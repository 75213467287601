// @flow
import { useParams } from 'react-router-dom';
import React, { useCallback, useMemo } from 'react';
import { DataFetcherContainer } from '@presentational/DataFetcherContainer';
import { shouldSkipDataFetching } from '@src/common-utils/utils';
import { useGetSPStreamingProjectLevelViewQuery } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { BASELINE_SP_IDENTIFIER_NAME } from '@streaming-projects/enums';

export const SPStreamingProjectContextProvider = ({ children }) => {
  const params = useParams();

  return (
    <DataFetcherContainer
      dataFetchingArgs={useMemo(
        () => [
          { ...params },
          {
            skip: shouldSkipDataFetching(params),
          },
        ],
        [params]
      )}
      dataFetchingFunction={useCallback(useGetSPStreamingProjectLevelViewQuery, [])}
    >
      {(data) => {
        const spName = data?.sp?.name;
        const dataToPass = {
          ...data,
          // todo::SP Discuss with BE during the Baseline Automation project on whether this should come from BE
          isBaseLineSP:
            spName && spName.toLowerCase() === BASELINE_SP_IDENTIFIER_NAME.toLowerCase(),
        };
        return (
          <SPStreamingProjectContext.Provider value={dataToPass}>
            {children}
          </SPStreamingProjectContext.Provider>
        );
      }}
    </DataFetcherContainer>
  );
};
