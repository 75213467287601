// @flow
import { StyledGridRow } from '@src/common-utils/styledComponents';
import { NegativeMessageContainer } from '@presentational/messages/NegativeMessageContainer';
import React from 'react';
import { areSourceAndDestinationClustersSame } from '@streaming-projects/resource-definitions/cluster-linking-resource-definitions/utils';

export const SourceAndDestinationSameWarningContainer = ({
  selectedSourceRow,
  selectedDestinationRow,
}) => {
  const areSelectedSourceAndDestinationClustersSame = areSourceAndDestinationClustersSame(
    selectedSourceRow,
    selectedDestinationRow
  );
  return (
    <>
      {areSelectedSourceAndDestinationClustersSame ? (
        <StyledGridRow>
          <NegativeMessageContainer header="The source and destination cannot be the same!!" />
        </StyledGridRow>
      ) : null}
    </>
  );
};
