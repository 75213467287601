// @flow
import React, { useContext } from 'react';
import { Grid } from 'semantic-ui-react';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { StyledHeader, StyledLabel } from '@src/common-utils/styledComponents';
import { Spacer } from '@presentational/spacing/Spacer';
import { StreamGovernanceWorkloadContext } from '@streaming-projects/workloads/stream-governance-workloads/contexts/StreamGovernanceWorkloadContext';
import { SPMetaDataContext } from '@streaming-projects/orgs/contexts/SPMetaDataContext';
import MappedDataRenderer from '@streaming-projects/resource-definitions/auxilary-components/MappedDataRenderer';

export const SPStreamGovernanceWorkloadResourceDetailsContainer = () => {
  const { providerRegionsMap, providerNameLabelMap } = useContext(SPMetaDataContext).metaData;
  const workloadData = useContext(StreamGovernanceWorkloadContext);

  const {
    name: envName,
    provider,
    package: packageName,
    region,
  } = workloadData.stream_governance_resource_details;

  return (
    <>
      <PaddedAndRaisedSegment>
        <Grid columns={4} divided={true}>
          <Grid.Row>
            <Grid.Column>
              <StyledHeader> Environment Name </StyledHeader>
              <Spacer y={20} />
              <StyledLabel>{envName}</StyledLabel>
            </Grid.Column>
            <Grid.Column>
              <StyledHeader> Package Name </StyledHeader>
              <Spacer y={20} />
              <StyledLabel>{packageName}</StyledLabel>
            </Grid.Column>
            <Grid.Column>
              <StyledHeader> Provider Name </StyledHeader>
              <Spacer y={20} />
              <StyledLabel>
                <MappedDataRenderer mapping={providerNameLabelMap} value={provider} />
              </StyledLabel>
            </Grid.Column>
            <Grid.Column>
              <StyledHeader> Region Name </StyledHeader>
              <Spacer y={20} />
              <StyledLabel>
                <MappedDataRenderer mapping={providerRegionsMap[provider].regions} value={region} />
              </StyledLabel>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </PaddedAndRaisedSegment>
    </>
  );
};
