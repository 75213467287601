// @flow
import { useHistory, useParams } from 'react-router-dom';
import React from 'react';
import { getLinkForStreamingProjectsSPPage } from '@streaming-projects/links';
import { useDeleteFlinkWorkloadMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { toastError, toastSuccess } from '@presentational/notifications/utils';
import { ConfirmModal } from '@presentational/modals/ConfirmModal';

export const SPFlinkWorkloadDeleteWarningModal = ({
  isOpen,
  onClose,
  flinkWorkloadName,
  flinkWorkloadId,
  flinkWorkloadResourceId,
  spVersion,
}) => {
  const [deleteFlinkWorkload] = useDeleteFlinkWorkloadMutation();
  const { orgId, spId } = useParams();
  const { push } = useHistory();
  const header = `Delete Flink Workload ${flinkWorkloadName}`;
  const headerBody = <p>Are you sure you want to delete this Flink Workload?</p>;

  const onClickHandlerForOK = async () => {
    const { error } = await deleteFlinkWorkload({
      orgId,
      spId,
      flinkWorkloadId,
      payload: {
        flink_pool_config_id: flinkWorkloadResourceId,
        sp_version: spVersion,
      },
    });
    if (error) {
      toastError(error);
    } else {
      toastSuccess('Flink Workload delete was successful!');
      push(getLinkForStreamingProjectsSPPage(orgId, spId));
    }
  };

  return (
    <ConfirmModal
      body={headerBody}
      header={header}
      isOpen={isOpen}
      onClickHandlerForCancel={onClose}
      onClickHandlerForOK={onClickHandlerForOK}
    />
  );
};
