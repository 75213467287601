// @flow
import React, { useCallback, useContext, useMemo } from 'react';
import { DataFetcherContainer } from '@presentational/DataFetcherContainer';
import { useGetStreamingProjectsMetadataQuery } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { getProviderRegionsMapFromMetaData } from '@streaming-projects/utils';
import {
  getConnectorNameToRecordMap,
  getFormattedClusterParams,
} from '@src/contexts/ClusterContextProvider';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { cloneDeep } from 'lodash';

import { SPMetaDataContext } from './SPMetaDataContext';
import { SPOrgContext } from './SPOrgContext';

export const getFormattedClusterConfigs = (clusterConfigs) => {
  const embellishedClusterParams = getFormattedClusterParams(clusterConfigs);

  return {
    ...clusterConfigs,
    cluster_params: embellishedClusterParams,
  };
};

export const SPMetaDataContextProvider = ({ children }) => {
  const spOrgData = useContext(SPOrgContext);
  const spData = useContext(SPStreamingProjectContext);
  const {
    org: { rate_card: rateCard },
  } = spOrgData;

  if (!rateCard) {
    throw new Error('An organisation must have a valid rate card');
  }
  const startDate = spData?.sp?.start_date ?? null;
  const endDate = spData?.sp?.end_date ?? null;

  let dealDurationQuery = '';
  if (startDate && endDate) {
    dealDurationQuery = startDate
      ? `DEAL_DURATION,START_DATE=${startDate},END_DATE=${endDate}`
      : '';
  }

  const tagsToQuery = useMemo(
    () => [
      'REGION_DETAILS',
      'FLINK_POOL_CONFIGS',
      'CLUSTER_CONFIGS',
      'CLUSTER_RESOURCE_DEFAULT_CONFIG',
      'CLUSTER_CONFIG_NAME_LABEL',
      `CONNECTOR_TYPES`,
      `RATE_CARD=${rateCard}`,
      'STREAM_GOVERNANCE_CONFIGS',
      'CLUSTER_LINKING_CONFIGS',
      dealDurationQuery,
    ],
    [rateCard, dealDurationQuery]
  );

  const args = useMemo(
    () => [
      {
        query: tagsToQuery.filter(Boolean).join(','),
      },
    ],
    [tagsToQuery]
  );

  return (
    <DataFetcherContainer
      dataFetchingArgs={args}
      dataFetchingFunction={useCallback(useGetStreamingProjectsMetadataQuery, [])}
    >
      {(data) => {
        const { providerRegionsMap, providerNameLabelMap } =
          getProviderRegionsMapFromMetaData(data);

        // todo::SP discuss with BE to update the below api contract
        const resourceConfigurationsNameLabelsMap = data.cluster_config_name_labels;
        // todo::SP Ask BE to send az_configuration and remove getFormattedClusterParams special handling

        const fullConnectorsList = cloneDeep(data.connectors);
        const connectorNameToRecordsMap = getConnectorNameToRecordMap(fullConnectorsList);

        return (
          <SPMetaDataContext.Provider
            value={{
              metaData: {
                ...data,
                providerNameLabelMap,
                providerRegionsMap,
                resourceConfigurationsNameLabelsMap,
                clusterResourceInputConfigs: getFormattedClusterConfigs(data.cluster_configs),
                fullConnectorsList,
                connectorNameToRecordsMap: connectorNameToRecordsMap,
              },
            }}
          >
            {children}
          </SPMetaDataContext.Provider>
        );
      }}
    </DataFetcherContainer>
  );
};

export const useMetaDataContext = () => useContext(SPMetaDataContext).metaData;
