// @flow
import React, { useContext, useState } from 'react';
import { DataGrid } from '@components/views/grid-utils/DataGrid';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';
import { BASELINE_SP_IDENTIFIER_NAME } from '@streaming-projects/enums';
import { PrimaryButton } from '@presentational/buttons/PrimaryButton';
import { SPSPAddModal } from '@streaming-projects/sp-page/add-sp/SPSPAddModal';
import { STREAMING_PROJECT_SUMMARY_GRID_CONFIG } from '@streaming-projects/orgs/config';

export const StreamingProjectsSummaryContainer = () => {
  const [isSPAddModalOpen, setSPAddModalOpen] = useState(false);
  const columnDefs = STREAMING_PROJECT_SUMMARY_GRID_CONFIG;
  const spORGData = useContext(SPOrgContext);

  let inputDataSource = spORGData?.sp_details ?? [];
  inputDataSource = inputDataSource.filter((x) => x.name !== BASELINE_SP_IDENTIFIER_NAME);

  const areAnySPsConfigured = inputDataSource.length > 0;

  return (
    <PaddedAndRaisedSegment>
      <PrimaryButton
        onClick={() => {
          setSPAddModalOpen(true);
        }}
        size="mini"
        text="Add New Streaming Project"
      />

      <SPSPAddModal isOpen={isSPAddModalOpen} setOpen={setSPAddModalOpen} />

      {areAnySPsConfigured && (
        <DataGrid
          columnDefs={columnDefs}
          inputDataSource={inputDataSource}
          label="Streaming Projects Summary"
          sizeColumnsToFitInitially={true}
        />
      )}
    </PaddedAndRaisedSegment>
  );
};
