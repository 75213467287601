// @flow
import React, { useContext } from 'react';
import { Grid } from 'semantic-ui-react';
import {
  CLUSTER_RESOURCES_GRID_FIELDNAMES,
  CLUSTER_RESOURCES_GRID_HEADERS,
} from '@streaming-projects/orgs/enums';
import { StyledH5 } from '@presentational/headings/StyledH5';
import { SPMetaDataContext } from '@streaming-projects/orgs/contexts/SPMetaDataContext';
import MappedDataRenderer from '@streaming-projects/resource-definitions/auxilary-components/MappedDataRenderer';
import { PercentageRenderer } from '@components/views/grid-utils/renderers';
import { StyledLabel } from '@src/common-utils/styledComponents';

const SPClusterResourceDetailsGrid = ({ clusterWorkloadDetails }) => {
  const { providerRegionsMap, resourceConfigurationsNameLabelsMap, providerNameLabelMap } =
    useContext(SPMetaDataContext).metaData;
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={4}>
          {/* { todo::SP extract the following as a separate component and utilise in SP cluster Resources} */}
          <StyledH5 text={CLUSTER_RESOURCES_GRID_HEADERS.NAME} />
          <section>
            <StyledLabel>
              {clusterWorkloadDetails[CLUSTER_RESOURCES_GRID_FIELDNAMES.NAME]}
            </StyledLabel>
          </section>
        </Grid.Column>
        <Grid.Column width={4}>
          <StyledH5 text={CLUSTER_RESOURCES_GRID_HEADERS.TYPE} />
          <section>
            <StyledLabel>
              <MappedDataRenderer
                mapping={
                  resourceConfigurationsNameLabelsMap[
                    CLUSTER_RESOURCES_GRID_FIELDNAMES.CLUSTER_TYPE
                  ]
                }
                value={clusterWorkloadDetails[CLUSTER_RESOURCES_GRID_FIELDNAMES.CLUSTER_TYPE]}
              />
            </StyledLabel>
          </section>
        </Grid.Column>
        <Grid.Column width={4}>
          <StyledH5 text={CLUSTER_RESOURCES_GRID_HEADERS.PROVIDER} />
          <section>
            <StyledLabel>
              <MappedDataRenderer
                mapping={providerNameLabelMap}
                value={clusterWorkloadDetails[CLUSTER_RESOURCES_GRID_FIELDNAMES.PROVIDER]}
              />
            </StyledLabel>
          </section>
        </Grid.Column>
        <Grid.Column width={4}>
          <StyledH5 text={CLUSTER_RESOURCES_GRID_HEADERS.REGION} />
          <section>
            <StyledLabel>
              <MappedDataRenderer
                mapping={
                  providerRegionsMap[
                    clusterWorkloadDetails[CLUSTER_RESOURCES_GRID_FIELDNAMES.PROVIDER]
                  ].regions
                }
                value={clusterWorkloadDetails[CLUSTER_RESOURCES_GRID_FIELDNAMES.REGION]}
              />
            </StyledLabel>
          </section>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={4}>
          <StyledH5 text={CLUSTER_RESOURCES_GRID_HEADERS.AVAILABILITY} />
          <section>
            <StyledLabel>
              <MappedDataRenderer
                mapping={
                  resourceConfigurationsNameLabelsMap[
                    CLUSTER_RESOURCES_GRID_FIELDNAMES.AVAILABILITY
                  ]
                }
                value={clusterWorkloadDetails.az_configuration}
              />
            </StyledLabel>
          </section>
        </Grid.Column>

        <Grid.Column width={4}>
          <StyledH5 text={CLUSTER_RESOURCES_GRID_HEADERS.SLA} />
          <StyledLabel>
            <PercentageRenderer
              value={clusterWorkloadDetails[CLUSTER_RESOURCES_GRID_FIELDNAMES.SLA]}
            />
          </StyledLabel>
        </Grid.Column>

        <Grid.Column width={4}>
          <StyledH5 text={CLUSTER_RESOURCES_GRID_HEADERS.NETWORKING_TYPE} />
          <section>
            <StyledLabel>
              <MappedDataRenderer
                mapping={
                  resourceConfigurationsNameLabelsMap[
                    CLUSTER_RESOURCES_GRID_FIELDNAMES.NETWORKING_TYPE
                  ]
                }
                value={clusterWorkloadDetails[CLUSTER_RESOURCES_GRID_FIELDNAMES.NETWORKING_TYPE]}
              />
            </StyledLabel>
          </section>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default SPClusterResourceDetailsGrid;
