// @flow
import React, { useContext } from 'react';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { DataGrid } from '@components/views/grid-utils/DataGrid';
import {
  COMMIT_DETAILS_GRID_HEADERS,
  COMMIT_DETAILS_GRID_FIELDNAMES,
} from '@streaming-projects/orgs/enums';
import { CurrencyZeroDPRenderer } from '@components/views/grid-utils/renderers';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';
import { BACKEND_FIELDNAMES, FRONT_END_DISPLAY_NAMES } from '@streaming-projects/enums';
import { IS_USER_COMMIT_SECTION_LIVE } from '@streaming-projects/constants';

import SPDefaultCommitNameRenderer from './SPDefaultCommitNameRenderer';
import SPUserCommitNameRenderer from './SPUserCommitNameRenderer';

/**
 * Component shown in the Commit Details accordion panel
 */
const SPCommitSummary = () => {
  const spOrgData = useContext(SPOrgContext);

  /**
   * @type {import('ag-grid-community').ColDef[]} columnDefs
   */
  const columnDefs = [
    {
      field: COMMIT_DETAILS_GRID_HEADERS.ID,
      backEndFieldName: COMMIT_DETAILS_GRID_FIELDNAMES.ID,
      hide: true,
    },
    {
      field: COMMIT_DETAILS_GRID_HEADERS.NAME,
      backEndFieldName: COMMIT_DETAILS_GRID_FIELDNAMES.NAME,
      headerName: COMMIT_DETAILS_GRID_HEADERS.NAME,
      cellRenderer: ({ value, data }) =>
        value === FRONT_END_DISPLAY_NAMES.DEFAULT_COMMIT_NAME ? (
          <SPDefaultCommitNameRenderer />
        ) : (
          <SPUserCommitNameRenderer
            commitId={data[COMMIT_DETAILS_GRID_FIELDNAMES.ID]}
            commitName={value}
          />
        ),
    },
    {
      field: COMMIT_DETAILS_GRID_HEADERS.START_DATE,
      backEndFieldName: COMMIT_DETAILS_GRID_FIELDNAMES.START_DATE,
      headerName: COMMIT_DETAILS_GRID_HEADERS.START_DATE,
    },
    {
      field: COMMIT_DETAILS_GRID_HEADERS.END_DATE,
      backEndFieldName: COMMIT_DETAILS_GRID_FIELDNAMES.END_DATE,
      headerName: COMMIT_DETAILS_GRID_HEADERS.END_DATE,
    },
    {
      field: COMMIT_DETAILS_GRID_HEADERS.COMMIT_AMOUNT,
      backEndFieldName: COMMIT_DETAILS_GRID_FIELDNAMES.COMMIT_AMOUNT,
      headerName: COMMIT_DETAILS_GRID_HEADERS.COMMIT_AMOUNT,
      // We get `-` from backend if there is no commit amount entered by the user.
      cellRenderer: (props) => (props.value === '-' ? props.value : CurrencyZeroDPRenderer(props)),
    },
  ];

  let commitsDataSource = [];

  if (IS_USER_COMMIT_SECTION_LIVE) {
    commitsDataSource = spOrgData[BACKEND_FIELDNAMES.COMMITS] ?? [];
  }

  const defaultCommitDetails = spOrgData[BACKEND_FIELDNAMES.DEFAULT_COMMIT_DETAILS];

  /**
   * Prepend the default commit details to the commits array if it exists
   */
  if (defaultCommitDetails) {
    commitsDataSource = [defaultCommitDetails, ...commitsDataSource];
  }

  return (
    <PaddedAndRaisedSegment>
      <DataGrid
        columnDefs={columnDefs}
        downloadAllCols={false}
        inputDataSource={commitsDataSource}
        label="Commit Summary"
        sizeColumnsToFitInitially={true}
      />
    </PaddedAndRaisedSegment>
  );
};

export default SPCommitSummary;
