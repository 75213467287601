// @flow
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import { CLUSTER_RESOURCE_INPUTS_DETAILS_CONFIG_MAP } from '@streaming-projects/resource-definitions/cluster-resource-definitions/cluster-resource-inputs-config';
import { CLUSTER_AVAILABILITY_BACKEND_NAME, MZ, SZ } from '@src/constants';
import { useClusterInputsDependencies } from '@components/cluster/cluster-details-inputs/utils';
import { getDropdownOptions } from '@components/stream-governance/utils';
import { SelectField } from '@src/formik-utils/FormFields';
import { SPMetaDataContext } from '@streaming-projects/orgs/contexts/SPMetaDataContext';

export const SPClusterAvailabilityConfigurationContainer = ({
  disabled,
  fluid = null,
  disableOnFormErrors = null,
}) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const clusterAvailabilityConfig = CLUSTER_RESOURCE_INPUTS_DETAILS_CONFIG_MAP.get(
    CLUSTER_AVAILABILITY_BACKEND_NAME
  );
  const { metaData } = useContext(SPMetaDataContext);
  const dataUniverse = metaData.clusterResourceInputConfigs.cluster_params;

  // todo:SP use the SP 3.0 config map in `handleDependedClusterInputFields` function...
  useClusterInputsDependencies(
    clusterAvailabilityConfig,
    dataUniverse,
    values,
    setFieldValue,
    setFieldTouched
  );

  const clusterAvailabilityOptions = getDropdownOptions(
    dataUniverse,
    values,
    clusterAvailabilityConfig,
    false,
    true,
    false,
    false,
    [SZ, MZ]
  );

  return (
    <SelectField
      disableOnFormErrors={disableOnFormErrors}
      disabled={disabled}
      fieldDisplayName={clusterAvailabilityConfig.displayName}
      fieldName={clusterAvailabilityConfig.backendFieldName}
      fluid={fluid}
      options={clusterAvailabilityOptions}
      placeholder={null}
    />
  );
};
