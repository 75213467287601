// @flow
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';
import {
  useDisableFlinkWorkloadMutation,
  useEnableFlinkWorkloadMutation,
} from '@streaming-projects/service-definitions/streamingProjectsApi';
import { FLINK_WORKLOAD_DETAILS_CONFIG_MAP } from '@streaming-projects/workloads/flink-workloads/config';
import { FLINK_WORKLOADS_INPUTS_FIELDNAMES } from '@streaming-projects/orgs/enums';
import { CheckBoxField } from '@src/formik-utils/FormFields';
import { toastError } from '@presentational/notifications/utils';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';

export const SPFlinkWorkloadEnabledContainer = ({
  flinkWorkloadId = null,
  flinkResourceId = null,
  checked = null,
  addLabel = true,
}) => {
  const { orgId, spId } = useParams();
  const { isValid } = useFormikContext();
  const spStreamingProjectData = useContext(SPStreamingProjectContext);

  const [enableFlinkWorkload] = useEnableFlinkWorkloadMutation();
  const [disableFlinkWorkload] = useDisableFlinkWorkloadMutation();

  const { backendFieldName, displayName, icon } = FLINK_WORKLOAD_DETAILS_CONFIG_MAP.get(
    FLINK_WORKLOADS_INPUTS_FIELDNAMES.IS_ENABLED
  );

  const checkedProps = checked != null ? { checked } : {};
  return (
    <CheckBoxField
      addLabel={addLabel}
      disabled={!isValid}
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      icon={icon}
      onChange={async (event, value, setFieldValue, setFieldTouched, values, checked) => {
        const payload = {
          flink_pool_config_id: flinkResourceId,
          sp_version: spStreamingProjectData?.sp?.version,
        };

        const funcToCall = checked ? enableFlinkWorkload : disableFlinkWorkload;

        const { error } = await funcToCall({
          orgId,
          spId,
          flinkWorkloadId,
          payload,
        });

        if (error) {
          toastError(error);
        }
      }}
      {...checkedProps}
    />
  );
};
