// @flow
import React from 'react';
import { ShowLoaderIfAnyQueryIsPending } from '@presentational/ShowLoaderIfAnyQueryIsPending';
import { UserContextProvider } from '@src/contexts/UserContextProvider';
import { SPStreamingProjectContextProvider } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContextProvider';
import { SPOrgContextProvider } from '@streaming-projects/orgs/contexts/SPOrgContextProvider';
import { ClusterLinkingWorkloadContextProvider } from '@streaming-projects/workloads/cluster-linking-workloads/contexts/ClusterLinkingWorkloadContextProvider';
import { SPClusterLinkingWorkloadBreadcrumbs } from '@streaming-projects/workloads/cluster-linking-workloads/breadcrumbs/SPClusterLinkingWorkloadBreadcrumbs';
import { SPClusterLinkingWorkloadTopLevelContainerForm } from '@streaming-projects/workloads/cluster-linking-workloads/SPClusterLinkingWorkloadTopLevelContainerForm';
import { SPMetaDataContextProvider } from '@streaming-projects/orgs/contexts/SPMetaDataContextProvider';

export const SPClusterLinkingWorkloadTopLevelContainer = () => {
  return (
    <>
      <ShowLoaderIfAnyQueryIsPending />
      <UserContextProvider>
        <SPOrgContextProvider>
          <SPStreamingProjectContextProvider>
            <SPMetaDataContextProvider>
              <ClusterLinkingWorkloadContextProvider>
                <SPClusterLinkingWorkloadBreadcrumbs />
                <SPClusterLinkingWorkloadTopLevelContainerForm />
              </ClusterLinkingWorkloadContextProvider>
            </SPMetaDataContextProvider>
          </SPStreamingProjectContextProvider>
        </SPOrgContextProvider>
      </UserContextProvider>
    </>
  );
};
