// @flow
import React, { useContext } from 'react';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { Grid } from 'semantic-ui-react';
import { StyledGridRow } from '@src/common-utils/styledComponents';
import { GenericSaveResetButtons } from '@components/GenericSaveResetButtons';
import { useUpdateSPDefaultCommitDetailsMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { Spacer } from '@presentational/spacing/Spacer';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';
import { SP_DEFAULT_COMMIT_DETAILS_CONFIG } from '@streaming-projects/default-commit-details/config';
import { useSPDefaultCommitDetailsContext } from '@streaming-projects/default-commit-details/context/SPDefaultCommitDetailsContext';

import SPDefaultCommitNameContainer from './fields/SPDefaultCommitNameContainer';
import SPDefaultCommitDealStartDateContainer from './fields/SPDefaultCommitDealStartDateContainer';
import SPDefaultCommitDealEndDateContainer from './fields/SPDefaultCommitDealEndDateContainer';
import SPDefaultCommitSupportTierContainer from './fields/SPDefaultCommitSupportTierContainer';

const SPDefaultCommitGenericDetails = () => {
  const spOrgContext = useContext(SPOrgContext);
  const [updateSPDefaultCommitDetails] = useUpdateSPDefaultCommitDetailsMutation();
  const spDefaultCommitDetails = useSPDefaultCommitDetailsContext();

  const handleSave = async (payload) => {
    return await updateSPDefaultCommitDetails({
      orgId: spOrgContext.org.id,
      payload: {
        support_tier: payload.support_tier,
        Version: spDefaultCommitDetails?.version,
      },
    });
  };

  return (
    <PaddedAndRaisedSegment>
      <Grid columns={4}>
        <StyledGridRow coloumns={1}>
          <Grid.Column>
            <SPDefaultCommitNameContainer />
          </Grid.Column>
        </StyledGridRow>

        <StyledGridRow coloumns={3}>
          <Grid.Column>
            <SPDefaultCommitDealStartDateContainer />
          </Grid.Column>

          <Grid.Column>
            <SPDefaultCommitDealEndDateContainer />
          </Grid.Column>

          <Grid.Column>
            <SPDefaultCommitSupportTierContainer />
          </Grid.Column>
        </StyledGridRow>
      </Grid>

      <Spacer y={40} />

      <GenericSaveResetButtons
        fieldsConfig={SP_DEFAULT_COMMIT_DETAILS_CONFIG.filter(
          (config) => config.backendFieldName !== BACKEND_FIELDNAMES.COMMIT_AMOUNT
        )}
        onSaveHandler={handleSave}
      />
    </PaddedAndRaisedSegment>
  );
};

export default SPDefaultCommitGenericDetails;
