// @flow
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';

import { EstimateContext } from '../../../contexts/EstimateContext';
import { isAnythingEditedOnEstimatePage } from '../utils';
import { StyledH5 } from '../../presentational/headings/StyledH5';

import { StreamGovernanceSummaryGridSegment } from './StreamGovernanceSummaryGridSegment';
import { AddStreamGovernanceSegment } from './AddStreamGovernanceSegment';

export const StreamGovernanceSummaryContainer = () => {
  const estimate = useContext(EstimateContext);
  const { values, initialValues } = useFormikContext();

  const streamGovernanceSummary = estimate.stream_governance;
  const isSGConfigured = streamGovernanceSummary.is_sg_configured;

  const disabled = isAnythingEditedOnEstimatePage(values, initialValues);

  const Header = (
    <StyledH5 text="Starting March 4th 2024, SG essentials free schema limit is lowered from 1000 to 100 schemas per environment through Rate Cards 3/5/2024 and 4/11/2024. Ensure all customers are evaluated accordingly." />
  );

  return (
    <>
      {!isSGConfigured ? (
        <AddStreamGovernanceSegment Header={Header} disabled={disabled} />
      ) : (
        <StreamGovernanceSummaryGridSegment Header={Header} disabled={disabled} />
      )}
    </>
  );
};

// todo:: Remove the extra space or make use of it to show additional info using this link https://www.ag-grid.com/javascript-data-grid/grid-size/
