// @flow
import React, { useContext } from 'react';
import { Grid } from 'semantic-ui-react';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { FlinkPoolWorkloadContext } from '@streaming-projects/workloads/flink-workloads/contexts/FlinkPoolWorkloadContext';
import { StyledHeader, StyledLabel } from '@src/common-utils/styledComponents';
import { Spacer } from '@presentational/spacing/Spacer';
import MappedDataRenderer from '@streaming-projects/resource-definitions/auxilary-components/MappedDataRenderer';
import { SPMetaDataContext } from '@streaming-projects/orgs/contexts/SPMetaDataContext';

export const SPFlinkWorkloadResourceDetailsContainer = () => {
  const spFlinkPoolWorkloadData = useContext(FlinkPoolWorkloadContext);
  const { providerRegionsMap, providerNameLabelMap } = useContext(SPMetaDataContext).metaData;

  const { provider, name, region } = spFlinkPoolWorkloadData.flink_resource_details;

  return (
    <>
      <PaddedAndRaisedSegment>
        <Grid columns={3} divided={true}>
          <Grid.Row>
            <Grid.Column>
              <StyledHeader> Pool Name </StyledHeader>
              <Spacer y={20} />
              <StyledLabel>{name}</StyledLabel>
            </Grid.Column>
            <Grid.Column>
              <StyledHeader> Provider Name </StyledHeader>
              <Spacer y={20} />
              <StyledLabel>
                <MappedDataRenderer mapping={providerNameLabelMap} value={provider} />
              </StyledLabel>
            </Grid.Column>
            <Grid.Column>
              <StyledHeader> Region Name </StyledHeader>
              <Spacer y={20} />
              <StyledLabel>
                <MappedDataRenderer mapping={providerRegionsMap[provider].regions} value={region} />
              </StyledLabel>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </PaddedAndRaisedSegment>
    </>
  );
};
