// @flow
import React, { useContext, useState } from 'react';
import { AccordionsList } from '@presentational/accordions/AccordionsList';
import { YearSelectionContext } from '@components/cluster/YearSelectionContext';
import {
  getAccordianPanelDetailsObject,
  getNumberOfYearsBasedOnMonths,
} from '@src/common-utils/utils';
import { StreamGovernanceWorkloadContext } from '@streaming-projects/workloads/stream-governance-workloads/contexts/StreamGovernanceWorkloadContext';
import { SPStreamGovernanceWorkloadDetailsContainer } from '@streaming-projects/workloads/stream-governance-workloads/workload-details/SPStreamGovernanceWorkloadDetailsContainer';
import { SPStreamGovernanceWorkloadResourceDetailsContainer } from '@streaming-projects/workloads/stream-governance-workloads/resource-details/SPStreamGovernanceWorkloadResourceDetailsContainer';
import { SPStreamGovernanceWorkloadMonthlyUsageContainer } from '@streaming-projects/workloads/stream-governance-workloads/monthly-inputs/SPStreamGovernanceWorkloadMonthlyUsageContainer';

export const SPStreamGovernanceWorkloadAccordionsContainer = () => {
  const workloadData = useContext(StreamGovernanceWorkloadContext);
  const totalYears = getNumberOfYearsBasedOnMonths(workloadData.stream_governance.inputs.length);
  const [selectedYear, setSelectedYear] = useState(1);

  const accordionPanels = [
    getAccordianPanelDetailsObject(
      'Workload Specific Details',
      <SPStreamGovernanceWorkloadDetailsContainer />
    ),
    getAccordianPanelDetailsObject(
      'Resource Details',
      <SPStreamGovernanceWorkloadResourceDetailsContainer />
    ),

    getAccordianPanelDetailsObject(
      'Usage Details',
      <SPStreamGovernanceWorkloadMonthlyUsageContainer />
    ),
  ];

  return (
    <YearSelectionContext.Provider
      value={{
        totalYears,
        selectedYear,
        setSelectedYear,
      }}
    >
      <AccordionsList panels={accordionPanels} />
    </YearSelectionContext.Provider>
  );
};
