// @flow
import { getIn, useFormikContext } from 'formik';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG,
  DEFAULT_VALUE_FOR_DB_ROW_ID_STR,
} from '../../../constants';
import { ConfirmModal } from '../../presentational/modals/ConfirmModal';
import { ExclusiveAccordionsList } from '../../presentational/accordions/ExclusiveAccordionsList';
import { getAccordianPanelDetailsObject } from '../../../common-utils/utils';
import { StyledLabel } from '../../../common-utils/styledComponents';
import { DeleteButton } from '../../presentational/buttons/DeleteButton';
import { PaddedAndRaisedSegment } from '../../presentational/PaddedAndRaisedSegment';
import { useDeleteConnectorMutation } from '../../../service-definitions/estimates';
import { toastError, toastSuccess } from '../../presentational/notifications/utils';
import { EstimateContext } from '../../../contexts/EstimateContext';
import { NegativeMessageContainer } from '../../presentational/messages/NegativeMessageContainer';

import { isAnyOtherConnectorBeingEdited } from './utils';
import { ConnectorsMonthlyInputContainer } from './ConnectorsMonthlyInputContainer';

const getIfConnectorNeedsToBeDisabled = (
  disabledFromParent,
  isAnyOtherConnectorBeingEditedCurrently
) => {
  let shouldConnectorBeDisabled = false;
  if (disabledFromParent) {
    shouldConnectorBeDisabled = true;
  } else {
    if (isAnyOtherConnectorBeingEditedCurrently) {
      shouldConnectorBeDisabled = true;
    }
  }
  return shouldConnectorBeDisabled;
};

export const ConnectorsTitle = ({
  connector,
  shouldConnectorBeDisabled,
  setSelectedConnector,
  actualIndexOfConnectorInArr,
  setDeleteConnectorModalOpen,
  error,
}) => (
  <>
    <StyledLabel basic={true} content={connector.connectorLabel} fontSize="1.1em" />

    <DeleteButton
      disabled={shouldConnectorBeDisabled}
      onClick={() => {
        setSelectedConnector({
          connector,
          connectorIndexToRemove: actualIndexOfConnectorInArr,
        });
        setDeleteConnectorModalOpen(true);
      }}
    />
    {error && <NegativeMessageContainer header={error?.connectorName ?? ''} />}
    {error && <NegativeMessageContainer header={error?.connectorNetworkingType ?? ''} />}
  </>
);
export const getConnectorAccordianPanels = ({
  reverseConnectorsData,
  connectorsData,
  initialValues,
  values,
  errors,
  disabled,
  setDeleteConnectorModalOpen,
  setSelectedConnector,
  MonthlyInputContainerComponent = ConnectorsMonthlyInputContainer,
}) => {
  const accordionPanels = [];

  reverseConnectorsData.forEach((connector, connectorIndex, arr) => {
    const actualIndexOfConnectorInArr = arr.length - 1 - connectorIndex;
    const keyNameInValuesToUseForThisConnector = `${CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG}.connectors[${actualIndexOfConnectorInArr}]`;

    const isAnyOtherConnectorBeingEditedCurrently = isAnyOtherConnectorBeingEdited(
      connectorsData.length,
      initialValues,
      values,
      actualIndexOfConnectorInArr
    );

    const shouldConnectorBeDisabled = getIfConnectorNeedsToBeDisabled(
      disabled,
      isAnyOtherConnectorBeingEditedCurrently
    );

    const error = getIn(errors, keyNameInValuesToUseForThisConnector);

    accordionPanels.push(
      getAccordianPanelDetailsObject(
        <ConnectorsTitle
          actualIndexOfConnectorInArr={actualIndexOfConnectorInArr}
          connector={connector}
          error={error}
          setDeleteConnectorModalOpen={setDeleteConnectorModalOpen}
          setSelectedConnector={setSelectedConnector}
          shouldConnectorBeDisabled={shouldConnectorBeDisabled}
        />,
        <PaddedAndRaisedSegment>
          <MonthlyInputContainerComponent
            connector={connector}
            connectorIndex={actualIndexOfConnectorInArr}
            disabled={shouldConnectorBeDisabled}
            key={`${connectorIndex}--${connector.connectorName}`}
          />
        </PaddedAndRaisedSegment>,
        `${connectorIndex}--${connector.connectorName}`
      )
    );
  });

  return accordionPanels;
};

export const ConnectorsMonthlyInputContainerWrapper = ({ arrayHelpers, disabled }) => {
  const { estimateId, clusterId } = useParams();
  const [isDeleteConnectorModalOpen, setDeleteConnectorModalOpen] = useState(false);
  const [selectedConnector, setSelectedConnector] = useState(null);
  const [deleteConnector] = useDeleteConnectorMutation();
  const estimate = useContext(EstimateContext);
  const { values, errors, initialValues } = useFormikContext();

  const connectorsData = getIn(values, [
    CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG,
    'connectors',
  ]);

  const reverseConnectorsData = [...connectorsData].reverse();

  const accordionPanels = getConnectorAccordianPanels({
    connectorsData,
    reverseConnectorsData,
    initialValues,
    values,
    errors,
    disabled,
    setDeleteConnectorModalOpen,
    setSelectedConnector,
  });

  return (
    <>
      <ExclusiveAccordionsList panels={accordionPanels} />
      {selectedConnector && (
        <ConfirmModal
          body={<p>Are you sure you want to delete this Connector?</p>}
          header={`Delete Connector ${selectedConnector?.connector?.connectorLabel}`}
          isOpen={isDeleteConnectorModalOpen}
          onClickHandlerForCancel={() => setDeleteConnectorModalOpen(false)}
          onClickHandlerForOK={async function () {
            const connectorId = selectedConnector?.connector?.connectorId;

            if (connectorId === DEFAULT_VALUE_FOR_DB_ROW_ID_STR) {
              arrayHelpers.remove(selectedConnector?.connectorIndexToRemove);
              setDeleteConnectorModalOpen(false);
            } else {
              const payload = {
                estimate_id: estimateId,
                cluster_id: clusterId,
                estimate_version: estimate?.inputs?.estimate_version,
                connector_id: connectorId,
              };

              const { error } = await deleteConnector({
                estimateId,
                clusterId,
                connectorId,
                payload,
              });

              if (error) {
                toastError(error);
              } else {
                toastSuccess('Successfully deleted the connector!');
                setDeleteConnectorModalOpen(false);
              }
            }
          }}
        />
      )}
    </>
  );
};
