// @flow
import React, { useContext, useState } from 'react';
import {
  CLUSTER_RESOURCES_GRID_FIELDNAMES,
  CLUSTER_RESOURCES_GRID_HEADERS,
} from '@streaming-projects/orgs/enums';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { SPClusterResourceConfigurationAddEditModal } from '@streaming-projects/resource-definitions/cluster-resource-definitions/SPClusterResourceConfigurationAddEditModal';
import { PrimaryButton } from '@presentational/buttons/PrimaryButton';
import { SPClusterDeleteRenderer } from '@src/streaming-projects/sp-top-level/SPClusterDeleteRenderer';
import { ClusterResourceEditRenderer } from '@streaming-projects/resource-definitions/cluster-resource-definitions/ClusterResourceEditRenderer';
import { SPMetaDataContext } from '@streaming-projects/orgs/contexts/SPMetaDataContext';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';
import { getSPClusterResourceConfig } from '@streaming-projects/orgs/config';
import { DataGrid } from '@components/views/grid-utils/DataGrid';

export const SPClusterResourceConfigurationsSummaryContainer = () => {
  const [isSPClusterConfigAddEditModalOpen, setSPClusterConfigAddEditModalOpen] = useState(false);
  const { providerRegionsMap, resourceConfigurationsNameLabelsMap, providerNameLabelMap } =
    useContext(SPMetaDataContext).metaData;

  const spCommonClusterColumnsConfig = getSPClusterResourceConfig({
    providerNameLabelMap,
    resourceConfigurationsNameLabelsMap,
    providerRegionsMap,
  });

  const columnDefs = [
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.ID,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.ID,
      hide: true,
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.NAME,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.NAME,
      headerTooltip: CLUSTER_RESOURCES_GRID_HEADERS.NAME,
    },
    ...spCommonClusterColumnsConfig,
    {
      field: '',
      filter: false,
      cellRenderer: ClusterResourceEditRenderer,
      suppressSizeToFit: true,
      maxWidth: 60,
      sortable: false,
    },
    {
      field: '',
      filter: false,
      cellRenderer: SPClusterDeleteRenderer,
      suppressSizeToFit: true,
      maxWidth: 60,
      sortable: false,
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.VERSION,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.VERSION,
      hide: true,
    },
  ];

  const inputDataSource = useContext(SPOrgContext)?.resources?.cluster_resources ?? [];
  const areAnyClustersConfigured = inputDataSource.length > 0;

  return (
    <PaddedAndRaisedSegment>
      <PrimaryButton
        onClick={() => {
          setSPClusterConfigAddEditModalOpen(true);
        }}
        size="mini"
        text="Add Cluster Shared Resource Configuration"
      />

      <SPClusterResourceConfigurationAddEditModal
        isOpen={isSPClusterConfigAddEditModalOpen}
        setOpen={setSPClusterConfigAddEditModalOpen}
      />

      {areAnyClustersConfigured && (
        <DataGrid
          columnDefs={columnDefs}
          displayResizingColsOption={true}
          downloadAllCols={false}
          inputDataSource={inputDataSource}
          label="Cluster Configuration Summary"
          sizeColumnsToFitInitially={true}
          tooltipShowDelay={0}
        />
      )}
    </PaddedAndRaisedSegment>
  );
};
