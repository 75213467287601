// @flow

import {
  CLUSTER_AVAILABILITY_BACKEND_NAME,
  CLUSTER_NAME_BACKEND_NAME,
  CLUSTER_NETWORKING_TYPE_BACKEND_NAME,
  CLUSTER_PROVIDER_BACKEND_NAME,
  CLUSTER_REGION_BACKEND_NAME,
  CLUSTER_SLA_BACKEND_NAME,
  CLUSTER_TYPE_BACKEND_NAME,
} from '@src/constants';
import { CLUSTER_RESOURCES_GRID_HEADERS } from '@streaming-projects/orgs/enums';

export const getClusterResouceDefaultInputValues = ({
  initialValues,
  clusterResourceDefaultInputValues,
  isEditingMode,
}) => {
  const { az_configuration: availability, ...defaultInputValues } =
    clusterResourceDefaultInputValues;
  if (isEditingMode) {
    return {
      [CLUSTER_NAME_BACKEND_NAME]: initialValues[CLUSTER_RESOURCES_GRID_HEADERS.NAME],
      [CLUSTER_TYPE_BACKEND_NAME]: initialValues[CLUSTER_RESOURCES_GRID_HEADERS.TYPE],
      [CLUSTER_NETWORKING_TYPE_BACKEND_NAME]:
        initialValues[CLUSTER_RESOURCES_GRID_HEADERS.NETWORKING_TYPE],
      [CLUSTER_SLA_BACKEND_NAME]: initialValues[CLUSTER_RESOURCES_GRID_HEADERS.SLA],
      [CLUSTER_AVAILABILITY_BACKEND_NAME]:
        initialValues[CLUSTER_RESOURCES_GRID_HEADERS.AVAILABILITY],
      [CLUSTER_PROVIDER_BACKEND_NAME]: initialValues[CLUSTER_RESOURCES_GRID_HEADERS.PROVIDER],
      [CLUSTER_REGION_BACKEND_NAME]: initialValues[CLUSTER_RESOURCES_GRID_HEADERS.REGION],
    };
  }
  // todo::SP remove the below defaultInputValues.provider once fixed by BE
  defaultInputValues.provider = defaultInputValues.provider.toLowerCase();
  return {
    availability,
    ...defaultInputValues,
  };
};
