// @flow
import { Formik } from 'formik';
import React from 'react';
import { useParams } from 'react-router-dom';
import { object } from 'yup';
import { Grid } from 'semantic-ui-react';
import {
  useAddClusterLinkingResourceMutation,
  useAddClusterLinkingWorkloadMutation,
  useAddClusterResourceMutation,
  useAddClusterWorkloadMutation,
  useAddFlinkPoolResourceMutation,
  useAddFlinkWorkloadMutation,
  useAddStreamGovernanceResourceMutation,
  useAddStreamGovernanceWorkloadMutation,
  useAddStreamingProjectMutation,
  useLazyGetStreamingProjectsMetadataQuery,
} from '@streaming-projects/service-definitions/streamingProjectsApi';
import { getValidationSchemaFromColsConfig } from '@src/configuration/utils';
import { StyledContainer, StyledGridRow } from '@src/common-utils/styledComponents';
import { ConfirmModal } from '@presentational/modals/ConfirmModal';
import { Form } from '@src/formik-utils/formikSUIWrapper';
import { SPStartDateContainer } from '@streaming-projects/sp-page/sp-details/SPStartDateContainer';
import { SPEndDateContainer } from '@streaming-projects/sp-page/sp-details/SPEndDateContainer';
import { Spacer } from '@presentational/spacing/Spacer';
import { toastError } from '@presentational/notifications/utils';
import { processUploadedData, formResetHandler } from '@streaming-projects/baseline/utils';
import { SPInputFileContainer } from '@streaming-projects/baseline/SPInputFileContainer';
import { STREAMING_PROJECTS_BASELINE_DETAILS_CONFIG } from '@streaming-projects/baseline/config';
import { parse } from 'papaparse';
import { convertLocalToUTCDate } from '@src/common-utils/utils';

export const SPBaselineAddModal = ({ isOpen, setOpen, isBaselineSPAlreadyConfigured }) => {
  const [addClusterResource] = useAddClusterResourceMutation();
  const [addFlinkPoolResource] = useAddFlinkPoolResourceMutation();
  const [addStreamingProject] = useAddStreamingProjectMutation();
  const [addClusterWorkload] = useAddClusterWorkloadMutation();
  const [addFlinkWorkload] = useAddFlinkWorkloadMutation();

  const [addStreamGovernanceResource] = useAddStreamGovernanceResourceMutation();
  const [addStreamGovernanceWorkload] = useAddStreamGovernanceWorkloadMutation();

  const [addClusterLinkingResource] = useAddClusterLinkingResourceMutation();
  const [addClusterLinkingWorkload] = useAddClusterLinkingWorkloadMutation();
  // Note, we are using the "Lazy" version here so that we can call it inside the processUploadedData function
  const [getMetaData] = useLazyGetStreamingProjectsMetadataQuery();

  const { orgId } = useParams();

  const initialValues = {};

  const validationSchema = object({
    ...getValidationSchemaFromColsConfig(STREAMING_PROJECTS_BASELINE_DETAILS_CONFIG),
  });

  return (
    <StyledContainer data-testid="sp-baseline-add-modal">
      <Formik
        autoComplete="off"
        initialValues={initialValues}
        onSubmit={() => {}}
        validationSchema={validationSchema}
      >
        {(addSPBaselineFormik) => (
          <ConfirmModal
            body={
              <StyledContainer>
                <Form autoComplete="off">
                  <Grid columns={2} divided={true}>
                    <StyledGridRow>
                      <Grid.Column>
                        <SPStartDateContainer
                          disableOnFormErrors={false}
                          showWarningOnChange={false}
                        />
                      </Grid.Column>
                    </StyledGridRow>
                    <StyledGridRow>
                      <Grid.Column>
                        <SPEndDateContainer
                          disableOnFormErrors={false}
                          showWarningOnChange={false}
                        />
                      </Grid.Column>
                    </StyledGridRow>
                    <StyledGridRow>
                      <Grid.Column>
                        <SPInputFileContainer disableOnFormErrors={false} />
                      </Grid.Column>
                    </StyledGridRow>
                  </Grid>
                  <Spacer y={40} />
                </Form>
              </StyledContainer>
            }
            cancelButtonNegative={true}
            disabled={!addSPBaselineFormik.dirty || !addSPBaselineFormik.isValid}
            header="Import Baseline"
            isOpen={isOpen}
            okButtonNegative={false}
            okButtonText="Continue"
            onClickHandlerForCancel={() => {
              formResetHandler(addSPBaselineFormik, setOpen);
            }}
            onClickHandlerForOK={async () => {
              const {
                start_date: startDate,
                end_date: endDate,
                input_file: inputFile,
              } = addSPBaselineFormik.values;

              // todo:: Move this functionality to a common place - eg., the Formik Field can handle this and set the values?
              const reader = new FileReader();
              reader.onload = async (e) => {
                try {
                  // First , parse the CSV File
                  const results = parse(e.target.result, { header: true });

                  // Second, get the json data from the first row and parse it
                  const firstRow = results.data[0];

                  // Trim all the keys because sometimes, Tableau can add an extra space in the "json" key
                  Object.keys(firstRow).forEach((key) => (firstRow[key.trim()] = firstRow[key]));
                  const data = JSON.parse(firstRow.json);

                  // Rest of the magic will happen here...
                  await processUploadedData(
                    convertLocalToUTCDate(startDate),
                    convertLocalToUTCDate(endDate),
                    data,
                    orgId,
                    isBaselineSPAlreadyConfigured,
                    addClusterResource,
                    addFlinkPoolResource,
                    addStreamingProject,
                    addClusterWorkload,
                    addFlinkWorkload,
                    addStreamGovernanceResource,
                    addStreamGovernanceWorkload,
                    addClusterLinkingResource,
                    addClusterLinkingWorkload,
                    getMetaData
                  );
                } catch (error) {
                  toastError(
                    null,
                    `There was an error parsing the JSON file. Please make sure the file is in the correct format.${error}`
                  );
                }
              };
              reader.onerror = (e) => {
                reader.abort();
                toastError(
                  null,
                  `Could not read the file! Faced this error: \n\n ${e.target.error}`
                );
              };
              reader.readAsText(inputFile);
              formResetHandler(addSPBaselineFormik, setOpen);
            }}
          />
        )}
      </Formik>
    </StyledContainer>
  );
};

// todo::SP In Baseline Upload, when a resource is created, we are clearing out the entire cache...
// This is a side effect of the current design where FE is handling the creation of items. Fix this during the automation phase.
