// @flow
import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { object, string } from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import {
  useAddClusterLinkingResourceMutation,
  useUpdateClusterLinkingResourceMutation,
} from '@streaming-projects/service-definitions/streamingProjectsApi';
import {
  CLUSTER_LINKING_RESOURCES_GRID_FIELDNAMES,
  CLUSTER_LINKING_RESOURCES_GRID_HEADERS,
  CLUSTER_RESOURCES_GRID_FIELDNAMES,
  CLUSTER_RESOURCES_GRID_HEADERS,
} from '@streaming-projects/orgs/enums';
import { StyledContainer } from '@src/common-utils/styledComponents';
import { ConfirmModal } from '@presentational/modals/ConfirmModal';
import { Spacer } from '@presentational/spacing/Spacer';
import { ResourceEditRepriceWarningContainer } from '@streaming-projects/resource-definitions/auxilary-components/ResourceEditRepriceWarningContainer';
import { handleGridRowSelectionChanged } from '@src/common-utils/utils';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';
import {
  handleClusterLinkAddOrEdit,
  shouldContinueButtonBeDisabled,
} from '@streaming-projects/resource-definitions/cluster-linking-resource-definitions/utils';
import { SourceAndDestinationSameWarningContainer } from '@streaming-projects/resource-definitions/cluster-linking-resource-definitions/SourceAndDestinationSameWarningContainer';
import { ExistingLinkWarningContainer } from '@streaming-projects/resource-definitions/cluster-linking-resource-definitions/ExistingLinkWarningContainer';
import { ClusterLinkingInputsContainer } from '@streaming-projects/resource-definitions/cluster-linking-resource-definitions/ClusterLinkingInputsContainer';
import { SPMetaDataContext } from '@streaming-projects/orgs/contexts/SPMetaDataContext';
import { getSPClusterResourceConfig } from '@streaming-projects/orgs/config';

export const SPClusterLinkingConfigurationAddEditModal = ({
  isOpen,
  setOpen,
  isEditingMode = false,
  initialValues = null,
}) => {
  const { orgId } = useParams();
  const { providerRegionsMap, resourceConfigurationsNameLabelsMap, providerNameLabelMap } =
    useContext(SPMetaDataContext).metaData;
  const [selectedSourceRow, setSelectedSourceRow] = useState(null);
  const [selectedDestinationRow, setSelectedDestinationRow] = useState(null);
  const existingClusterLinks = useContext(SPOrgContext)?.resources?.cluster_linking_resources ?? [];

  const [addCLResource] = useAddClusterLinkingResourceMutation();
  const [updateCLResource] = useUpdateClusterLinkingResourceMutation();
  const { push } = useHistory();

  const initialValuesToUse = isEditingMode
    ? {
        [CLUSTER_LINKING_RESOURCES_GRID_FIELDNAMES.NAME]:
          initialValues[CLUSTER_LINKING_RESOURCES_GRID_HEADERS.NAME],
        [CLUSTER_LINKING_RESOURCES_GRID_FIELDNAMES.SOURCE_CLUSTER_ID]:
          initialValues[CLUSTER_LINKING_RESOURCES_GRID_HEADERS.SOURCE_CLUSTER_ID],
        [CLUSTER_LINKING_RESOURCES_GRID_FIELDNAMES.DESTINATION_CLUSTER_ID]:
          initialValues[CLUSTER_LINKING_RESOURCES_GRID_HEADERS.DESTINATION_CLUSTER_ID],
      }
    : {
        [CLUSTER_LINKING_RESOURCES_GRID_FIELDNAMES.NAME]: null,
      };

  const onSourceSelectionChanged = handleGridRowSelectionChanged(
    (rowData) => setSelectedSourceRow(rowData),
    () => setSelectedSourceRow(null)
  );

  const onDestinationSelectionChanged = handleGridRowSelectionChanged(
    (rowData) => setSelectedDestinationRow(rowData),
    () => setSelectedDestinationRow(null)
  );

  const validationSchema = object({
    name: string().label('Cluster Linking Name').required(),
  });

  const spCommonClusterColumnsConfig = getSPClusterResourceConfig({
    providerNameLabelMap,
    resourceConfigurationsNameLabelsMap,
    providerRegionsMap,
  });

  // todo::SP Add renderers
  // todo::SP Change this checkboxSelection after Nikhil merges his changes
  const clusterLinkingResourceDefinitionColumnDefs = [
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.ID,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.ID,
      hide: true,
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.NAME,
      backEndFieldName: CLUSTER_RESOURCES_GRID_FIELDNAMES.NAME,
      checkboxSelection: true,
      headerTooltip: CLUSTER_RESOURCES_GRID_HEADERS.NAME,
    },
    ...spCommonClusterColumnsConfig,
  ];

  return (
    <StyledContainer>
      <Formik
        autoComplete="off"
        enableReinitialize={true}
        initialValues={initialValuesToUse}
        onSubmit={() => {}}
        validateOnMount={true}
        validationSchema={validationSchema}
      >
        {(addClusterLinkingFormik) => {
          const isSourceChanged =
            initialValuesToUse.source_cluster_id !== selectedSourceRow?.['Cluster ID'];

          const isDestinationChanged =
            initialValuesToUse.destination_cluster_id !== selectedDestinationRow?.['Cluster ID'];

          const shouldContinueBeDisabled = shouldContinueButtonBeDisabled(
            selectedSourceRow,
            selectedDestinationRow,
            addClusterLinkingFormik,
            existingClusterLinks,
            isEditingMode,
            initialValuesToUse
          );

          // If we are in "Add" mode, this warning should be shown
          // If not, this warning should only be shown when either the source or the destination is changed by the user
          const shouldShowExistingLinkWarning = !(
            isEditingMode &&
            !isSourceChanged &&
            !isDestinationChanged
          );

          return (
            <ConfirmModal
              body={
                <>
                  <ClusterLinkingInputsContainer
                    columnDefs={clusterLinkingResourceDefinitionColumnDefs}
                    initialValues={initialValuesToUse}
                    onDestinationSelectionChanged={onDestinationSelectionChanged}
                    onSourceSelectionChanged={onSourceSelectionChanged}
                  />

                  <SourceAndDestinationSameWarningContainer
                    selectedDestinationRow={selectedDestinationRow}
                    selectedSourceRow={selectedSourceRow}
                  />

                  {shouldShowExistingLinkWarning && (
                    <ExistingLinkWarningContainer
                      existingClusterLinks={existingClusterLinks}
                      selectedDestinationRow={selectedDestinationRow}
                      selectedSourceRow={selectedSourceRow}
                    />
                  )}

                  {isEditingMode && (
                    <>
                      <Spacer y={5} />
                      <ResourceEditRepriceWarningContainer resourceType="Cluster Link" />
                    </>
                  )}
                </>
              }
              cancelButtonNegative={true}
              centered={true}
              disabled={shouldContinueBeDisabled}
              fullScreen={true}
              header={
                isEditingMode
                  ? 'Edit Cluster Linking Resource Configuration'
                  : 'Add New Cluster Linking Resource Configuration'
              }
              isOpen={isOpen}
              okButtonNegative={false}
              okButtonText="Continue"
              onClickHandlerForCancel={() => {
                addClusterLinkingFormik.resetForm();
                setSelectedSourceRow(null);
                setSelectedDestinationRow(null);
                return setOpen(false);
              }}
              onClickHandlerForOK={async () => {
                await handleClusterLinkAddOrEdit(
                  addClusterLinkingFormik,
                  isEditingMode,
                  addCLResource,
                  orgId,
                  selectedSourceRow,
                  selectedDestinationRow,
                  initialValues,
                  updateCLResource,
                  push,
                  setOpen,
                  setSelectedSourceRow,
                  setSelectedDestinationRow
                );
              }}
            />
          );
        }}
      </Formik>
    </StyledContainer>
  );
};
