// @flow

import {
  STREAM_GOVERNANCE_ADVANCED_PACKAGE_NAME,
  STREAM_GOVERNANCE_ESSENTIALS_PACKAGE_NAME,
} from '@src/constants';
import { number, object } from 'yup';
import { getMonthName } from '@components/cluster/cluster-input-table/utils';

export const getIfEssentialsPackageIsSelected = (selectedPackageName) => {
  return (
    selectedPackageName &&
    selectedPackageName.toLowerCase() === STREAM_GOVERNANCE_ESSENTIALS_PACKAGE_NAME.toLowerCase()
  );
};

export const getIfAdvancedPackageIsSelected = (selectedPackageName) => {
  return (
    selectedPackageName &&
    selectedPackageName.toLowerCase() === STREAM_GOVERNANCE_ADVANCED_PACKAGE_NAME.toLowerCase()
  );
};

const getSPStreamGovernancePoolValidationSchema = (maxLimitsObject) =>
  object({
    schema_count: number()
      .integer()
      .label('Number Of Schemas')
      .typeError('Number Of Schemas is required!')
      .min(0)
      .required()
      .test(
        'max',
        'Number of schemas must be less than or equal to 20,000 (Hard limit for schemas per environment).',
        (value, { createError, path }) => {
          const isError = value > maxLimitsObject.schema_count;
          if (isError) {
            return createError({
              message: `Number of schemas must be less than or equal to ${maxLimitsObject.schema_count} (Hard limit for schemas per environment).`,
              path: path,
            });
          }
          return true;
        }
      ),

    rules_count: number()
      .integer()
      .label('Number Of Rules')
      .typeError('Number Of Rules is required!')
      .min(0)
      .required()
      .test('max', 'Number Of Rules Test', (value, { createError, path }) => {
        const isError = value > maxLimitsObject.rules_count;

        if (isError) {
          return createError({
            message: `Rules count cannot be greater than ${maxLimitsObject.rules_count}`,
            path: path,
          });
        }
        return true;
      }),
  });

export const getSPStreamGovernanceMonthlyInputsValidation = (numberOfMonths, maxLimitsObject) => {
  const schemaObject = {};
  for (let i = 1; i <= numberOfMonths; i++) {
    schemaObject[getMonthName(i)] = getSPStreamGovernancePoolValidationSchema(maxLimitsObject);
  }
  return object({ months: object(schemaObject) });
};
