// @flow
import React, { useContext } from 'react';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { GenericSaveResetButtons } from '@components/GenericSaveResetButtons';
import { Spacer } from '@presentational/spacing/Spacer';
import { Grid } from 'semantic-ui-react';
import { useUpdateClusterWorkloadMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { useParams } from 'react-router-dom';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { RetentionApprovalRequiredContainer } from '@src/triggers/RetentionApprovalRequiredContainer';
import { SPMetaDataContext } from '@streaming-projects/orgs/contexts/SPMetaDataContext';
import { shouldShowFFOption } from '@streaming-projects/utils';
import useIsAdmin from '@streaming-projects/useIsAdmin';
import { If } from '@presentational/If';

import { useSPClusterWorkloadContext } from '../contexts/SPClusterWorkloadContextProvider';
import { CLUSTER_WORKLOAD_DETAILS_INPUT_CONFIG } from '../config';

import { SPClusterWorkloadNameContainer } from './SPClusterWorkloadNameContainer';
import { SPClusterRetentionContainer } from './SPClusterRetentionContainer';
import { SPClusterRetentionInfiniteContainer } from './SPClusterRetentionInfiniteContainer';
import { SPClusterFollowerFetchContainer } from './SPClusterFollowerFetchContainer';
import { SPClusterWorkloadExistingStorageContainer } from './SPClusterWorkloadExistingStorageContainer';
import { SPClusterWorkloadCKUContainer } from './SPClusterWorkloadCKUContainer';

export const SPClusterWorkloadDetailsContainer = () => {
  const { orgId, spId, clusterWorkloadId } = useParams();
  const [updateClusterWorkload] = useUpdateClusterWorkloadMutation();
  const spStreamingProjectData = useContext(SPStreamingProjectContext);
  const dealDuration = useContext(SPMetaDataContext).metaData.deal_duration;

  const spClusterWorkloadData = useSPClusterWorkloadContext();

  const { retention } = spClusterWorkloadData.cluster_details;

  const isUserAdmin = useIsAdmin();

  const shouldShowFFOptionVariable = shouldShowFFOption(
    spClusterWorkloadData.cluster_resource_details,
    isUserAdmin
  );

  const { isBaseLineSP } = spStreamingProjectData;

  return (
    <>
      <PaddedAndRaisedSegment>
        <>
          <GenericSaveResetButtons
            fieldsConfig={CLUSTER_WORKLOAD_DETAILS_INPUT_CONFIG}
            onSaveHandler={async ({
              name,
              retention_days: retentionDays,
              retention_infinite: retentionInfinite,
              is_follower_fetch_enabled: isFollowerFetchEnabled = false,
              existing_storage: existingStorage,
              cku,
            }) => {
              return await updateClusterWorkload({
                orgId,
                spId,
                clusterWorkloadId,
                payload: {
                  cluster_resource_id: spClusterWorkloadData.cluster_resource_id,
                  sp_version: spStreamingProjectData?.sp?.version,
                  name,
                  inputs: {
                    is_follower_fetch_enabled: isFollowerFetchEnabled,
                    retention: {
                      retention_days: retentionDays,
                      is_finite_retention: !retentionInfinite,
                    },
                  },
                  metadata: {
                    existing_storage: existingStorage,
                    cku,
                  },
                },
              });
            }}
          />
          <Spacer y={20} />
          <Grid>
            <Grid.Row>
              <Grid.Column width={4}>
                <SPClusterWorkloadNameContainer disabled={false} />
              </Grid.Column>
              <Grid.Column width={4}>
                <SPClusterRetentionContainer retention={retention} />
                <RetentionApprovalRequiredContainer isCustomerViewEnabled={false} />
              </Grid.Column>
              <Grid.Column width={4}>
                <SPClusterRetentionInfiniteContainer
                  dealDurationInDays={dealDuration?.deal_duration_days}
                />
              </Grid.Column>
              <Grid.Column width={4}>
                {shouldShowFFOptionVariable && <SPClusterFollowerFetchContainer />}
              </Grid.Column>
            </Grid.Row>
            <If test={isBaseLineSP}>
              <Grid.Row>
                <Grid.Column width={4}>
                  <SPClusterWorkloadExistingStorageContainer disabled={false} />
                </Grid.Column>
                <Grid.Column width={4}>
                  <SPClusterWorkloadCKUContainer disabled={false} />
                </Grid.Column>
              </Grid.Row>
            </If>
          </Grid>
        </>
      </PaddedAndRaisedSegment>
    </>
  );
};
