// @flow
import React from 'react';
import { string } from 'yup';
import { DATE_INPUT_TYPE, FILE_INPUT_TYPE } from '@src/formik-utils/consts';
import { BACKEND_FIELDNAMES, FRONT_END_DISPLAY_NAMES } from '@streaming-projects/enums';
import { convertLocalToUTCDate, convertUTCToLocalDate } from '@src/common-utils/utils';
import { convertConfigArrayToMap } from '@src/configuration/utils';
import { Tooltip } from '@presentational/Tooltip';

export const STREAMING_PROJECTS_BASELINE_DETAILS_CONFIG = [
  {
    displayName: FRONT_END_DISPLAY_NAMES.SP_START_DATE,
    backendFieldName: BACKEND_FIELDNAMES.SP_START_DATE,
    validation: string().label(FRONT_END_DISPLAY_NAMES.SP_START_DATE).required(),
    inputType: DATE_INPUT_TYPE,
    inputTransformationFunc: convertUTCToLocalDate,
    transformationFunctionToSendToBackEnd: convertLocalToUTCDate,
  },
  {
    displayName: FRONT_END_DISPLAY_NAMES.SP_END_DATE,
    backendFieldName: BACKEND_FIELDNAMES.SP_END_DATE,
    validation: string().label(FRONT_END_DISPLAY_NAMES.SP_END_DATE).required(),
    inputType: DATE_INPUT_TYPE,
    inputTransformationFunc: convertUTCToLocalDate,
    transformationFunctionToSendToBackEnd: convertLocalToUTCDate,
  },
  {
    displayName: 'Input File',
    backendFieldName: 'input_file',
    validation: string().label('Input File').required(),
    inputType: FILE_INPUT_TYPE,
    tooltip: (
      <Tooltip
        link="https://prod-tableau.confluent.io/#/views/SPE-BaselineImport/SPE-BaselineImport?:iid=1"
        text="Download Baseline"
      />
    ),
  },
];

export const STREAMING_PROJECTS_BASELINE_DETAILS_CONFIG_MAP = convertConfigArrayToMap(
  STREAMING_PROJECTS_BASELINE_DETAILS_CONFIG
);
