// @flow
import { Grid } from 'semantic-ui-react';
import { ClusterLinkingNameContainer } from '@streaming-projects/resource-definitions/cluster-linking-resource-definitions/ClusterLinkingNameContainer';
import { SourceClusterSelectionContainer } from '@streaming-projects/resource-definitions/cluster-linking-resource-definitions/SourceClusterSelectionContainer';
import { DestinationClusterSelectionContainer } from '@streaming-projects/resource-definitions/cluster-linking-resource-definitions/DestinationClusterSelectionContainer';
import React from 'react';

export const ClusterLinkingInputsContainer = ({
  columnDefs,
  initialValues,
  onSourceSelectionChanged,
  onDestinationSelectionChanged,
}) => (
  <Grid columns={1}>
    <Grid.Row>
      <Grid.Column width={5}>
        <ClusterLinkingNameContainer />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column width={14}>
        <SourceClusterSelectionContainer
          columnDefs={columnDefs}
          initialValues={initialValues}
          onSelectionChanged={onSourceSelectionChanged}
        />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column width={14}>
        <DestinationClusterSelectionContainer
          columnDefs={columnDefs}
          initialValues={initialValues}
          onSelectionChanged={onDestinationSelectionChanged}
        />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);
