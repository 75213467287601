// @flow

import React, { useContext } from 'react';
import SPCommitSummary from '@streaming-projects/orgs/org-top-level/sp-commit-summary/index';
import SPResourceConfigurations from '@streaming-projects/resource-definitions/SPResourceConfigurationsTopLevelContainer';
import { getAccordianPanelDetailsObject } from '@src/common-utils/utils';
import { AccordionsList } from '@presentational/accordions/AccordionsList';
import { SPOrganizationDetailsContainer } from '@streaming-projects/orgs/org-top-level/SPOrganizationDetailsContainer';
import { StreamingProjectsSummaryContainer } from '@streaming-projects/orgs/org-top-level/sp-summary/StreamingProjectsSummaryContainer';
import { SPDiscountsTopLevelContainer } from '@streaming-projects/orgs/org-top-level/sp-custom-discounts/SPDiscountsTopLevelContainer';
import { BaselineSummaryContainer } from '@streaming-projects/baseline/BaselineSummaryContainer';
import { IS_DEFAULT_COMMIT_SECTION_LIVE } from '@streaming-projects/constants';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';

export const SPOrgAccordionsContainer = () => {
  const spOrgData = useContext(SPOrgContext);
  const isDefaultCommitSectionSentByBE = spOrgData[BACKEND_FIELDNAMES.DEFAULT_COMMIT_DETAILS];

  const accordionPanels = [
    getAccordianPanelDetailsObject('Org Details', <SPOrganizationDetailsContainer />),
    getAccordianPanelDetailsObject('Discounts', <SPDiscountsTopLevelContainer />),
    getAccordianPanelDetailsObject('Baseline', <BaselineSummaryContainer />),
    getAccordianPanelDetailsObject(
      'Streaming Projects Configurations',
      <StreamingProjectsSummaryContainer />
    ),
    getAccordianPanelDetailsObject('Resource Configurations', <SPResourceConfigurations />),
  ];

  if (IS_DEFAULT_COMMIT_SECTION_LIVE && isDefaultCommitSectionSentByBE) {
    accordionPanels.push(getAccordianPanelDetailsObject('Commit Details', <SPCommitSummary />));
  }

  return (
    <>
      <AccordionsList panels={accordionPanels} />
    </>
  );
};
