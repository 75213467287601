// @flow
import React from 'react';
import { FRONT_END_DISPLAY_NAMES } from '@streaming-projects/enums';
import { SPClusterLinkingWorkloadEnabledContainer } from '@streaming-projects/workloads/cluster-linking-workloads/enable/SPClusterLinkingWorkloadEnabledContainer';

export const SPClusterLinkingWorkloadEnabledCellRenderer = (props) => {
  if (props.value == null) {
    return null;
  }

  const clusterLinkingWorkloadId = props.data[FRONT_END_DISPLAY_NAMES.WORKLOAD_ID];
  const clusterLinkingResourceId = props.data[FRONT_END_DISPLAY_NAMES.RESOURCE_ID];

  return (
    <SPClusterLinkingWorkloadEnabledContainer
      addLabel={false}
      checked={props.value}
      clusterLinkingResourceId={clusterLinkingResourceId}
      clusterLinkingWorkloadId={clusterLinkingWorkloadId}
    />
  );
};
