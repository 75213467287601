// @flow
import React, { useContext } from 'react';
import { DeleteRenderer } from '@components/views/grid-utils/renderers';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { FRONT_END_DISPLAY_NAMES } from '@streaming-projects/enums';
import { SPClusterWorkloadDeleteWarningModal } from '@streaming-projects/workloads/cluster-workloads/delete/SPClusterWorkloadDeleteWarningModal';

export const SPClusterWorkloadDeleteRenderer = ({ data }) => {
  const spStreamingProjectData = useContext(SPStreamingProjectContext);
  return (
    <DeleteRenderer>
      {(isDeleteModalOpen, setDeleteModalOpen) => {
        return (
          <SPClusterWorkloadDeleteWarningModal
            clusterWorkloadId={data[FRONT_END_DISPLAY_NAMES.WORKLOAD_ID]}
            clusterWorkloadName={data[FRONT_END_DISPLAY_NAMES.WORKLOAD_NAME]}
            clusterWorkloadResourceId={data[FRONT_END_DISPLAY_NAMES.RESOURCE_ID]}
            isOpen={isDeleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            spVersion={spStreamingProjectData?.sp?.version}
          />
        );
      }}
    </DeleteRenderer>
  );
};
