// @flow
import { DataFetcherContainer } from '@presentational/DataFetcherContainer';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { shouldSkipDataFetching } from '@src/common-utils/utils';

import SPAsyncCalcContainer from './SPAsyncCalcContainer';

/**
 * Wrapper for Pricing Summary Sections which uses the pricingStatusFetchingFunction to check the status
 * of pricing and then renders the children components if the pricing is ready.
 *
 * - If the pricing is not ready, Please wait message is shown.
 * - If the pricing fails, An error message is shown with a button to recalculate.
 * - If the pricing is ready, Children components are rendered.
 *
 * @param {React.ReactElement} props.children The children components to be rendered when the pricing is ready.
 * @param {Function} props.pricingStatusFetchingFunction The function to fetch the pricing status.
 * @param {Function} props.useRecalcRequestMutation The mutation function to recalculate the pricing.
 */
const SPAsyncCalcWrapper = ({
  children,
  pricingStatusFetchingFunction,
  useRecalcRequestMutation,
}) => {
  const params = useParams();

  const dataFetchingArgs = useMemo(
    () => [{ ...params }, { skip: shouldSkipDataFetching(params) }],
    [params]
  );

  return (
    <DataFetcherContainer
      dataFetchingArgs={dataFetchingArgs}
      dataFetchingFunction={pricingStatusFetchingFunction}
    >
      {(data) => (
        <SPAsyncCalcContainer data={data} useRecalcRequestMutation={useRecalcRequestMutation}>
          {children}
        </SPAsyncCalcContainer>
      )}
    </DataFetcherContainer>
  );
};

export default SPAsyncCalcWrapper;
