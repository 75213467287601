// @flow
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SPFlinkWorkloadDeleteWarningModal } from '@streaming-projects/workloads/flink-workloads/delete/SPFlinkWorkloadDeleteWarningModal';
import { FlinkPoolWorkloadContext } from '@streaming-projects/workloads/flink-workloads/contexts/FlinkPoolWorkloadContext';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { DeleteButton } from '@presentational/buttons/DeleteButton';

export const SPFlinkWorkloadDeleteContainer = () => {
  const { flinkWorkloadId } = useParams();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const spFlinkPoolWorkloadData = useContext(FlinkPoolWorkloadContext);
  const spStreamingProjectData = useContext(SPStreamingProjectContext);

  return (
    <>
      <DeleteButton
        onClick={() => {
          setDeleteModalOpen(true);
        }}
        size="large"
      />
      <SPFlinkWorkloadDeleteWarningModal
        flinkWorkloadId={flinkWorkloadId}
        flinkWorkloadName={spFlinkPoolWorkloadData?.name}
        flinkWorkloadResourceId={spFlinkPoolWorkloadData?.flink_pool_config_id}
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        spVersion={spStreamingProjectData?.sp?.version}
      />
    </>
  );
};
