// @flow

import React, { useContext, useState } from 'react';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { PrimaryButton } from '@presentational/buttons/PrimaryButton';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { DataGrid } from '@components/views/grid-utils/DataGrid';
import {
  STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES,
  STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS,
} from '@streaming-projects/orgs/enums';
import { ENABLED_DISABLED_FILTER_PARAMS } from '@components/views/grid-utils/constants';
import { BACKEND_FIELDNAMES, FRONT_END_DISPLAY_NAMES } from '@streaming-projects/enums';
import { SPStreamGovernanceWorkloadAddModal } from '@streaming-projects/sp-page/workloads-summary/stream-governance-workloads-summary/SPStreamGovernanceWorkloadAddModal';
import { SPStreamGovernanceWorkloadNameRenderer } from '@streaming-projects/sp-page/workloads-summary/stream-governance-workloads-summary/SPStreamGovernanceWorkloadNameRenderer';
import { SPStreamGovernanceWorkloadEnabledCellRenderer } from '@streaming-projects/sp-page/workloads-summary/stream-governance-workloads-summary/SPStreamGovernanceWorkloadEnabledCellRenderer';
import { SPStreamGovernanceWorkloadDeleteRenderer } from '@streaming-projects/sp-page/workloads-summary/stream-governance-workloads-summary/SPStreamGovernanceWorkloadDeleteRenderer';
import { SPMetaDataContext } from '@streaming-projects/orgs/contexts/SPMetaDataContext';
import {
  getMappedDataValueGetter,
  getRegionName,
} from '@streaming-projects/resource-definitions/auxilary-components/MappedDataRenderer';
import { ADD_NEW_WORKLOAD_MESSAGE } from '@streaming-projects/constants';

export const SPStreamGovernanceSPPageWorkloadSummaryContainer = () => {
  const [isSGWorkloadAddModalOpen, setSGWorkloadAddModalOpen] = useState(false);
  const { providerRegionsMap, providerNameLabelMap } = useContext(SPMetaDataContext).metaData;
  const spStreamingProjectData = useContext(SPStreamingProjectContext);
  const inputDataSource =
    spStreamingProjectData?.use_case_summaries?.stream_governance_summaries ?? [];
  const areAnyWorkloadsConfigured = inputDataSource.length > 0;

  const columnDefs = [
    {
      field: FRONT_END_DISPLAY_NAMES.WORKLOAD_ID,
      backEndFieldName: BACKEND_FIELDNAMES.WORKLOAD_ID,
      hide: true,
    },
    {
      field: FRONT_END_DISPLAY_NAMES.WORKLOAD_NAME,
      backEndFieldName: BACKEND_FIELDNAMES.WORKLOAD_NAME,
      cellRenderer: SPStreamGovernanceWorkloadNameRenderer,
    },
    {
      field: FRONT_END_DISPLAY_NAMES.RESOURCE_ID,
      backEndFieldName: BACKEND_FIELDNAMES.RESOURCE_ID,
      hide: true,
    },
    {
      field: STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.ENVIRONMENT_NAME,
      backEndFieldName: BACKEND_FIELDNAMES.RESOURCE_NAME,
    },
    {
      field: FRONT_END_DISPLAY_NAMES.IS_ENABLED,
      filter: true,
      filterParams: ENABLED_DISABLED_FILTER_PARAMS,
      cellRenderer: SPStreamGovernanceWorkloadEnabledCellRenderer,
      backEndFieldName: BACKEND_FIELDNAMES.IS_ENABLED,
    },
    {
      field: STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.PROVIDER,
      backEndFieldName: STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.PROVIDER,
      valueGetter: getMappedDataValueGetter(providerNameLabelMap),
    },
    {
      field: STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.REGION,
      backEndFieldName: STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.REGION,
      tooltipValueGetter: (params) => getRegionName(params, providerRegionsMap),
      valueGetter: (params) => getRegionName(params, providerRegionsMap),
    },
    {
      field: '',
      filter: false,
      cellRenderer: SPStreamGovernanceWorkloadDeleteRenderer,
      suppressSizeToFit: true,
      maxWidth: 60,
      sortable: false,
    },
  ];

  return (
    <PaddedAndRaisedSegment>
      <PrimaryButton
        onClick={() => {
          setSGWorkloadAddModalOpen(true);
        }}
        size="mini"
        text={ADD_NEW_WORKLOAD_MESSAGE}
      />

      <SPStreamGovernanceWorkloadAddModal
        isOpen={isSGWorkloadAddModalOpen}
        setOpen={setSGWorkloadAddModalOpen}
      />

      {areAnyWorkloadsConfigured && (
        <DataGrid
          columnDefs={columnDefs}
          downloadAllCols={false}
          inputDataSource={inputDataSource}
          label="Stream Governance Workloads Summary"
          sizeColumnsToFitInitially={true}
          tooltipShowDelay={0}
        />
      )}
    </PaddedAndRaisedSegment>
  );
};
