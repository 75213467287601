/* eslint-disable react/jsx-no-undef */
// @flow
import React from 'react';
import {
  useCreateMetricDiscountMutation,
  useDeleteMetricDiscountGroupMutation,
  useDeleteMetricDiscountMutation,
  useUpdateGlobalDiscountMutation,
  useUpdateMetricDiscountMutation,
} from '@streaming-projects/service-definitions/streamingProjectsApi';
import { SPMLDMutationsContext } from '@streaming-projects/orgs/org-top-level/sp-custom-discounts/contexts/SPMLDMutationsContext';
import useIsAdmin from '@streaming-projects/useIsAdmin';

import { PaddedAndRaisedSegment } from '../../../../presentational/PaddedAndRaisedSegment';

import { SPMetricsLevelDiscountsTopLevelContainer } from './metrics-level-configuration/SPMetricsLevelDiscountsTopLevelContainer';
import { SPGlobalDiscountContainer } from './global-discount/SPGlobalDiscountContainer';
import { SPMetricLevelDiscountLabelContainer } from './metrics-level-configuration/SPMetricLevelDiscountLabelContainer';

export const SPDiscountsTopLevelContainer = () => {
  const disabled = false; // todo::SP this needs to be taken care of

  const isUserAdmin = useIsAdmin();

  const readOnlyModeToUse = !isUserAdmin || disabled;

  const mutations = {
    globalDiscountUpdateMutation: useUpdateGlobalDiscountMutation(),
    metricDiscountCreateMutation: useCreateMetricDiscountMutation(),
    metricDiscountUpdateMutation: useUpdateMetricDiscountMutation(),
    metricDiscountDeleteMutation: useDeleteMetricDiscountMutation(),
    metricDiscountGroupDeleteMutation: useDeleteMetricDiscountGroupMutation(),
  };

  return (
    <SPMLDMutationsContext.Provider value={mutations}>
      <PaddedAndRaisedSegment disabled={disabled}>
        <SPGlobalDiscountContainer readOnlyMode={false} />
        <div style={{ marginLeft: '1.5rem', marginTop: '2rem', marginBottom: '2rem' }}>
          {/* { todo::SP reuse the styles from styles component} */}
          <SPMetricLevelDiscountLabelContainer />
        </div>
        <SPMetricsLevelDiscountsTopLevelContainer readOnlyMode={readOnlyModeToUse} />
      </PaddedAndRaisedSegment>
    </SPMLDMutationsContext.Provider>
  );
};
