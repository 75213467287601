// @flow

import { getIfEssentialsPackageIsSelected } from '@streaming-projects/workloads/stream-governance-workloads/utils';

const getSelectedPackageName = (context) => {
  return context.stream_governance_resource_details.package;
};

export const streamGovernanceWorkloadMonthlyInputRowsConfig = [
  {
    backendName: 'is_enabled',
    displayName: 'Active',
    isBooleanField: true,
    defaultValue: true,
  },
  {
    backendName: 'schema_count',
    displayName: 'Schema Count',
    defaultValue: 0,
    showOrHideFunc: (values, context) => {
      const selectedPackageName = getSelectedPackageName(context);
      return getIfEssentialsPackageIsSelected(selectedPackageName);
    },
  },
  {
    backendName: 'rules_count',
    displayName: 'Rules Count',
    defaultValue: 0,
    showOrHideFunc: (values, context) => {
      const selectedPackageName = getSelectedPackageName(context);
      return !getIfEssentialsPackageIsSelected(selectedPackageName);
    },
  },
];
