// @flow
import React, { useContext, useState } from 'react';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { STREAMING_PROJECTS_SP_DETAILS_CONFIG } from '@streaming-projects/sp-page/config';
import { getFormikFormInputsFromColConfigAndInputSource } from '@src/common-utils/utils';
import { StyledContainer } from '@src/common-utils/styledComponents';
import { Form } from '@src/formik-utils/formikSUIWrapper';
import { DeleteButton } from '@presentational/buttons/DeleteButton';
import { SPDeleteWarningModal } from '@streaming-projects/orgs/org-top-level/sp-summary/SPDeleteWarningModal';
import { Spacer } from '@presentational/spacing/Spacer';
import { SPStreamingProjectAccordionsContainer } from '@streaming-projects/sp-page/accordions-container/SPStreamingProjectAccordionsContainer';
import { getSPDetailsFieldConfigByAddingBaselineNameValidation } from '@streaming-projects/sp-page/utils';

export const SPStreamingProjectTopLevelContainerForm = () => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const spSPData = useContext(SPStreamingProjectContext);
  const { spId } = useParams();
  const spName = spSPData?.sp?.name;
  const currVersion = spSPData?.sp?.version;
  const spSPInputsSource = {
    name: spName,
    spId,
    spSfdcId: spSPData?.sp?.sp_sfdc_id,
    start_date: spSPData?.sp?.start_date,
    end_date: spSPData?.sp?.end_date,
  };

  let fieldsConfigToUse = STREAMING_PROJECTS_SP_DETAILS_CONFIG;
  if (!spSPData.isBaseLineSP) {
    // If it is not a Baseline SP, then, add the dynamic validation that SP Name cannot be Baseline
    fieldsConfigToUse = getSPDetailsFieldConfigByAddingBaselineNameValidation();
  }

  const { initialValues, initialTouched, validationSchema } =
    getFormikFormInputsFromColConfigAndInputSource(fieldsConfigToUse, spSPInputsSource);

  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialTouched={initialTouched}
        initialValues={initialValues}
        onSubmit={() => {}}
        validateOnMount={true}
        validationSchema={validationSchema}
      >
        {() => (
          <Form autoComplete="off">
            <DeleteButton
              onClick={() => {
                setDeleteModalOpen(true);
              }}
              size="large"
            />
            <SPDeleteWarningModal
              isOpen={isDeleteModalOpen}
              onClose={() => setDeleteModalOpen(false)}
              spId={spId}
              spName={spName}
              version={currVersion}
            />
            <Spacer y={10} />
            <SPStreamingProjectAccordionsContainer />
          </Form>
        )}
      </Formik>
    </StyledContainer>
  );
};
