// @flow
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import { FLINK_POOL_RESOURCE_INPUTS_DETAILS_CONFIG_MAP } from '@streaming-projects/resource-definitions/flink-resource-definitions/flink-resource-inputs-config';
import { FLINK_POOL_RESOURCES_GRID_FIELDNAMES } from '@streaming-projects/orgs/enums';
import { getDropdownOptions } from '@components/stream-governance/utils';
import { AWS, AZURE, GCP } from '@src/constants';
import { SelectField } from '@src/formik-utils/FormFields';
import { SPMetaDataContext } from '@streaming-projects/orgs/contexts/SPMetaDataContext';

export const FlinkPoolResourceProviderContainer = ({
  disabled,
  disableOnFormErrors = null,
  fluid = null,
}) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const flinkPoolProviderConfig = FLINK_POOL_RESOURCE_INPUTS_DETAILS_CONFIG_MAP.get(
    FLINK_POOL_RESOURCES_GRID_FIELDNAMES.PROVIDER
  );

  const dataUniverse = useContext(SPMetaDataContext).metaData.flink_pool_config.flink_pool_params;

  const flinkPoolProviderOptions = getDropdownOptions(
    dataUniverse,
    values,
    flinkPoolProviderConfig,
    false,
    true,
    false,
    false,
    [AWS, AZURE, GCP]
  );

  return (
    <SelectField
      disableOnFormErrors={disableOnFormErrors}
      disabled={disabled}
      fieldDisplayName={flinkPoolProviderConfig.displayName}
      fieldName={flinkPoolProviderConfig.backendFieldName}
      fluid={fluid}
      onChange={async () => {
        const regionBackendFieldName = FLINK_POOL_RESOURCES_GRID_FIELDNAMES.REGION;
        await setFieldTouched(regionBackendFieldName, true);
        await setFieldValue(regionBackendFieldName, null);
      }}
      options={flinkPoolProviderOptions}
    />
  );
};
