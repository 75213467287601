// @flow
import { boolean, string } from 'yup';
import {
  STREAM_GOVERNANCE_WORKLOADS_INPUTS_DISPLAY_NAMES,
  STREAM_GOVERNANCE_WORKLOADS_INPUTS_FIELDNAMES,
} from '@streaming-projects/orgs/enums';
import { convertConfigArrayToMap, createFormNameToFieldsMap } from '@src/configuration/utils';
import { clusterMonthlyDataInputTransformationFunc } from '@components/cluster/cluster-input-table/utils';

export const STREAM_GOVERNANCE_WORKLOAD_DETAILS_CONFIG = [
  {
    displayName: STREAM_GOVERNANCE_WORKLOADS_INPUTS_DISPLAY_NAMES.WORKLOAD_NAME,
    backendFieldName: STREAM_GOVERNANCE_WORKLOADS_INPUTS_FIELDNAMES.WORKLOAD_NAME,
    validation: string()
      .label(STREAM_GOVERNANCE_WORKLOADS_INPUTS_DISPLAY_NAMES.WORKLOAD_NAME)
      .required(),
  },
  {
    displayName: STREAM_GOVERNANCE_WORKLOADS_INPUTS_DISPLAY_NAMES.IS_ENABLED,
    backendFieldName: STREAM_GOVERNANCE_WORKLOADS_INPUTS_FIELDNAMES.IS_ENABLED,
    validation: boolean()
      .label(STREAM_GOVERNANCE_WORKLOADS_INPUTS_DISPLAY_NAMES.IS_ENABLED)
      .required(),
  },
  {
    displayName: STREAM_GOVERNANCE_WORKLOADS_INPUTS_DISPLAY_NAMES.MONTHLY_INPUTS,
    backendFieldName: STREAM_GOVERNANCE_WORKLOADS_INPUTS_FIELDNAMES.MONTHLY_INPUTS,
    inputTransformationFunc: clusterMonthlyDataInputTransformationFunc,
  },
];

export const STREAM_GOVERNANCE_WORKLOAD_DETAILS_CONFIG_MAP = convertConfigArrayToMap(
  STREAM_GOVERNANCE_WORKLOAD_DETAILS_CONFIG
);

export const STREAM_GOVERNANCE_WORKLOAD_DETAILS_FORM_NAMES_TO_FIELDS_MAP =
  createFormNameToFieldsMap(STREAM_GOVERNANCE_WORKLOAD_DETAILS_CONFIG);
