// @flow
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';

import { SPMLDMutationsContext } from '../contexts/SPMLDMutationsContext';
import {
  FieldArrayGridConfigItemContext,
  FieldArrayGridDeleteButton,
} from '../../../../../../formik-utils/FieldArrayGrid';
import { METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_METRIC_ID_BACKEND_NAME } from '../../../../../../constants';
import { getIfAllMetricsAreFrozen } from '../../../../../custom-discounts/utils';

const getIfMetricNameDeleteButtonShouldBeDisabled = (readOnlyMode, rowValues, formik) => {
  if (readOnlyMode) {
    return true;
  }

  if (rowValues.dimensions_config_array.length === 0) {
    return false;
  }

  if (!formik.isValid) {
    return true;
  }

  return !getIfAllMetricsAreFrozen(formik.values);
};

export const SPMetricNameDeleteButtonContainer = () => {
  const formik = useFormikContext();
  const orgData = useContext(SPOrgContext);
  const {
    metricDiscountGroupDeleteMutation: [deleteMetriclDiscountGroup],
  } = useContext(SPMLDMutationsContext);
  const { orgId } = useParams();
  const { rowValues, readOnlyMode } = useContext(FieldArrayGridConfigItemContext);
  const payload = {
    org_version: orgData?.org?.version,
    metric_id: Number(rowValues[METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_METRIC_ID_BACKEND_NAME]),
  };

  const deleteMetricsLevelDiscountParams = {
    orgId: orgId,
    payload: payload,
  };

  return (
    <FieldArrayGridDeleteButton
      deleteFunc={deleteMetriclDiscountGroup}
      deleteFuncParams={deleteMetricsLevelDiscountParams}
      disabled={getIfMetricNameDeleteButtonShouldBeDisabled(readOnlyMode, rowValues, formik)}
    />
  );
};
