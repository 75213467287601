// @flow
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getIn, useFormikContext } from 'formik';

import {
  CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG,
  CLUSTER_NETWORKING_TYPE_BACKEND_NAME,
  CONNECTOR_ALLOWED_NETWORKING_TYPES,
  CONNECTOR_AVAILABLE_NETWORKING_TYPES_LIST_BACKEND_NAME,
  DEFAULT_VALUE_FOR_DB_ROW_ID_STR,
} from '../../../constants';
import { ClusterInputTable } from '../cluster-input-table/ClusterInputTable';
import { toastError } from '../../presentational/notifications/utils';
import { EstimateContext } from '../../../contexts/EstimateContext';
import { useUpdateClusterMutation } from '../../../service-definitions/estimates';
import { getConnectorInputsFromConnectorValue } from '../cluster-input-table/utils';
import { Spacer } from '../../presentational/spacing/Spacer';
import { SelectField } from '../../../formik-utils/FormFields';
import { CLUSTER_DETAILS_CONFIG_MAP } from '../../../configuration/cluster-details';
import { getDropdownOptions } from '../../stream-governance/utils';
import { ClusterContext } from '../../../contexts/ClusterContext';
import { StyledSegmentWithBorderColor } from '../../../common-utils/styledComponents';

import { connectorsRowConfig } from './ConnectorsTopLevelContainer';

export const getConnectorNetworkingOptions = (
  connectorNameToRecordsMap,
  connectorName,
  values,
  connectorNetworkingTypeOptionsConfig
) => {
  let fullConnectorsList = connectorNameToRecordsMap.get(connectorName);
  fullConnectorsList = fullConnectorsList.filter((x) =>
    CONNECTOR_ALLOWED_NETWORKING_TYPES.includes(x[CLUSTER_NETWORKING_TYPE_BACKEND_NAME])
  );

  return getDropdownOptions(
    fullConnectorsList,
    values,
    connectorNetworkingTypeOptionsConfig,
    false,
    false,
    false,
    true
  );
};

export const ConnectorsMonthlyInputContainer = ({
  connector,
  connectorIndex,
  disabled,
  keyToUse,
}) => {
  const { estimateId, clusterId } = useParams();
  const estimate = useContext(EstimateContext);
  const [updateCluster] = useUpdateClusterMutation();
  const { values } = useFormikContext();

  const cluster = useContext(ClusterContext);
  const connectorNameToRecordsMap = cluster?.cluster_configs?.connectorNameToRecordsMap;

  const connectorLabel = connector.connectorLabel;
  const keyNameInValuesToUseForThisConnector = `${CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG}.connectors[${connectorIndex}]`;

  const connectorNetworkingTypeOptionsConfig = CLUSTER_DETAILS_CONFIG_MAP.get(
    CONNECTOR_AVAILABLE_NETWORKING_TYPES_LIST_BACKEND_NAME
  );

  const connectorName = connector.connectorName;
  const connectorNetworkingTypeOptions = getConnectorNetworkingOptions(
    connectorNameToRecordsMap,
    connectorName,
    values,
    connectorNetworkingTypeOptionsConfig
  );

  return (
    <>
      <ClusterInputTable
        AdditionalComponent={
          <>
            <Spacer y={40} />
            <StyledSegmentWithBorderColor compact={true}>
              <SelectField
                disabled={false}
                fieldDisplayName={connectorNetworkingTypeOptionsConfig.displayName}
                fieldName={`${keyNameInValuesToUseForThisConnector}.connectorNetworkingType`}
                fluid={false}
                options={connectorNetworkingTypeOptions}
                search={false}
              />
            </StyledSegmentWithBorderColor>
          </>
        }
        connectorIndex={connectorIndex}
        disabled={disabled}
        includeSaveReset={true}
        includeTableDownload={true}
        isNewElemAddedByUI={connector.connectorId === DEFAULT_VALUE_FOR_DB_ROW_ID_STR}
        key={keyToUse}
        keyNameInValues={keyNameInValuesToUseForThisConnector}
        rowsConfig={connectorsRowConfig}
        tableName={`Monthly Usage for ${connectorLabel} Connector`}
        updateFunc={async () => {
          const connectorValues = getIn(values, keyNameInValuesToUseForThisConnector);

          const payload = {
            id: clusterId,
            estimate_id: estimateId,
            estimate_version: estimate?.inputs?.estimate_version,
            connectors: [getConnectorInputsFromConnectorValue(connectorValues)],
          };

          const { error } = await updateCluster({
            estimateId,
            clusterId,
            payload,
          });

          if (error) {
            toastError(error);
          }
        }}
      />
    </>
  );
};
