// @flow
import React from 'react';
import { ShowLoaderIfAnyQueryIsPending } from '@presentational/ShowLoaderIfAnyQueryIsPending';
import { UserContextProvider } from '@src/contexts/UserContextProvider';
import { SPOrgContextProvider } from '@streaming-projects/orgs/contexts/SPOrgContextProvider';
import { SPOrgBreadcrumbs } from '@streaming-projects/orgs/SPOrgBreadcrumbs';
import { SPOrgTopLevelContainerForm } from '@streaming-projects/orgs/org-top-level/SPOrgTopLevelContainerForm';
import { SPMetaDataContextProvider } from '@streaming-projects/orgs/contexts/SPMetaDataContextProvider';

import { SPRateCardMetaContextProvider } from '../contexts/SPRateCardMetaContextProvider';

export const SPOrgTopLevelContainer = () => {
  return (
    <>
      <ShowLoaderIfAnyQueryIsPending />
      <UserContextProvider>
        <SPRateCardMetaContextProvider>
          <SPOrgContextProvider>
            <SPMetaDataContextProvider>
              <SPOrgBreadcrumbs />
              <SPOrgTopLevelContainerForm />
            </SPMetaDataContextProvider>
          </SPOrgContextProvider>
        </SPRateCardMetaContextProvider>
      </UserContextProvider>
    </>
  );
};
