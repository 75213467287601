// @flow
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';

import { SPMLDMutationsContext } from '../contexts/SPMLDMutationsContext';
import {
  FieldArrayGridConfigItemContext,
  FieldArrayGridDeleteButton,
} from '../../../../../../formik-utils/FieldArrayGrid';
import { getIfAllMetricsAreFrozen } from '../../../../../custom-discounts/utils';
import { DEFAULT_VALUE_FOR_DB_ROW_ID, ROW_ID } from '../../../../../../constants';

const getIfDimensionConfigDeleteButtonShouldBeDisabled = (readOnlyMode, rowValues, formik) => {
  if (readOnlyMode) {
    return true;
  }

  if (!rowValues?.isRowFrozen) {
    return false;
  }

  if (!getIfAllMetricsAreFrozen(formik.values)) {
    return true;
  }

  return !formik.isValid;
};

export const SPDimensionsConfigDeleteButtonContainer = () => {
  const orgData = useContext(SPOrgContext);
  const {
    metricDiscountDeleteMutation: [deleteMLDFunc],
  } = useContext(SPMLDMutationsContext);
  const { orgId } = useParams();
  const { rowValues, readOnlyMode } = useContext(FieldArrayGridConfigItemContext);
  const formik = useFormikContext();
  const payload = {
    ...rowValues,
    id: rowValues[ROW_ID] === DEFAULT_VALUE_FOR_DB_ROW_ID ? '-1' : rowValues[ROW_ID],
    org_version: orgData?.org?.version,
  };

  const deleteMLDFuncParams = {
    payload: payload,
    orgId: orgId,
    metricDiscountId: rowValues?.metric_discount_id ?? -1,
  };

  return (
    <FieldArrayGridDeleteButton
      deleteFunc={deleteMLDFunc}
      deleteFuncParams={deleteMLDFuncParams}
      disabled={getIfDimensionConfigDeleteButtonShouldBeDisabled(readOnlyMode, rowValues, formik)}
    />
  );
};
