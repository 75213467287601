// @flow
import React, { useContext } from 'react';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';
import { SecondaryTopBar } from '@components/breadcrumbs/SecondaryTopBar';
import { getWorkloadBreadcrumbs } from '@streaming-projects/utils';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';

import { useSPClusterWorkloadContext } from '../contexts/SPClusterWorkloadContextProvider';

export const SPClusterWorkloadBreadcrumbs = () => {
  const spOrgData = useContext(SPOrgContext);
  const spStreamingProjectData = useContext(SPStreamingProjectContext);

  const spClusterWorkloadData = useSPClusterWorkloadContext();
  const workloadName = spClusterWorkloadData?.name;
  return (
    <SecondaryTopBar
      sectionsToShow={getWorkloadBreadcrumbs(
        spOrgData,
        spStreamingProjectData,
        workloadName,
        'Cluster'
      )}
      showLastUpdatedMessage={false}
    />
  );
};
