// @flow
import React, { useContext } from 'react';
import { FieldArray, getIn, useFormikContext } from 'formik';
import { Grid } from 'semantic-ui-react';
import { StyledH5 } from '@presentational/headings/StyledH5';

import {
  CLUSTER_AVAILABLE_CONNECTORS_LIST_BACKEND_NAME,
  CLUSTER_CONNECTORS_FORM,
  CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG,
  CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG,
  DEFAULT_VALUE_FOR_CONNECTOR_NETWORKING_TYPE,
  DEFAULT_VALUE_FOR_DB_ROW_ID_STR,
  FREIGHT_CLUSTER_TYPE,
} from '../../../constants';
import { getMonthName } from '../cluster-input-table/utils';
import { PrimaryButton } from '../../presentational/buttons/PrimaryButton';
import { SelectField } from '../../../formik-utils/FormFields';
import { PaddedAndRaisedSegment } from '../../presentational/PaddedAndRaisedSegment';
import { getDropdownOptions } from '../../stream-governance/utils';
import {
  CLUSTER_DETAILS_CONFIG_MAP,
  CLUSTER_DETAILS_FORM_NAMES_TO_FIELDS_MAP,
} from '../../../configuration/cluster-details';
import { EstimateContext } from '../../../contexts/EstimateContext';
import { ClusterContext } from '../../../contexts/ClusterContext';
import { isAnythingEditedOnClusterPageBesidesTheGivenForm } from '../../views/utils';
import { isFormEdited } from '../cluster-details-inputs/utils';

import { ConnectorsMonthlyInputContainerWrapper } from './ConnectorsMonthlyInputContainerWrapper';

export const connectorsRowConfig = [
  {
    backendName: 'is_enabled',
    displayName: 'Active',
    isBooleanField: true,
    defaultValue: true,
  },
  {
    backendName: 'task_count',
    displayName: 'Number of Tasks',
    defaultValue: 0,
  },
  {
    backendName: 'throughput_average_mbps',
    displayName: 'Average Throughput (MBps)',
    defaultValue: 0,
  },
];

const getConnectorWithDefaults = (
  connectorName,
  connectorNameToRecordsMap,
  totalNumberOfMonths,
  kafkaMonthlyInputs
) => {
  const toRet = {
    connectorName: connectorName,
    connectorNetworkingType: DEFAULT_VALUE_FOR_CONNECTOR_NETWORKING_TYPE,
    connectorLabel: connectorNameToRecordsMap.get(connectorName)[0].connector_label,
    connectorId: DEFAULT_VALUE_FOR_DB_ROW_ID_STR,
  };

  const months = {};

  for (let month = 1; month <= totalNumberOfMonths; month++) {
    const monthName = getMonthName(month);
    const currMonthObj = { name: monthName, month: month };
    for (const row of connectorsRowConfig) {
      currMonthObj[row.backendName] = row.defaultValue;
      currMonthObj.is_enabled = kafkaMonthlyInputs[monthName].is_enabled;
    }
    months[monthName] = currMonthObj;
  }
  toRet.months = months;
  return toRet;
};

export const AddKafkaConnectorComponent = ({
  disabled,
  keyNameInValues,
  connectorOptionsConfig,
  connectorOptions,
  values,
  initialValues,
  connectorNameToRecordsMap,
  numberOfMonths,
  MonthlyInputContainerWrapper = ConnectorsMonthlyInputContainerWrapper,
}) => (
  <PaddedAndRaisedSegment disabled={disabled}>
    <FieldArray
      name={`${keyNameInValues}.connectors`}
      render={(arrayHelpers) => {
        return (
          <>
            <Grid>
              <Grid.Row columns={5}>
                <Grid.Column verticalAlign="bottom">
                  <SelectField
                    disabled={disabled}
                    fieldDisplayName={connectorOptionsConfig.displayName}
                    fieldName={connectorOptionsConfig.backendFieldName}
                    options={connectorOptions}
                    search={true}
                  />
                </Grid.Column>
                <Grid.Column verticalAlign="bottom">
                  <PrimaryButton
                    disabled={
                      !getIn(values, CLUSTER_AVAILABLE_CONNECTORS_LIST_BACKEND_NAME) ||
                      disabled ||
                      isFormEdited(
                        values,
                        initialValues,
                        CLUSTER_CONNECTORS_FORM,
                        CLUSTER_DETAILS_FORM_NAMES_TO_FIELDS_MAP
                      )
                    }
                    onClick={() =>
                      arrayHelpers.push(
                        getConnectorWithDefaults(
                          getIn(values, CLUSTER_AVAILABLE_CONNECTORS_LIST_BACKEND_NAME),
                          connectorNameToRecordsMap,
                          numberOfMonths,
                          getIn(values, [CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG, 'months'])
                        )
                      )
                    }
                    size="small"
                    text="Add Connector"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <MonthlyInputContainerWrapper arrayHelpers={arrayHelpers} disabled={disabled} />
          </>
        );
      }}
    />
  </PaddedAndRaisedSegment>
);

export const ConnectorsTopLevelContainer = () => {
  const { values, initialValues } = useFormikContext();

  const cluster = useContext(ClusterContext);
  const estimate = useContext(EstimateContext);

  const isFreightCluster = cluster?.inputs?.cluster_type === FREIGHT_CLUSTER_TYPE;

  if (isFreightCluster) {
    return (
      <PaddedAndRaisedSegment>
        <StyledH5 text="Freight does not support connectors!" />
      </PaddedAndRaisedSegment>
    );
  }

  const connectorsList = cluster?.cluster_configs?.full_connectors_list;

  const numberOfMonths = estimate.deal_duration.deal_duration_months;
  const keyNameInValues = CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG;

  const connectorOptionsConfig = CLUSTER_DETAILS_CONFIG_MAP.get(
    CLUSTER_AVAILABLE_CONNECTORS_LIST_BACKEND_NAME
  );

  const fullConnectorsList = connectorsList.connector_types;
  const connectorNameToRecordsMap = cluster?.cluster_configs?.connectorNameToRecordsMap;

  const connectorOptions = getDropdownOptions(
    fullConnectorsList,
    values,
    connectorOptionsConfig,
    false,
    false,
    false,
    true
  );

  const disabled = isAnythingEditedOnClusterPageBesidesTheGivenForm(
    values,
    initialValues,
    CLUSTER_CONNECTORS_FORM
  );

  return (
    <AddKafkaConnectorComponent
      connectorNameToRecordsMap={connectorNameToRecordsMap}
      connectorOptions={connectorOptions}
      connectorOptionsConfig={connectorOptionsConfig}
      disabled={disabled}
      initialValues={initialValues}
      keyNameInValues={keyNameInValues}
      numberOfMonths={numberOfMonths}
      values={values}
    />
  );
};

// todo:: fix the issue with Accordions index when adding and deleting connectors.
// Replicate the existing behaviour
