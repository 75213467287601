// @flow
import { useHistory, useParams } from 'react-router-dom';
import React from 'react';
import { getLinkForOrganizationsPage } from '@streaming-projects/links';
import { getDeleteWithWarningHeaderAndBody } from '@src/common-utils/utils';
import { useDeleteClusterLinkingResourceMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { toastError, toastSuccess } from '@presentational/notifications/utils';
import { ConfirmModal } from '@presentational/modals/ConfirmModal';

export const SPClusterLinkingResourceDeleteWarningModal = ({
  isOpen,
  onClose,
  clusterLinkName,
  clusterLinkId,
  version = null,
}) => {
  const [deleteResource] = useDeleteClusterLinkingResourceMutation();
  const { orgId } = useParams();
  const { push } = useHistory();
  const { header, headerBody } = getDeleteWithWarningHeaderAndBody('CL Resource', clusterLinkName);

  const onClickHandlerForOK = async () => {
    const { error } = await deleteResource({
      clusterLinkingResourceId: clusterLinkId,
      orgId: orgId,
      payload: {
        version: version,
      },
    });
    if (error) {
      toastError(error);
    } else {
      toastSuccess('Cluster Linking Resource delete was successful!');
      push(getLinkForOrganizationsPage(orgId));
    }
  };

  return (
    <ConfirmModal
      body={headerBody}
      header={header}
      isOpen={isOpen}
      onClickHandlerForCancel={onClose}
      onClickHandlerForOK={onClickHandlerForOK}
    />
  );
};
