// @flow
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { DeleteButton } from '@presentational/buttons/DeleteButton';
import { SPClusterLinkingWorkloadDeleteWarningModal } from '@streaming-projects/workloads/cluster-linking-workloads/delete/SPClusterLinkingWorkloadDeleteWarningModal';
import { ClusterLinkingWorkloadContext } from '@streaming-projects/workloads/cluster-linking-workloads/contexts/ClusterLinkingWorkloadContext';

export const SPClusterLinkingWorkloadDeleteContainer = () => {
  const { clusterLinkingWorkloadId } = useParams();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const workloadData = useContext(ClusterLinkingWorkloadContext);
  const spStreamingProjectData = useContext(SPStreamingProjectContext);

  return (
    <>
      <DeleteButton
        onClick={() => {
          setDeleteModalOpen(true);
        }}
        size="large"
      />
      <SPClusterLinkingWorkloadDeleteWarningModal
        clusterLinkingWorkloadId={clusterLinkingWorkloadId}
        clusterLinkingWorkloadName={workloadData?.name} // todo::SP Name is not sent
        clusterLinkingWorkloadResourceId={workloadData?.cluster_linking_resource_id}
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        spVersion={spStreamingProjectData?.sp?.version}
      />
    </>
  );
};
