// @flow
import React, { useContext, useState } from 'react';
import {
  CLUSTER_LINKING_RESOURCES_GRID_FIELDNAMES,
  CLUSTER_LINKING_RESOURCES_GRID_HEADERS,
} from '@streaming-projects/orgs/enums';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { PrimaryButton } from '@presentational/buttons/PrimaryButton';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';
import { SPClusterLinkingConfigurationAddEditModal } from '@streaming-projects/resource-definitions/cluster-linking-resource-definitions/SPClusterLinkingConfigurationAddEditModal';
import { SPClusterLinkingDeleteRenderer } from '@streaming-projects/resource-definitions/cluster-linking-resource-definitions/SPClusterLinkingDeleteRenderer';
import { SPClusterLinkingEditRenderer } from '@streaming-projects/resource-definitions/cluster-linking-resource-definitions/SPClusterLinkingEditRenderer';

import { DataGrid } from '../../../views/grid-utils/DataGrid';

export const SPClusterLinkingResourceConfigurationsSummaryContainer = () => {
  const [isAddEditModalOpen, setAddEditModalOpen] = useState(false);
  const inputDataSource = useContext(SPOrgContext)?.resources?.cluster_linking_resources ?? [];
  const areAnyClusterLinksConfigured = inputDataSource?.length > 0;

  const columnDefs = [
    {
      field: CLUSTER_LINKING_RESOURCES_GRID_HEADERS.ID,
      backEndFieldName: CLUSTER_LINKING_RESOURCES_GRID_FIELDNAMES.ID,
      hide: true,
    },
    {
      field: CLUSTER_LINKING_RESOURCES_GRID_HEADERS.NAME,
      backEndFieldName: CLUSTER_LINKING_RESOURCES_GRID_FIELDNAMES.NAME,
    },
    {
      field: CLUSTER_LINKING_RESOURCES_GRID_HEADERS.SOURCE_CLUSTER_NAME,
      backEndFieldName: CLUSTER_LINKING_RESOURCES_GRID_FIELDNAMES.SOURCE_CLUSTER_NAME,
    },
    {
      field: CLUSTER_LINKING_RESOURCES_GRID_HEADERS.SOURCE_CLUSTER_ID,
      backEndFieldName: CLUSTER_LINKING_RESOURCES_GRID_FIELDNAMES.SOURCE_CLUSTER_ID,
      hide: true,
    },
    {
      field: CLUSTER_LINKING_RESOURCES_GRID_HEADERS.DESTINATION_CLUSTER_NAME,
      backEndFieldName: CLUSTER_LINKING_RESOURCES_GRID_FIELDNAMES.DESTINATION_CLUSTER_NAME,
    },
    {
      field: CLUSTER_LINKING_RESOURCES_GRID_HEADERS.DESTINATION_CLUSTER_ID,
      backEndFieldName: CLUSTER_LINKING_RESOURCES_GRID_FIELDNAMES.DESTINATION_CLUSTER_ID,
      hide: true,
    },
    {
      field: CLUSTER_LINKING_RESOURCES_GRID_HEADERS.VERSION,
      backEndFieldName: CLUSTER_LINKING_RESOURCES_GRID_FIELDNAMES.VERSION,
      hide: true,
    },
    {
      field: '',
      filter: false,
      cellRenderer: SPClusterLinkingEditRenderer,
      suppressSizeToFit: true,
      maxWidth: 60,
      sortable: false,
    },
    {
      field: '',
      filter: false,
      cellRenderer: SPClusterLinkingDeleteRenderer,
      suppressSizeToFit: true,
      maxWidth: 60,
      sortable: false,
    },
  ];

  return (
    <PaddedAndRaisedSegment>
      <PrimaryButton
        onClick={() => {
          setAddEditModalOpen(true);
        }}
        size="mini"
        text="Add Cluster Linking Shared Resource Configuration"
      />

      <SPClusterLinkingConfigurationAddEditModal
        isOpen={isAddEditModalOpen}
        setOpen={setAddEditModalOpen}
      />

      {areAnyClusterLinksConfigured && (
        <DataGrid
          columnDefs={columnDefs}
          displayResizingColsOption={true}
          downloadAllCols={false}
          inputDataSource={inputDataSource}
          label="Cluster Linking Resource Configuration Summary"
          sizeColumnsToFitInitially={true}
          tooltipShowDelay={0}
        />
      )}
    </PaddedAndRaisedSegment>
  );
};
