// @flow

import React, { useContext, useState } from 'react';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { PrimaryButton } from '@presentational/buttons/PrimaryButton';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { DataGrid } from '@components/views/grid-utils/DataGrid';
import { CLUSTER_RESOURCES_GRID_HEADERS } from '@streaming-projects/orgs/enums';
import { ENABLED_DISABLED_FILTER_PARAMS } from '@components/views/grid-utils/constants';
import { BACKEND_FIELDNAMES, FRONT_END_DISPLAY_NAMES } from '@streaming-projects/enums';
import { SPMetaDataContext } from '@streaming-projects/orgs/contexts/SPMetaDataContext';
import { ADD_NEW_WORKLOAD_MESSAGE } from '@streaming-projects/constants';
import { getSPClusterResourceConfig } from '@streaming-projects/orgs/config';

import { SPClusterWorkloadAddModal } from './SPClusterWorkloadAddModal';
import { SPClusterWorkloadEnabledCellRenderer } from './SPClusterWorkloadEnabledCellRenderer';
import { SPClusterWorkloadDeleteRenderer } from './SPClusterWorkloadDeleteRenderer';
import { SPClusterWorkloadNameRenderer } from './SPClusterWorkloadNameRenderer';

export const SPClustersSPPageWorkloadSummaryContainer = () => {
  const { providerRegionsMap, resourceConfigurationsNameLabelsMap, providerNameLabelMap } =
    useContext(SPMetaDataContext).metaData;

  const [isClusterWorkloadAddModalOpen, setClusterWorkloadAddModalOpen] = useState(false);
  const spStreamingProjectData = useContext(SPStreamingProjectContext);

  const inputDataSource = spStreamingProjectData?.use_case_summaries?.clusters_summaries ?? [];
  const areAnyClusterWorkloadsConfigured = inputDataSource.length > 0;

  const spCommonClusterColumnsConfig = getSPClusterResourceConfig({
    providerNameLabelMap,
    resourceConfigurationsNameLabelsMap,
    providerRegionsMap,
  });
  // todo::SP Same for all other DSP elems
  const columnDefs = [
    {
      field: FRONT_END_DISPLAY_NAMES.WORKLOAD_ID,
      backEndFieldName: BACKEND_FIELDNAMES.WORKLOAD_ID,
      hide: true,
    },
    {
      field: FRONT_END_DISPLAY_NAMES.WORKLOAD_NAME,
      backEndFieldName: BACKEND_FIELDNAMES.WORKLOAD_NAME,
      cellRenderer: SPClusterWorkloadNameRenderer,
      headerTooltip: FRONT_END_DISPLAY_NAMES.WORKLOAD_NAME,
    },
    {
      field: FRONT_END_DISPLAY_NAMES.RESOURCE_ID,
      backEndFieldName: BACKEND_FIELDNAMES.RESOURCE_ID,
      hide: true,
    },
    {
      field: CLUSTER_RESOURCES_GRID_HEADERS.NAME,
      backEndFieldName: BACKEND_FIELDNAMES.RESOURCE_NAME,
      headerTooltip: CLUSTER_RESOURCES_GRID_HEADERS.NAME,
    },
    {
      field: FRONT_END_DISPLAY_NAMES.IS_ENABLED,
      filter: true,
      filterParams: ENABLED_DISABLED_FILTER_PARAMS,
      cellRenderer: SPClusterWorkloadEnabledCellRenderer,
      backEndFieldName: BACKEND_FIELDNAMES.IS_ENABLED,
      headerTooltip: FRONT_END_DISPLAY_NAMES.IS_ENABLED,
    },
    ...spCommonClusterColumnsConfig,
    {
      field: '',
      filter: false,
      cellRenderer: SPClusterWorkloadDeleteRenderer,
      suppressSizeToFit: true,
      maxWidth: 60,
      sortable: false,
    },
  ];

  return (
    <PaddedAndRaisedSegment>
      <PrimaryButton
        onClick={() => {
          setClusterWorkloadAddModalOpen(true);
        }}
        size="mini"
        text={ADD_NEW_WORKLOAD_MESSAGE}
      />

      <SPClusterWorkloadAddModal
        isOpen={isClusterWorkloadAddModalOpen}
        setOpen={setClusterWorkloadAddModalOpen}
      />

      {areAnyClusterWorkloadsConfigured && (
        <DataGrid
          columnDefs={columnDefs}
          downloadAllCols={false}
          inputDataSource={inputDataSource}
          label="Cluster Workloads Summary"
          sizeColumnsToFitInitially={true}
          tooltipShowDelay={0}
        />
      )}
    </PaddedAndRaisedSegment>
  );
};
