// @flow
import React, { useContext } from 'react';
import { getIn, useFormikContext } from 'formik';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { ClusterInputTable } from '@components/cluster/cluster-input-table/ClusterInputTable';
import { useParams } from 'react-router-dom';
import { useUpdateClusterWorkloadMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { isExpandedInputNeeded } from '@streaming-projects/utils';
import { CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG, DEDICATED_CLUSTER_TYPE } from '@src/constants';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';
import { toastError } from '@presentational/notifications/utils';
import { getOtherTabsInputsBasedOnWhetherMonthsAreDisabledOrNot } from '@components/cluster/cluster-monthly-inputs/KafkaUsageContainer';

import { useSPClusterWorkloadContext } from '../contexts/SPClusterWorkloadContextProvider';

export const SPKafkaUsageContainer = ({ disabled }) => {
  const { values, initialValues } = useFormikContext();
  const { orgId, spId, clusterWorkloadId } = useParams();
  const [updateClusterWorkload] = useUpdateClusterWorkloadMutation();
  const spStreamingProjectData = useContext(SPStreamingProjectContext);
  const spClusterWorkloadData = useSPClusterWorkloadContext();
  const rateCard = useContext(SPOrgContext).org.rate_card;

  const clusterType = spClusterWorkloadData.cluster_resource_details.cluster_type;
  const rowsConfig = [
    {
      backendName: 'is_enabled',
      displayName: 'Active',
      isBooleanField: true,
    },
    {
      backendName: 'write_throughput_peak_mbps',
      displayName: 'Peak Write Throughput (MBps)',
      showOrHideFunc: () => clusterType === DEDICATED_CLUSTER_TYPE,
    },
    {
      backendName: 'read_throughput_peak_mbps',
      displayName: 'Peak Read Throughput (MBps)',
      showOrHideFunc: () => clusterType === DEDICATED_CLUSTER_TYPE,
    },
    {
      backendName: 'write_throughput_avg_mbps',
      displayName: 'Average Write Throughput (MBps)',
    },
    {
      backendName: 'read_throughput_avg_mbps',
      displayName: 'Average Read Throughput (MBps)',
    },
    {
      backendName: 'connected_client_count',
      displayName: '# of Simultaneously Connected Clients',
      showOrHideFunc: () => isExpandedInputNeeded(clusterType, rateCard),
    },
    {
      backendName: 'client_connection_attempts_per_sec',
      displayName: '# of Connection Attempts Per Second',
      showOrHideFunc: () => isExpandedInputNeeded(clusterType, rateCard),
    },
    {
      backendName: 'client_requests_per_sec',
      displayName: '# of Requests Per Second',
      showOrHideFunc: () => isExpandedInputNeeded(clusterType, rateCard),
    },
    {
      backendName: 'partition_count',
      displayName: '# of Partitions',
    },
  ];

  return (
    <PaddedAndRaisedSegment disabled={disabled}>
      <ClusterInputTable
        addCalendarMonthRowToDownload={true}
        inferTotalNumberOfMonthsFromDataset={true}
        keyNameInValues={CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG}
        rowsConfig={rowsConfig}
        showYearNumberInMonthlyHeader={false}
        tableName="Kafka Monthly Usage"
        updateFunc={async () => {
          const { name } = values;
          const valuesToSendToBackend = getIn(values, CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG);
          const kafkaInputs = Object.values(valuesToSendToBackend.months);
          const throughput = {
            id: spClusterWorkloadData.throughput.id,
            inputs: kafkaInputs,
          };
          const otherComponentsToSendToBackEnd =
            getOtherTabsInputsBasedOnWhetherMonthsAreDisabledOrNot(
              valuesToSendToBackend.months,
              initialValues,
              values
            );
          if (otherComponentsToSendToBackEnd.ksqldb) {
            otherComponentsToSendToBackEnd.ksqldb.id = spClusterWorkloadData.ksqldb.id;
          }
          if (otherComponentsToSendToBackEnd.connectors && spClusterWorkloadData.connectors) {
            otherComponentsToSendToBackEnd.connectors.id = spClusterWorkloadData.connectors.id;
          }
          const payload = {
            id: clusterWorkloadId,
            sp_id: spId,
            cluster_resource_id: spClusterWorkloadData.cluster_resource_id,
            sp_version: spStreamingProjectData.sp.version,
            // todo::SP check if name is still needed for clusterWokload update api
            name,
            throughput,
            ...otherComponentsToSendToBackEnd,
          };

          const { error } = await updateClusterWorkload({
            orgId,
            spId,
            clusterWorkloadId,
            payload,
          });
          if (error) {
            toastError(error);
          }
        }}
      />
    </PaddedAndRaisedSegment>
  );
};
