// @flow
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import React, { useContext } from 'react';
import { Spacer } from '@presentational/spacing/Spacer';
import { GenericSaveResetButtons } from '@components/GenericSaveResetButtons';
import { Grid } from 'semantic-ui-react';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';
import { SP_DEFAULT_COMMIT_DETAILS_CONFIG_MAP } from '@streaming-projects/default-commit-details/config';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';
import { useUpdateSPDefaultCommitDetailsMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { useSPDefaultCommitDetailsContext } from '@streaming-projects/default-commit-details/context/SPDefaultCommitDetailsContext';

import SPDefaultCommitValueContainer from './fields/SPDefaultCommitValueContainer';

const SPDefaultCommitValueDetails = () => {
  const spOrgContext = useContext(SPOrgContext);
  const [updateSPDefaultCommitDetails] = useUpdateSPDefaultCommitDetailsMutation();
  const spDefaultCommitDetails = useSPDefaultCommitDetailsContext();

  const handleSave = async (payload) => {
    return await updateSPDefaultCommitDetails({
      orgId: spOrgContext.org.id,
      payload: {
        [BACKEND_FIELDNAMES.COMMIT_AMOUNT]: Number(payload[BACKEND_FIELDNAMES.COMMIT_AMOUNT]),
        Version: spDefaultCommitDetails?.version,
      },
    });
  };

  return (
    <PaddedAndRaisedSegment>
      <Grid>
        <Grid.Column width={4}>
          <SPDefaultCommitValueContainer />
        </Grid.Column>
      </Grid>

      <Spacer y={40} />

      <GenericSaveResetButtons
        fieldsConfig={[SP_DEFAULT_COMMIT_DETAILS_CONFIG_MAP.get(BACKEND_FIELDNAMES.COMMIT_AMOUNT)]}
        onSaveHandler={handleSave}
      />
    </PaddedAndRaisedSegment>
  );
};

export default SPDefaultCommitValueDetails;
