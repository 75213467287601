// @flow
import React from 'react';
import { FileField } from '@src/formik-utils/FormFields';
import { STREAMING_PROJECTS_BASELINE_DETAILS_CONFIG_MAP } from '@streaming-projects/baseline/config';

export const SPInputFileContainer = ({ disableOnFormErrors = null }) => {
  const { backendFieldName, displayName, tooltip } =
    STREAMING_PROJECTS_BASELINE_DETAILS_CONFIG_MAP.get('input_file');

  return (
    <FileField
      accept=".csv"
      disableOnFormErrors={disableOnFormErrors}
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      fluid={true}
      tooltip={tooltip}
    />
  );
};
