// @flow
import React, { useContext, useState } from 'react';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';
import { BASELINE_SP_IDENTIFIER_NAME } from '@streaming-projects/enums';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { PrimaryButton } from '@presentational/buttons/PrimaryButton';
import { StyledPopup } from '@src/common-utils/styledComponents';
import { DataGrid } from '@components/views/grid-utils/DataGrid';
import { SPBaselineAddModal } from '@streaming-projects/baseline/SPBaselineAddModal';
import { STREAMING_PROJECT_SUMMARY_GRID_CONFIG } from '@streaming-projects/orgs/config';

export const BaselineSummaryContainer = () => {
  const [isBaselineImportModalOpen, setBaselineImportModalOpen] = useState(false);

  const columnDefs = STREAMING_PROJECT_SUMMARY_GRID_CONFIG;

  const spORGData = useContext(SPOrgContext);

  let inputDataSource = spORGData?.sp_details ?? [];
  inputDataSource = inputDataSource.filter((x) => x.name === BASELINE_SP_IDENTIFIER_NAME);

  const isBaselineSPAlreadyConfigured = inputDataSource.length > 0;

  return (
    <PaddedAndRaisedSegment>
      {!isBaselineSPAlreadyConfigured && (
        <PrimaryButton
          onClick={() => {
            setBaselineImportModalOpen(true);
          }}
          size="mini"
          text="Import Baseline"
        />
      )}

      {isBaselineSPAlreadyConfigured && (
        <StyledPopup
          content="First delete the existing Baseline SP and all the BQ_ resources!"
          flowing={true}
          hoverable={true}
          offset={[0, 0]}
          on={['hover']}
          positionFixed={true}
          trigger={
            <span>
              <PrimaryButton
                disabled={true}
                onClick={() => {
                  setBaselineImportModalOpen(true);
                }}
                size="mini"
                text="Import Baseline"
              />
            </span>
          }
        />
      )}
      <SPBaselineAddModal
        isBaselineSPAlreadyConfigured={isBaselineSPAlreadyConfigured}
        isOpen={isBaselineImportModalOpen}
        setOpen={setBaselineImportModalOpen}
      />
      {isBaselineSPAlreadyConfigured && (
        <DataGrid
          columnDefs={columnDefs}
          inputDataSource={inputDataSource}
          label="Baseline SP Summary"
          sizeColumnsToFitInitially={true}
        />
      )}
    </PaddedAndRaisedSegment>
  );
};
