// @flow
import React, { useContext } from 'react';
import { Grid } from 'semantic-ui-react';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { StyledHeader } from '@src/common-utils/styledComponents';
import { Spacer } from '@presentational/spacing/Spacer';
import { ClusterLinkingWorkloadContext } from '@streaming-projects/workloads/cluster-linking-workloads/contexts/ClusterLinkingWorkloadContext';

export const SPClusterLinkingWorkloadResourceDetailsContainer = () => {
  const workloadData = useContext(ClusterLinkingWorkloadContext);
  const workloadResourceDetails = workloadData.cluster_linking_resource_details;

  return (
    <>
      <PaddedAndRaisedSegment>
        <Grid columns={3} divided={true}>
          <Grid.Row>
            <Grid.Column>
              <StyledHeader> Cluster Link Name </StyledHeader>
              <Spacer y={20} />
              {workloadResourceDetails.name}
            </Grid.Column>
            <Grid.Column>
              <StyledHeader> Source Cluster Name </StyledHeader>
              <Spacer y={20} />
              {workloadResourceDetails.source_cluster_name}
            </Grid.Column>
            <Grid.Column>
              <StyledHeader> Destination Cluster Name </StyledHeader>
              <Spacer y={20} />
              {workloadResourceDetails.destination_cluster_name}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </PaddedAndRaisedSegment>
    </>
  );
};
