// @flow
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { DataFetcherContainer } from '@presentational/DataFetcherContainer';
import { useGetSPStreamingProjectMonthlyPricingViewQuery } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { shouldSkipDataFetching } from '@src/common-utils/utils';
import { MonthlySpendSummaryContainer } from '@components/common/MonthlySpendSummaryContainer';
import { getMonthsAndYearsTransformedMonthlySpendSummary } from '@streaming-projects/utils';
import { IS_PINNING_TOTAL_ROW_ENABLED } from '@streaming-projects/constants';

export const SPStreamingProjectPricingMonthlySpendSummaryContainerWrapper = ({
  addCostMargin = false,
  disabled = false,
}) => {
  const params = useParams();
  return (
    <DataFetcherContainer
      dataFetchingArgs={useMemo(
        () => [
          { ...params },
          {
            skip: shouldSkipDataFetching(params),
          },
        ],
        [params]
      )}
      dataFetchingFunction={useCallback(useGetSPStreamingProjectMonthlyPricingViewQuery, [])}
    >
      {(data) => {
        const monthlySpendSummary = getMonthsAndYearsTransformedMonthlySpendSummary(
          data?.sp_cloud_monthly_spend
        );

        return (
          <MonthlySpendSummaryContainer
            addCostMargin={addCostMargin}
            columnNamesToPin={IS_PINNING_TOTAL_ROW_ENABLED ? ['Total'] : []}
            disabled={disabled}
            monthlySpendSummary={monthlySpendSummary}
          />
        );
      }}
    </DataFetcherContainer>
  );
};
