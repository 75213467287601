// @flow
import React from 'react';
import { useFormikContext } from 'formik';
import { STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES } from '@streaming-projects/orgs/enums';
import { SG_PARAMS_HACK } from '@src/constants';
import { getDropdownOptions } from '@components/stream-governance/utils';
import { SelectField } from '@src/formik-utils/FormFields';
import { STREAM_GOVERNANCE_RESOURCE_INPUTS_DETAILS_CONFIG_MAP } from '@streaming-projects/resource-definitions/stream-governance-resource-definitions/stream-governance-resource-inputs-config';

export const SGResourceRegionNameContainer = ({
  disabled,
  disableOnFormErrors = null,
  fluid = null,
}) => {
  const { values } = useFormikContext();
  const regionNameConfig = STREAM_GOVERNANCE_RESOURCE_INPUTS_DETAILS_CONFIG_MAP.get(
    STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.REGION
  );

  // todo::Fix This!!!
  const dataUniverse = SG_PARAMS_HACK?.sg_configs?.sg_packages ?? [];

  const regionNameOptions = getDropdownOptions(
    dataUniverse,
    values,
    regionNameConfig,
    false,
    true,
    false,
    false,
    [],
    true
  );

  return (
    <SelectField
      disableOnFormErrors={disableOnFormErrors}
      disabled={disabled}
      fieldDisplayName={regionNameConfig.displayName}
      fieldName={regionNameConfig.backendFieldName}
      fluid={fluid}
      options={regionNameOptions}
      placeholder={regionNameConfig.displayName}
    />
  );
};
