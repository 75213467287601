// @flow

import {
  useGetSPDefaultCommitPricingStatusViewQuery,
  useSubmitRecalcRequestForDefaultCommitMutation,
} from '@streaming-projects/service-definitions/streamingProjectsApi';
import React, { useCallback } from 'react';
import SPAsyncCalcWrapper from '@components/common/pricing-summary-wrapper/SPAsyncCalcWrapper';
import { IS_COST_AND_MARGIN_COLUMNS_ENABLED } from '@streaming-projects/constants';
import useIsAdmin from '@streaming-projects/useIsAdmin';

import SPDefaultCommitMonthlySummaryContainerWrapper from './SPDefaultCommitMonthlySummaryContainerWrapper';

const SPDefaultCommitSummaryContainer = () => {
  const isUserAdmin = useIsAdmin();
  const pricingStatusFetchingFunction = useCallback(
    useGetSPDefaultCommitPricingStatusViewQuery,
    []
  );

  return (
    <SPAsyncCalcWrapper
      pricingStatusFetchingFunction={pricingStatusFetchingFunction}
      useRecalcRequestMutation={useSubmitRecalcRequestForDefaultCommitMutation}
    >
      <SPDefaultCommitMonthlySummaryContainerWrapper
        addCostMargin={IS_COST_AND_MARGIN_COLUMNS_ENABLED && isUserAdmin}
        disabled={false}
      />
    </SPAsyncCalcWrapper>
  );
};

export default SPDefaultCommitSummaryContainer;
