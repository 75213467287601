// @flow
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getFormikFormInputsFromColConfigAndInputSource } from '@src/common-utils/utils';
import { Form } from '@src/formik-utils/formikSUIWrapper';
import { ValidateFormOnMount } from '@src/formik-utils/ValidateFormOnMount';
import FormListener from '@src/formik-utils/FormListener';
import { PromptIfFormHasUnSavedChanges } from '@presentational/PromptIfFormHasUnSavedChanges';
import { Spacer } from '@presentational/spacing/Spacer';
import { StyledContainer } from '@src/common-utils/styledComponents';
import { Formik } from 'formik';
import { CLUSTER_WORKLOADS_INPUTS_FIELDNAMES } from '@streaming-projects/orgs/enums';
import { SPMetaDataContext } from '@streaming-projects/orgs/contexts/SPMetaDataContext';
import {
  CLUSTER_AVAILABLE_CONNECTORS_LIST_BACKEND_NAME,
  CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG,
  CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG,
  CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG,
  DEDICATED_CLUSTER_TYPE,
} from '@src/constants';
import { isExpandedInputNeeded } from '@streaming-projects/utils';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';

import { SPClusterWorkloadEnabledContainer } from './enable/SPClusterWorkloadEnabledContainer';
import { SPClusterWorkloadDeleteContainer } from './delete/SPClusterWorkloadDeleteContainer';
import { SPClusterWorkloadAccordionsContainer } from './SPClusterWorkloadAccordionsContainer';
import { useSPClusterWorkloadContext } from './contexts/SPClusterWorkloadContextProvider';
import { CLUSTER_WORKLOAD_DETAILS_CONFIG } from './config';
import {
  connectorsMonthlyInputsValidationFunc,
  getMonthlyInputsValidationForType,
} from './monthly-inputs/validation';

const getMaxLimitsObjectForKafka = (kafkaLimits, clusterType, rateCard) => {
  const maxLimitsObjectForKafka = {
    read_throughput_avg_mbps: kafkaLimits.read_throughput_threshold,
    write_throughput_avg_mbps: kafkaLimits.write_throughput_threshold,
    partition_count: kafkaLimits.partitions_threshold,
  };

  // todo::SP remove duplication between this file and the functions defined in SPKafkaUsageContainer.jsx
  if (clusterType === DEDICATED_CLUSTER_TYPE) {
    maxLimitsObjectForKafka.read_throughput_peak_mbps = kafkaLimits.read_throughput_threshold;
    maxLimitsObjectForKafka.write_throughput_peak_mbps = kafkaLimits.write_throughput_threshold;
  }

  if (isExpandedInputNeeded(clusterType, rateCard)) {
    maxLimitsObjectForKafka.connected_client_count = kafkaLimits.connected_clients_threshold;
    maxLimitsObjectForKafka.client_requests_per_sec = kafkaLimits.requests_per_sec_threshold;
    maxLimitsObjectForKafka.client_connection_attempts_per_sec =
      kafkaLimits.connection_attempts_threshold;
  }
  return maxLimitsObjectForKafka;
};

export const SPClusterWorkloadTopLevelContainerForm = () => {
  const { metaData } = useContext(SPMetaDataContext);
  const spClusterWorkloadData = useSPClusterWorkloadContext();
  const { clusterWorkloadId } = useParams();
  const rateCard = useContext(SPOrgContext).org.rate_card;

  const { cluster_type: clusterType } = spClusterWorkloadData.cluster_resource_details;

  const kafkaLimits = metaData.cluster_configs.cku_threshold_configs.find(
    (config) => config.cluster_type === clusterType
  );
  const ksqlDBlimits = metaData.cluster_configs.ksql_threshold_configs;
  const connectorLimits = metaData.cluster_configs.connector_threshold_configs;

  const { retention, is_follower_fetch_enabled: isFollowerFetchEnabled } =
    spClusterWorkloadData.cluster_details;

  const clusterInputsSource = {
    name: spClusterWorkloadData.name,
    is_enabled: spClusterWorkloadData.is_enabled,
    [CLUSTER_WORKLOADS_INPUTS_FIELDNAMES.FOLLOWER_FETCH]: isFollowerFetchEnabled,
    [CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG]: spClusterWorkloadData.throughput,
    [CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG]:
      spClusterWorkloadData[CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG],
    [CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG]: {
      connectors: spClusterWorkloadData[CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG],
    },
    [CLUSTER_WORKLOADS_INPUTS_FIELDNAMES.CLUSTER_RETENTION_INFINITE]:
      !retention.is_finite_retention,
    [CLUSTER_WORKLOADS_INPUTS_FIELDNAMES.CLUSTER_RETENTION]:
      retention[CLUSTER_WORKLOADS_INPUTS_FIELDNAMES.CLUSTER_RETENTION],
    // todo::SP Remove the hardcoding of ActiveMQSource
    [CLUSTER_AVAILABLE_CONNECTORS_LIST_BACKEND_NAME]: 'activemqsource',
    [CLUSTER_WORKLOADS_INPUTS_FIELDNAMES.EXISTING_STORAGE]:
      spClusterWorkloadData.metadata.existing_storage,
    [CLUSTER_WORKLOADS_INPUTS_FIELDNAMES.CKU]: spClusterWorkloadData.metadata.cku,
  };

  const maxLimitsObjectForKafka = getMaxLimitsObjectForKafka(kafkaLimits, clusterType, rateCard);
  const maxLimitsObject = {
    [CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG]: maxLimitsObjectForKafka,
    [CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG]: {
      csu_count: ksqlDBlimits.ksql_count_threshold,
    },
    [CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG]: {
      task_count: connectorLimits.connector_count_threshold,
      throughput_average_mbps: connectorLimits.connector_throughput_threshold,
    },
  };
  const numberOfMonths = spClusterWorkloadData.throughput.inputs.length;
  // Append the Dynamic Validations Required
  CLUSTER_WORKLOAD_DETAILS_CONFIG.find(
    (x) => x.backendFieldName === CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG
  ).validation = getMonthlyInputsValidationForType(
    numberOfMonths,
    maxLimitsObject,
    CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG
  );

  CLUSTER_WORKLOAD_DETAILS_CONFIG.find(
    (x) => x.backendFieldName === CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG
  ).validation = getMonthlyInputsValidationForType(
    numberOfMonths,
    maxLimitsObject,
    CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG
  );

  CLUSTER_WORKLOAD_DETAILS_CONFIG.find(
    (x) => x.backendFieldName === CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG
  ).validation = connectorsMonthlyInputsValidationFunc(
    numberOfMonths,
    maxLimitsObject,
    CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG
  );

  const { initialValues, initialTouched, validationSchema } =
    getFormikFormInputsFromColConfigAndInputSource(
      CLUSTER_WORKLOAD_DETAILS_CONFIG,
      clusterInputsSource,
      { dealDuration: metaData.deal_duration }
    );

  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialTouched={initialTouched}
        initialValues={initialValues}
        onSubmit={() => {}}
        validateOnBlur={true}
        validateOnChange={false}
        validateOnMount={true}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form autoComplete="off">
            <ValidateFormOnMount />
            <FormListener formik={formik} />
            <PromptIfFormHasUnSavedChanges />
            <SPClusterWorkloadEnabledContainer
              checked={spClusterWorkloadData.is_enabled}
              clusterResourceId={spClusterWorkloadData?.cluster_resource_id}
              clusterWorkloadId={clusterWorkloadId}
            />
            <SPClusterWorkloadDeleteContainer />
            <Spacer y={10} />
            <SPClusterWorkloadAccordionsContainer />
          </Form>
        )}
      </Formik>
    </StyledContainer>
  );
};
