// @flow
import React, { useContext } from 'react';
import { SPFlinkWorkloadDeleteWarningModal } from '@streaming-projects/workloads/flink-workloads/delete/SPFlinkWorkloadDeleteWarningModal';
import { DeleteRenderer } from '@components/views/grid-utils/renderers';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { FRONT_END_DISPLAY_NAMES } from '@streaming-projects/enums';

export const SPFlinkWorkloadDeleteRenderer = ({ data }) => {
  const spStreamingProjectData = useContext(SPStreamingProjectContext);
  return (
    <DeleteRenderer>
      {(isDeleteModalOpen, setDeleteModalOpen) => {
        return (
          <SPFlinkWorkloadDeleteWarningModal
            flinkWorkloadId={data[FRONT_END_DISPLAY_NAMES.WORKLOAD_ID]}
            flinkWorkloadName={data[FRONT_END_DISPLAY_NAMES.WORKLOAD_NAME]}
            flinkWorkloadResourceId={data[FRONT_END_DISPLAY_NAMES.RESOURCE_ID]}
            isOpen={isDeleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            spVersion={spStreamingProjectData?.sp?.version}
          />
        );
      }}
    </DeleteRenderer>
  );
};
