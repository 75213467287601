// @flow
import { useParams } from 'react-router-dom';
import React, { useCallback, useMemo, createContext, useContext } from 'react';
import { DataFetcherContainer } from '@presentational/DataFetcherContainer';
import { shouldSkipDataFetching } from '@src/common-utils/utils';
import { useGetSPClusterWorkloadViewQuery } from '@streaming-projects/service-definitions/streamingProjectsApi';

const SPClusterWorkloadContext = createContext({});

export const SPClusterWorkloadContextProvider = ({ children }) => {
  const params = useParams();

  return (
    <DataFetcherContainer
      dataFetchingArgs={useMemo(
        () => [
          { ...params },
          {
            skip: shouldSkipDataFetching(params),
          },
        ],
        [params]
      )}
      dataFetchingFunction={useCallback(useGetSPClusterWorkloadViewQuery, [])}
    >
      {(data) => {
        return (
          <SPClusterWorkloadContext.Provider value={data}>
            {children}
          </SPClusterWorkloadContext.Provider>
        );
      }}
    </DataFetcherContainer>
  );
};

export const useSPClusterWorkloadContext = () => {
  return useContext(SPClusterWorkloadContext);
};
