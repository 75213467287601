// @flow
import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { DataFetcherContainer } from '@presentational/DataFetcherContainer';
import { useGetStreamingProjectsMetadataQuery } from '@streaming-projects/service-definitions/streamingProjectsApi';

export const SPRateCardContext = createContext({});

export const SPRateCardMetaContextProvider = ({ children }) => {
  const args = useMemo(
    () => [
      {
        query: 'RC_META',
      },
    ],
    []
  );

  return (
    <DataFetcherContainer
      dataFetchingArgs={args}
      dataFetchingFunction={useCallback(useGetStreamingProjectsMetadataQuery, [])}
    >
      {(data) => {
        return <SPRateCardContext.Provider value={data}>{children}</SPRateCardContext.Provider>;
      }}
    </DataFetcherContainer>
  );
};

export const useRateCardMetaContext = () => useContext(SPRateCardContext);
