// @flow
import React from 'react';
import { useParams } from 'react-router-dom';
import { LinkRenderer } from '@components/views/grid-utils/renderers';
import { getLinkForSPDefaultCommitDetailsPage } from '@streaming-projects/links';
import { FRONT_END_DISPLAY_NAMES } from '@streaming-projects/enums';

const SPDefaultCommitNameRenderer = () => {
  const { orgId } = useParams();

  return (
    <LinkRenderer
      pathName={getLinkForSPDefaultCommitDetailsPage(orgId)}
      text={FRONT_END_DISPLAY_NAMES.DEFAULT_COMMIT_NAME}
    />
  );
};

export default SPDefaultCommitNameRenderer;
