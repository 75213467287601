// @flow
import React from 'react';
import { ShowLoaderIfAnyQueryIsPending } from '@presentational/ShowLoaderIfAnyQueryIsPending';
import { UserContextProvider } from '@src/contexts/UserContextProvider';
import { SPStreamingProjectContextProvider } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContextProvider';
import { SPOrgContextProvider } from '@streaming-projects/orgs/contexts/SPOrgContextProvider';
import { StreamGovernanceWorkloadContextProvider } from '@streaming-projects/workloads/stream-governance-workloads/contexts/StreamGovernanceWorkloadContextProvider';
import { SPStreamGovernanceWorkloadBreadcrumbs } from '@streaming-projects/workloads/stream-governance-workloads/breadcrumbs/SPStreamGovernanceWorkloadBreadcrumbs';
import { SPStreamGovernanceWorkloadTopLevelContainerForm } from '@streaming-projects/workloads/stream-governance-workloads/SPStreamGovernanceWorkloadTopLevelContainerForm';
import { SPMetaDataContextProvider } from '@streaming-projects/orgs/contexts/SPMetaDataContextProvider';

export const SPStreamGovernanceWorkloadTopLevelContainer = () => {
  return (
    <>
      <ShowLoaderIfAnyQueryIsPending />
      <UserContextProvider>
        <SPOrgContextProvider>
          <SPStreamingProjectContextProvider>
            <SPMetaDataContextProvider>
              <StreamGovernanceWorkloadContextProvider>
                <SPStreamGovernanceWorkloadBreadcrumbs />
                <SPStreamGovernanceWorkloadTopLevelContainerForm />
              </StreamGovernanceWorkloadContextProvider>
            </SPMetaDataContextProvider>
          </SPStreamingProjectContextProvider>
        </SPOrgContextProvider>
      </UserContextProvider>
    </>
  );
};
