// @flow
import React, { useContext } from 'react';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';
import { SecondaryTopBar } from '@components/breadcrumbs/SecondaryTopBar';
import { getWorkloadBreadcrumbs } from '@streaming-projects/utils';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { FlinkPoolWorkloadContext } from '@streaming-projects/workloads/flink-workloads/contexts/FlinkPoolWorkloadContext';

export const SPFlinkWorkloadBreadcrumbs = () => {
  const spOrgData = useContext(SPOrgContext);
  const spStreamingProjectData = useContext(SPStreamingProjectContext);
  const spFlinkPoolWorkloadData = useContext(FlinkPoolWorkloadContext);
  const workloadName = spFlinkPoolWorkloadData?.name;
  return (
    <SecondaryTopBar
      sectionsToShow={getWorkloadBreadcrumbs(
        spOrgData,
        spStreamingProjectData,
        workloadName,
        'Flink'
      )}
      showLastUpdatedMessage={false}
    />
  );
};
