// @flow
import { useParams } from 'react-router-dom';
import React, { useCallback, useMemo } from 'react';
import { DataFetcherContainer } from '@presentational/DataFetcherContainer';
import { shouldSkipDataFetching } from '@src/common-utils/utils';
import { useGetSPClusterLinkingWorkloadViewQuery } from '@streaming-projects/service-definitions/streamingProjectsApi';

import { ClusterLinkingWorkloadContext } from './ClusterLinkingWorkloadContext';

export const ClusterLinkingWorkloadContextProvider = ({ children }) => {
  const params = useParams();

  return (
    <DataFetcherContainer
      dataFetchingArgs={useMemo(
        () => [
          { ...params },
          {
            skip: shouldSkipDataFetching(params),
          },
        ],
        [params]
      )}
      dataFetchingFunction={useCallback(useGetSPClusterLinkingWorkloadViewQuery, [])}
    >
      {(data) => {
        return (
          <ClusterLinkingWorkloadContext.Provider value={data}>
            {children}
          </ClusterLinkingWorkloadContext.Provider>
        );
      }}
    </DataFetcherContainer>
  );
};
