// @flow
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';

import { SPMLDMutationsContext } from '../contexts/SPMLDMutationsContext';
import {
  FieldArrayGridConfigItemContext,
  FieldArrayGridUpdateButton,
} from '../../../../../../formik-utils/FieldArrayGrid';
import {
  DEFAULT_VALUE_FOR_DB_ROW_ID,
  METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_MLD_ID_BACKEND_NAME,
  RATE_CARD_BACKEND_NAME,
  ROW_ID,
} from '../../../../../../constants';

export const SPDimensionsConfigUpdateButtonContainer = () => {
  const { orgId } = useParams();
  const {
    metricDiscountUpdateMutation: [updateMetricDiscounts],
    metricDiscountCreateMutation: [createMetricDiscounts],
  } = useContext(SPMLDMutationsContext);

  const orgData = useContext(SPOrgContext);

  const { columnsConfiguration, rowValues } = useContext(FieldArrayGridConfigItemContext);

  const payload = {
    ...rowValues,
    metric_name: rowValues.metricName,
    discount: Number(rowValues.discount),
    rate_card_version: orgData?.org[RATE_CARD_BACKEND_NAME],
    org_version: orgData?.org?.version,
  };

  const updateFuncParams = {
    orgId,
    metricsDiscountId:
      rowValues[ROW_ID] === DEFAULT_VALUE_FOR_DB_ROW_ID
        ? null
        : rowValues[METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_MLD_ID_BACKEND_NAME],
    payload,
  };

  return (
    <FieldArrayGridUpdateButton
      columnsConfig={columnsConfiguration}
      errorMessage="There was an error in updating the discount configuration, please contact #cloud-commitment-estimator channel!"
      updateFunc={
        rowValues[ROW_ID] === DEFAULT_VALUE_FOR_DB_ROW_ID
          ? createMetricDiscounts
          : updateMetricDiscounts
      }
      updateFuncParams={updateFuncParams}
    />
  );
};
