// @flow
import React from 'react';
import { CLUSTER_LINKING_RESOURCES_GRID_HEADERS } from '@streaming-projects/orgs/enums';
import { SPClusterLinkingResourceDeleteWarningModal } from '@streaming-projects/resource-definitions/cluster-linking-resource-definitions/SPClusterLinkingResourceDeleteWarningModal';

import { DeleteRenderer } from '../../../views/grid-utils/renderers';

export const SPClusterLinkingDeleteRenderer = ({ data }) => {
  return (
    <DeleteRenderer>
      {(isDeleteModalOpen, setDeleteModalOpen) => {
        return (
          <SPClusterLinkingResourceDeleteWarningModal
            clusterLinkId={data[CLUSTER_LINKING_RESOURCES_GRID_HEADERS.ID]}
            clusterLinkName={data[CLUSTER_LINKING_RESOURCES_GRID_HEADERS.NAME]}
            isOpen={isDeleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            version={data[CLUSTER_LINKING_RESOURCES_GRID_HEADERS.VERSION]}
          />
        );
      }}
    </DeleteRenderer>
  );
};
