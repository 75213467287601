// @flow
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { DeleteButton } from '@presentational/buttons/DeleteButton';

import { useSPClusterWorkloadContext } from '../contexts/SPClusterWorkloadContextProvider';

import { SPClusterWorkloadDeleteWarningModal } from './SPClusterWorkloadDeleteWarningModal';

export const SPClusterWorkloadDeleteContainer = () => {
  const { clusterWorkloadId } = useParams();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const spStreamingProjectData = useContext(SPStreamingProjectContext);
  const spClusterPoolWorkloadData = useSPClusterWorkloadContext();

  return (
    <>
      <DeleteButton
        onClick={() => {
          setDeleteModalOpen(true);
        }}
        size="large"
      />
      <SPClusterWorkloadDeleteWarningModal
        clusterWorkloadId={clusterWorkloadId}
        clusterWorkloadName={spClusterPoolWorkloadData?.name}
        clusterWorkloadResourceId={spClusterPoolWorkloadData?.cluster_resource_id}
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        spVersion={spStreamingProjectData?.sp?.version}
      />
    </>
  );
};
