// @flow
import React, { useContext } from 'react';
import { FlinkPoolWorkloadContext } from '@streaming-projects/workloads/flink-workloads/contexts/FlinkPoolWorkloadContext';
import { useParams } from 'react-router-dom';
import { FLINK_WORKLOAD_DETAILS_CONFIG } from '@streaming-projects/workloads/flink-workloads/config';
import { getFormikFormInputsFromColConfigAndInputSource } from '@src/common-utils/utils';
import { Form } from '@src/formik-utils/formikSUIWrapper';
import { ValidateFormOnMount } from '@src/formik-utils/ValidateFormOnMount';
import FormListener from '@src/formik-utils/FormListener';
import { PromptIfFormHasUnSavedChanges } from '@presentational/PromptIfFormHasUnSavedChanges';
import { Spacer } from '@presentational/spacing/Spacer';
import { StyledContainer } from '@src/common-utils/styledComponents';
import { Formik } from 'formik';
import { SPFlinkWorkloadEnabledContainer } from '@streaming-projects/workloads/flink-workloads/enable/SPFlinkWorkloadEnabledContainer';
import { SPFlinkWorkloadDeleteContainer } from '@streaming-projects/workloads/flink-workloads/delete/SPFlinkWorkloadDeleteContainer';
import { SPFlinkWorkloadAccordionsContainer } from '@streaming-projects/workloads/flink-workloads/SPFlinkWorkloadAccordionsContainer';
import { FLINK_WORKLOADS_INPUTS_FIELDNAMES } from '@streaming-projects/orgs/enums';
import { getSPFlinkMonthlyInputsValidation } from '@streaming-projects/workloads/flink-workloads/utils';
import { useMetaDataContext } from '@streaming-projects/orgs/contexts/SPMetaDataContextProvider';

export const SPFlinkWorkloadTopLevelContainerForm = () => {
  const spFlinkPoolWorkloadData = useContext(FlinkPoolWorkloadContext);
  const { flinkWorkloadId } = useParams();

  const flinkPoolConfigeMaxLimitsAllowed = useMetaDataContext().flink_pool_config;
  const flinkPoolInputsSource = {
    id: flinkWorkloadId,
    name: spFlinkPoolWorkloadData.name,
    is_enabled: spFlinkPoolWorkloadData.is_enabled,
    [FLINK_WORKLOADS_INPUTS_FIELDNAMES.MONTHLY_INPUTS]: spFlinkPoolWorkloadData.flink_throughput,
  };

  const numberOfMonths = spFlinkPoolWorkloadData.flink_throughput.inputs.length;
  const maxLimitsObject = {
    cfu_count: flinkPoolConfigeMaxLimitsAllowed.cfu_count_threshold,
  };

  // Append the Dynamic Validations Required
  FLINK_WORKLOAD_DETAILS_CONFIG.find(
    (x) => x.backendFieldName === FLINK_WORKLOADS_INPUTS_FIELDNAMES.MONTHLY_INPUTS
  ).validation = getSPFlinkMonthlyInputsValidation(numberOfMonths, maxLimitsObject);

  const { initialValues, initialTouched, validationSchema } =
    getFormikFormInputsFromColConfigAndInputSource(
      FLINK_WORKLOAD_DETAILS_CONFIG,
      flinkPoolInputsSource
    );

  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialTouched={initialTouched}
        initialValues={initialValues}
        onSubmit={() => {}}
        validateOnBlur={true}
        validateOnChange={false}
        validateOnMount={true}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form autoComplete="off">
            <ValidateFormOnMount />
            <FormListener formik={formik} />
            <PromptIfFormHasUnSavedChanges />
            <SPFlinkWorkloadEnabledContainer
              checked={spFlinkPoolWorkloadData.is_enabled}
              flinkResourceId={spFlinkPoolWorkloadData?.flink_pool_config_id}
              flinkWorkloadId={flinkWorkloadId}
            />
            <SPFlinkWorkloadDeleteContainer />
            <Spacer y={10} />
            <SPFlinkWorkloadAccordionsContainer />
          </Form>
        )}
      </Formik>
    </StyledContainer>
  );
};
