// @flow
import React, { useContext, useState } from 'react';
import {
  FLINK_POOL_RESOURCES_GRID_FIELDNAMES,
  FLINK_POOL_RESOURCES_GRID_HEADERS,
} from '@streaming-projects/orgs/enums';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { SPFlinkPoolDeleteRenderer } from '@streaming-projects/resource-definitions/flink-resource-definitions/SPFlinkPoolDeleteRenderer';
import { PrimaryButton } from '@presentational/buttons/PrimaryButton';
import { SPFlinkConfigurationAddEditModal } from '@streaming-projects/resource-definitions/flink-resource-definitions/SPFlinkConfigurationAddEditModal';
import { FlinkPoolEditRenderer } from '@streaming-projects/resource-definitions/flink-resource-definitions/FlinkPoolEditRenderer';
import { SPMetaDataContext } from '@streaming-projects/orgs/contexts/SPMetaDataContext';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';

import { DataGrid } from '../../../views/grid-utils/DataGrid';
import { getMappedDataValueGetter, getRegionName } from '../auxilary-components/MappedDataRenderer';

export const SPFlinkResourceConfigurationsSummaryContainer = () => {
  const [isSPFlinkConfigAddEditModalOpen, setSPFlinkConfigAddEditModalOpen] = useState(false);

  const { providerRegionsMap, providerNameLabelMap } = useContext(SPMetaDataContext).metaData;

  const { flink_resources: inputDataSource } = useContext(SPOrgContext).resources;

  const areAnyFlinkPoolsConfigured = inputDataSource?.length > 0;
  const columnDefs = [
    {
      field: FLINK_POOL_RESOURCES_GRID_HEADERS.ID,
      backEndFieldName: FLINK_POOL_RESOURCES_GRID_FIELDNAMES.ID,
      hide: true,
    },
    {
      field: FLINK_POOL_RESOURCES_GRID_HEADERS.NAME,
      backEndFieldName: FLINK_POOL_RESOURCES_GRID_FIELDNAMES.NAME,
    },
    {
      field: FLINK_POOL_RESOURCES_GRID_HEADERS.PROVIDER,
      backEndFieldName: FLINK_POOL_RESOURCES_GRID_FIELDNAMES.PROVIDER,
      valueGetter: getMappedDataValueGetter(providerNameLabelMap),
    },
    {
      field: FLINK_POOL_RESOURCES_GRID_HEADERS.REGION,
      backEndFieldName: FLINK_POOL_RESOURCES_GRID_FIELDNAMES.REGION,
      tooltipValueGetter: (params) => getRegionName(params, providerRegionsMap),
      valueGetter: (params) => getRegionName(params, providerRegionsMap),
    },
    {
      field: FLINK_POOL_RESOURCES_GRID_HEADERS.VERSION,
      backEndFieldName: FLINK_POOL_RESOURCES_GRID_FIELDNAMES.VERSION,
      hide: true,
    },
    {
      field: '',
      filter: false,
      cellRenderer: FlinkPoolEditRenderer,
      suppressSizeToFit: true,
      maxWidth: 60,
      sortable: false,
    },
    {
      field: '',
      filter: false,
      cellRenderer: SPFlinkPoolDeleteRenderer,
      suppressSizeToFit: true,
      maxWidth: 60,
      sortable: false,
    },
  ];

  return (
    <PaddedAndRaisedSegment>
      <PrimaryButton
        onClick={() => {
          setSPFlinkConfigAddEditModalOpen(true);
        }}
        size="mini"
        text="Add Flink Shared Resource Configuration"
      />

      <SPFlinkConfigurationAddEditModal
        isOpen={isSPFlinkConfigAddEditModalOpen}
        setOpen={setSPFlinkConfigAddEditModalOpen}
      />

      {areAnyFlinkPoolsConfigured && (
        <DataGrid
          columnDefs={columnDefs}
          displayResizingColsOption={true}
          downloadAllCols={false}
          inputDataSource={inputDataSource}
          label="Flink Pools ( FP ) Configuration Summary"
          sizeColumnsToFitInitially={true}
          tooltipShowDelay={0}
        />
      )}
    </PaddedAndRaisedSegment>
  );
};
