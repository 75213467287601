// @flow
import React from 'react';
import { useFormikContext } from 'formik';

import { WarningMessageContainer } from '../components/presentational/messages/WarningMessageContainer';
import { CLUSTER_RETENTION_BACKEND_NAME } from '../constants';

export const RetentionApprovalRequiredContainer = ({ isCustomerViewEnabled }) => {
  const { values } = useFormikContext();

  const isRetentionAboveThreshold = (values?.[CLUSTER_RETENTION_BACKEND_NAME] ?? 0) > 30;

  const shouldShowWarning = isRetentionAboveThreshold && !isCustomerViewEnabled;

  if (!shouldShowWarning) {
    return null;
  }

  return (
    <WarningMessageContainer
      link="https://confluent.seismic.com/Link/Content/DCW3VFjTXHB7WGmWMQC98DF8gPc8"
      linkText="Verify Policy"
      message="SE Leadership Approval Required. "
    />
  );
};
