// @flow
import React from 'react';
import { useFormikContext } from 'formik';
import { TextField } from '@src/formik-utils/FormFields';
import { CLUSTER_WORKLOADS_INPUTS_FIELDNAMES } from '@streaming-projects/orgs/enums';

import { CLUSTER_WORKLOAD_DETAILS_CONFIG_MAP } from '../config';

export const SPClusterRetentionContainer = ({ disabled = false }) => {
  const { values } = useFormikContext();

  const { displayName, backendFieldName, icon, inputType } =
    CLUSTER_WORKLOAD_DETAILS_CONFIG_MAP.get(CLUSTER_WORKLOADS_INPUTS_FIELDNAMES.CLUSTER_RETENTION);

  return (
    <TextField
      disabled={values[CLUSTER_WORKLOADS_INPUTS_FIELDNAMES.CLUSTER_RETENTION_INFINITE] || disabled}
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      icon={icon}
      type={inputType}
    />
  );
};
