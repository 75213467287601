// @flow
import { getDropdownOptionsFromArray } from '@src/common-utils/utils';
import { SP_COMMIT_CONFIGS } from '@src/constants';
import { SelectField } from '@src/formik-utils/FormFields';
import { SP_DEFAULT_COMMIT_DETAILS_CONFIG_MAP } from '@streaming-projects/default-commit-details/config';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';
import React from 'react';

const SPDefaultCommitSupportTierContainer = () => {
  const { backendFieldName, displayName } = SP_DEFAULT_COMMIT_DETAILS_CONFIG_MAP.get(
    BACKEND_FIELDNAMES.SUPPORT_TIER
  );

  // TODO::SP - Get these from backend
  const supportTiersOptions = getDropdownOptionsFromArray(SP_COMMIT_CONFIGS.support_tiers);

  return (
    <SelectField
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      fluid={true}
      options={supportTiersOptions}
    />
  );
};

export default SPDefaultCommitSupportTierContainer;
