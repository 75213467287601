// @flow

import { useFormikContext } from 'formik';
import React, { useContext } from 'react';
import { FLINK_POOL_RESOURCE_INPUTS_DETAILS_CONFIG_MAP } from '@streaming-projects/resource-definitions/flink-resource-definitions/flink-resource-inputs-config';
import { FLINK_POOL_RESOURCES_GRID_FIELDNAMES } from '@streaming-projects/orgs/enums';
import { getFormattedRegionsUniverse } from '@src/common-utils/utils';
import { getDropdownOptions } from '@components/stream-governance/utils';
import { SelectField } from '@src/formik-utils/FormFields';
import { SPMetaDataContext } from '@streaming-projects/orgs/contexts/SPMetaDataContext';

export const FlinkPoolResourceRegionContainer = ({
  disabled,
  disableOnFormErrors = null,
  fluid = null,
}) => {
  const { values } = useFormikContext();
  const flinkPoolRegionsConfig = FLINK_POOL_RESOURCE_INPUTS_DETAILS_CONFIG_MAP.get(
    FLINK_POOL_RESOURCES_GRID_FIELDNAMES.REGION
  );

  const dataUniverse =
    useContext(SPMetaDataContext).metaData.resource_region_details.region_details;

  const dataUniverseFormatted = getFormattedRegionsUniverse(
    dataUniverse,
    FLINK_POOL_RESOURCES_GRID_FIELDNAMES.PROVIDER_LABEL
  );

  const flinkPoolRegionsOptions = getDropdownOptions(
    dataUniverseFormatted,
    values,
    flinkPoolRegionsConfig,
    false,
    true,
    false,
    false,
    [],
    true
  );

  return (
    <SelectField
      disableOnFormErrors={disableOnFormErrors}
      disabled={disabled}
      fieldDisplayName={flinkPoolRegionsConfig.displayName}
      fieldName={flinkPoolRegionsConfig.backendFieldName}
      fluid={fluid}
      options={flinkPoolRegionsOptions}
    />
  );
};
