// @flow
export const LINK = 'link';
export const BASELINE_SP_IDENTIFIER_NAME = 'Baseline';

export const BACKEND_FIELDNAMES = {
  NAME: 'name',
  ID: 'id',
  LINK: 'link',
  SFDC_ORG_NAME: 'sfdc_org_name',
  SFDC_ORG_ID: 'sfdc_org_id',
  RATE_CARD: 'rate_card',
  ACCOUNT_NAME: 'account_name',
  ACCOUNT_ID: 'account_id',
  SP_NAME: 'name',
  SP_SFDC_ID: 'spSfdcId',
  SP_START_DATE: 'start_date',
  SP_END_DATE: 'end_date',
  WORKLOAD_ID: 'id',
  WORKLOAD_NAME: 'name',
  RESOURCE_ID: 'resource_id',
  RESOURCE_NAME: 'resource_name',
  IS_ENABLED: 'is_enabled',

  // CTODO::SP check all the below after backend changes
  COMMIT_ID: 'commit_id',
  SUPPORT_TIER: 'support_tier',
  COMMIT_AMOUNT: 'user_entered_commit_amount',
  DEFAULT_COMMIT_DETAILS: 'default_commit_details',
  COMMITS: 'commits',
};

export const FRONT_END_DISPLAY_NAMES = {
  SP_NAME: 'SP Name',
  SP_ID: 'Streaming Project ID',
  SP_START_DATE: 'Start Date',
  SP_END_DATE: 'End Date',
  WORKLOAD_ID: 'Workload ID',
  WORKLOAD_NAME: 'Workload Name',
  RESOURCE_ID: 'Resource ID',
  RESOURCE_NAME: 'Resource Name',
  IS_ENABLED: 'Enabled',
  COMMIT_NAME: 'Commit Name',
  DEAL_START_DATE: 'Deal Start Date',
  DEAL_END_DATE: 'Deal End Date',
  SUPPORT_TIER: 'Support Tier',
  COMMIT_AMOUNT: 'Total Commit',
  DEFAULT_COMMIT_NAME: 'Default Commit',
};
