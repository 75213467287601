// @flow
import React, { useContext, useState } from 'react';
import { AccordionsList } from '@presentational/accordions/AccordionsList';
import { YearSelectionContext } from '@components/cluster/YearSelectionContext';
import { SPFlinkWorkloadDetailsContainer } from '@streaming-projects/workloads/flink-workloads/workload-details/SPFlinkWorkloadDetailsContainer';
import {
  getAccordianPanelDetailsObject,
  getNumberOfYearsBasedOnMonths,
} from '@src/common-utils/utils';
import { SPFlinkWorkloadResourceDetailsContainer } from '@streaming-projects/workloads/flink-workloads/resource-details/SPFlinkWorkloadResourceDetailsContainer';
import { SPFlinkWorkloadMonthlyUsageContainer } from '@streaming-projects/workloads/flink-workloads/monthly-inputs/SPFlinkWorkloadMonthlyUsageContainer';
import { FlinkPoolWorkloadContext } from '@streaming-projects/workloads/flink-workloads/contexts/FlinkPoolWorkloadContext';

export const SPFlinkWorkloadAccordionsContainer = () => {
  const spFlinkPoolWorkloadData = useContext(FlinkPoolWorkloadContext);
  const totalYears = getNumberOfYearsBasedOnMonths(
    spFlinkPoolWorkloadData.flink_throughput.inputs.length
  );
  const [selectedYear, setSelectedYear] = useState(1);

  const accordionPanels = [
    getAccordianPanelDetailsObject(
      'Workload Specific Details',
      <SPFlinkWorkloadDetailsContainer />
    ),
    getAccordianPanelDetailsObject('Resource Details', <SPFlinkWorkloadResourceDetailsContainer />),
    getAccordianPanelDetailsObject('Usage Details', <SPFlinkWorkloadMonthlyUsageContainer />),
  ];

  return (
    <YearSelectionContext.Provider
      value={{
        totalYears,
        selectedYear,
        setSelectedYear,
      }}
    >
      <AccordionsList panels={accordionPanels} />
    </YearSelectionContext.Provider>
  );
};
