// @flow
import { StyledGridRow } from '@src/common-utils/styledComponents';
import { NegativeMessageContainer } from '@presentational/messages/NegativeMessageContainer';
import React from 'react';
import { getIfThereIsAnExistingLinkBetweenChosenSourceAndDest } from '@streaming-projects/resource-definitions/cluster-linking-resource-definitions/utils';

export const ExistingLinkWarningContainer = ({
  selectedSourceRow,
  selectedDestinationRow,
  existingClusterLinks,
}) => {
  const isAClusterLinkAlreadyPresentBetweenTheChosenSourceAndDestination =
    getIfThereIsAnExistingLinkBetweenChosenSourceAndDest(
      selectedSourceRow,
      selectedDestinationRow,
      existingClusterLinks
    );

  return (
    <>
      {isAClusterLinkAlreadyPresentBetweenTheChosenSourceAndDestination ? (
        <StyledGridRow>
          <NegativeMessageContainer header="There is already an existing Link between the two clusters!" />
        </StyledGridRow>
      ) : null}
    </>
  );
};
