// @flow
import React from 'react';
import { FRONT_END_DISPLAY_NAMES } from '@streaming-projects/enums';
import { SPClusterWorkloadEnabledContainer } from '@streaming-projects/workloads/cluster-workloads/enable/SPClusterWorkloadEnabledContainer';

export const SPClusterWorkloadEnabledCellRenderer = (props) => {
  if (props.value == null) {
    return null;
  }

  const clusterWorkloadId = props.data[FRONT_END_DISPLAY_NAMES.WORKLOAD_ID];
  const clusterResourceId = props.data[FRONT_END_DISPLAY_NAMES.RESOURCE_ID];

  return (
    <SPClusterWorkloadEnabledContainer
      addLabel={false}
      checked={props.value}
      clusterResourceId={clusterResourceId}
      clusterWorkloadId={clusterWorkloadId}
    />
  );
};
