// @flow
import React, { useContext, useState } from 'react';
import { AccordionsList } from '@presentational/accordions/AccordionsList';
import { YearSelectionContext } from '@components/cluster/YearSelectionContext';
import {
  getAccordianPanelDetailsObject,
  getNumberOfYearsBasedOnMonths,
} from '@src/common-utils/utils';
import { SPClusterLinkingWorkloadDetailsContainer } from '@streaming-projects/workloads/cluster-linking-workloads/workload-details/SPClusterLinkingWorkloadDetailsContainer';
import { ClusterLinkingWorkloadContext } from '@streaming-projects/workloads/cluster-linking-workloads/contexts/ClusterLinkingWorkloadContext';
import { SPClusterLinkingWorkloadResourceDetailsContainer } from '@streaming-projects/workloads/cluster-linking-workloads/resource-details/SPClusterLinkingWorkloadResourceDetailsContainer';
import { SPClusterLinkingWorkloadMonthlyUsageContainer } from '@streaming-projects/workloads/cluster-linking-workloads/monthly-inputs/SPClusterLinkingWorkloadMonthlyUsageContainer';

export const SPClusterLinkingWorkloadAccordionsContainer = () => {
  const workloadData = useContext(ClusterLinkingWorkloadContext);
  const totalYears = getNumberOfYearsBasedOnMonths(workloadData.cluster_linking.inputs.length);
  const [selectedYear, setSelectedYear] = useState(1);

  const accordionPanels = [
    getAccordianPanelDetailsObject(
      'Workload Specific Details',
      <SPClusterLinkingWorkloadDetailsContainer />
    ),
    getAccordianPanelDetailsObject(
      'Resource Details',
      <SPClusterLinkingWorkloadResourceDetailsContainer />
    ),
    getAccordianPanelDetailsObject(
      'Usage Details',
      <SPClusterLinkingWorkloadMonthlyUsageContainer />
    ),
  ];

  return (
    <YearSelectionContext.Provider
      value={{
        totalYears,
        selectedYear,
        setSelectedYear,
      }}
    >
      <AccordionsList panels={accordionPanels} />
    </YearSelectionContext.Provider>
  );
};
