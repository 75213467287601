// @flow
import React, { useRef } from 'react';
import { Grid } from 'semantic-ui-react';

import { SPMetricNameDropdownContainer } from './SPMetricNameDropdownContainer';
import { SPMetricNameDeleteButtonContainer } from './SPMetricNameDeleteButtonContainer';
import { SPMetricsDimensionsListContainer } from './SPMetricsDimensionsListContainer';
import { SPMetricNameAddButtonContainer } from './SPMetricNameAddButtonContainer';

export const SPMetricsLevelDiscountsMetricConfigurationItem = ({ readOnlyMode }) => {
  const metricDimensionRefs = useRef({
    dimensionArrayHelpers: null,
    dimensionColumnsConfiguration: null,
  });

  return (
    <>
      <Grid.Row>
        <Grid.Column width={3}>
          <SPMetricNameDropdownContainer />
        </Grid.Column>
        <Grid.Column width={1}>
          <SPMetricNameDeleteButtonContainer />
        </Grid.Column>
        <Grid.Column
          style={{
            marginLeft: '-2rem',
          }}
          width={1}
        >
          <SPMetricNameAddButtonContainer metricDimensionRefs={metricDimensionRefs} />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <SPMetricsDimensionsListContainer
            metricDimensionRefs={metricDimensionRefs}
            readOnlyMode={readOnlyMode}
          />
        </Grid.Column>
      </Grid.Row>
    </>
  );
};
