// @flow

import React, { useContext, useState } from 'react';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { PrimaryButton } from '@presentational/buttons/PrimaryButton';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { DataGrid } from '@components/views/grid-utils/DataGrid';
import {
  CLUSTER_LINKING_RESOURCES_GRID_FIELDNAMES,
  CLUSTER_LINKING_RESOURCES_GRID_HEADERS,
} from '@streaming-projects/orgs/enums';
import { ENABLED_DISABLED_FILTER_PARAMS } from '@components/views/grid-utils/constants';
import { BACKEND_FIELDNAMES, FRONT_END_DISPLAY_NAMES } from '@streaming-projects/enums';
import { SPClusterLinkingWorkloadAddModal } from '@streaming-projects/sp-page/workloads-summary/cluster-linking-workloads-summary/SPClusterLinkingWorkloadAddModal';
import { SPClusterLinkingWorkloadEnabledCellRenderer } from '@streaming-projects/sp-page/workloads-summary/cluster-linking-workloads-summary/SPClusterLinkingWorkloadEnabledCellRenderer';
import { SPClusterLinkingWorkloadDeleteRenderer } from '@streaming-projects/sp-page/workloads-summary/cluster-linking-workloads-summary/SPClusterLinkingWorkloadDeleteRenderer';
import { SPClusterLinkingWorkloadNameRenderer } from '@streaming-projects/sp-page/workloads-summary/cluster-linking-workloads-summary/SPClusterLinkingWorkloadNameRenderer';
import { ADD_NEW_WORKLOAD_MESSAGE } from '@streaming-projects/constants';

export const SPClusterLinkingSPPageWorkloadSummaryContainer = () => {
  const [isWorkloadAddModalOpen, setWorkloadAddModalOpen] = useState(false);

  const spStreamingProjectData = useContext(SPStreamingProjectContext);

  const inputDataSource =
    spStreamingProjectData?.use_case_summaries?.cluster_linking_summaries ?? [];

  const areAnyWorkloadsConfigured = inputDataSource.length > 0;

  const columnDefs = [
    {
      field: FRONT_END_DISPLAY_NAMES.WORKLOAD_ID,
      backEndFieldName: BACKEND_FIELDNAMES.WORKLOAD_ID,
      hide: true,
    },
    {
      field: FRONT_END_DISPLAY_NAMES.WORKLOAD_NAME,
      backEndFieldName: BACKEND_FIELDNAMES.WORKLOAD_NAME,
      cellRenderer: SPClusterLinkingWorkloadNameRenderer,
    },
    {
      field: FRONT_END_DISPLAY_NAMES.RESOURCE_ID,
      backEndFieldName: BACKEND_FIELDNAMES.RESOURCE_ID,
      hide: true,
    },
    {
      field: CLUSTER_LINKING_RESOURCES_GRID_HEADERS.NAME,
      backEndFieldName: BACKEND_FIELDNAMES.RESOURCE_NAME,
    },
    {
      field: FRONT_END_DISPLAY_NAMES.IS_ENABLED,
      filter: true,
      filterParams: ENABLED_DISABLED_FILTER_PARAMS,
      cellRenderer: SPClusterLinkingWorkloadEnabledCellRenderer,
      backEndFieldName: BACKEND_FIELDNAMES.IS_ENABLED,
    },
    {
      field: CLUSTER_LINKING_RESOURCES_GRID_HEADERS.SOURCE_CLUSTER_NAME,
      backEndFieldName: CLUSTER_LINKING_RESOURCES_GRID_FIELDNAMES.SOURCE_CLUSTER_NAME,
    },
    {
      field: CLUSTER_LINKING_RESOURCES_GRID_HEADERS.DESTINATION_CLUSTER_NAME,
      backEndFieldName: CLUSTER_LINKING_RESOURCES_GRID_FIELDNAMES.DESTINATION_CLUSTER_NAME,
    },
    {
      field: '',
      filter: false,
      cellRenderer: SPClusterLinkingWorkloadDeleteRenderer,
      suppressSizeToFit: true,
      maxWidth: 60,
      sortable: false,
    },
  ];

  return (
    <PaddedAndRaisedSegment>
      <PrimaryButton
        onClick={() => {
          setWorkloadAddModalOpen(true);
        }}
        size="mini"
        text={ADD_NEW_WORKLOAD_MESSAGE}
      />

      <SPClusterLinkingWorkloadAddModal
        isOpen={isWorkloadAddModalOpen}
        setOpen={setWorkloadAddModalOpen}
      />

      {areAnyWorkloadsConfigured && (
        <DataGrid
          columnDefs={columnDefs}
          downloadAllCols={false}
          inputDataSource={inputDataSource}
          label="Cluster Linking Workloads Summary"
          sizeColumnsToFitInitially={true}
          tooltipShowDelay={0}
        />
      )}
    </PaddedAndRaisedSegment>
  );
};
