// @flow
import React from 'react';
import { ShowLoaderIfAnyQueryIsPending } from '@presentational/ShowLoaderIfAnyQueryIsPending';
import { UserContextProvider } from '@src/contexts/UserContextProvider';
import { SPStreamingProjectContextProvider } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContextProvider';
import { FlinkPoolWorkloadContextProvider } from '@streaming-projects/workloads/flink-workloads/contexts/FlinkPoolWorkloadContextProvider';
import { SPFlinkWorkloadBreadcrumbs } from '@streaming-projects/workloads/flink-workloads/breadcrumbs/SPFlinkWorkloadBreadcrumbs';
import { SPOrgContextProvider } from '@streaming-projects/orgs/contexts/SPOrgContextProvider';
import { SPFlinkWorkloadTopLevelContainerForm } from '@streaming-projects/workloads/flink-workloads/SPFlinkWorkloadTopLevelContainerForm';
import { SPMetaDataContextProvider } from '@streaming-projects/orgs/contexts/SPMetaDataContextProvider';

export const SPFlinkWorkloadTopLevelContainer = () => {
  return (
    <>
      <ShowLoaderIfAnyQueryIsPending />
      <UserContextProvider>
        <SPOrgContextProvider>
          <SPStreamingProjectContextProvider>
            <SPMetaDataContextProvider>
              <FlinkPoolWorkloadContextProvider>
                <SPFlinkWorkloadBreadcrumbs />
                <SPFlinkWorkloadTopLevelContainerForm />
              </FlinkPoolWorkloadContextProvider>
            </SPMetaDataContextProvider>
          </SPStreamingProjectContextProvider>
        </SPOrgContextProvider>
      </UserContextProvider>
    </>
  );
};
