// @flow
import React from 'react';
import { SPFlinkWorkloadEnabledContainer } from '@streaming-projects/workloads/flink-workloads/enable/SPFlinkWorkloadEnabledContainer';
import { FRONT_END_DISPLAY_NAMES } from '@streaming-projects/enums';

export const SPFlinkWorkloadEnabledCellRenderer = (props) => {
  if (props.value == null) {
    return null;
  }

  const flinkWorkloadId = props.data[FRONT_END_DISPLAY_NAMES.WORKLOAD_ID];
  const flinkResourceId = props.data[FRONT_END_DISPLAY_NAMES.RESOURCE_ID];

  return (
    <SPFlinkWorkloadEnabledContainer
      addLabel={false}
      checked={props.value}
      flinkResourceId={flinkResourceId}
      flinkWorkloadId={flinkWorkloadId}
    />
  );
};
