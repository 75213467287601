// @flow
import React from 'react';
import { SPClusterLinkingConfigurationAddEditModal } from '@streaming-projects/resource-definitions/cluster-linking-resource-definitions/SPClusterLinkingConfigurationAddEditModal';

import { EditRenderer } from '../../../views/grid-utils/renderers';

export const SPClusterLinkingEditRenderer = ({ data }) => {
  return (
    <EditRenderer>
      {(isEditModalOpen, setEditModalOpen) => {
        return (
          <SPClusterLinkingConfigurationAddEditModal
            initialValues={data}
            isEditingMode={true}
            isOpen={isEditModalOpen}
            setOpen={setEditModalOpen}
          />
        );
      }}
    </EditRenderer>
  );
};
