// @flow
import React from 'react';
import { getIn, useFormikContext } from 'formik';
import {
  CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG,
  CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG,
  CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG,
} from '@src/constants';
import { TabsContainer } from '@presentational/TabsContainer';
import { WarningIcon } from '@presentational/icons/WarningIcon';

import { SPKafkaUsageContainer } from './SPKafkaUsageContainer';
import { SPKSQLDBUsageContainer } from './SPKSQLDBUsageContainer';
import { SPConnectorsTopLevelContainer } from './connectors/SPConnectorsTopLevelContainer';

export const SPClusterUsageTabsContainer = () => {
  const { errors } = useFormikContext();
  const areAnyErrorsPresentInThroughputTab =
    getIn(errors, CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG) != null;

  const areAnyErrorsPresentInConnectorsTab =
    getIn(errors, CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG) != null;

  const areAnyErrorsPresentInKSQLDBTab =
    getIn(errors, CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG) != null;

  const tabPanes = [
    {
      menuItem: {
        key: 'Throughput',
        icon: areAnyErrorsPresentInThroughputTab ? <WarningIcon /> : null,
        content: <>Throughput</>,
      },
      render: () => <SPKafkaUsageContainer />,
    },
    {
      menuItem: {
        key: 'Connectors',
        icon: areAnyErrorsPresentInConnectorsTab ? <WarningIcon /> : null,
        content: <>Connectors</>,
      },
      render: () => <SPConnectorsTopLevelContainer />,
    },
    {
      menuItem: {
        key: 'ksqlDB',
        icon: areAnyErrorsPresentInKSQLDBTab ? <WarningIcon /> : null,
        content: <>ksqlDB</>,
      },
      render: () => <SPKSQLDBUsageContainer />,
    },
  ];
  return <TabsContainer tabPanes={tabPanes} />;
};
