// @flow
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import { getDropdownOptions } from '@components/stream-governance/utils';
import {
  CLUSTER_AVAILABLE_CONNECTORS_LIST_BACKEND_NAME,
  CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG,
  FREIGHT_CLUSTER_TYPE,
} from '@src/constants';
import { getConnectorNameToRecordMap } from '@src/contexts/ClusterContextProvider';
import { SPMetaDataContext } from '@streaming-projects/orgs/contexts/SPMetaDataContext';
import { AddKafkaConnectorComponent } from '@components/cluster/cluster-monthly-inputs/ConnectorsTopLevelContainer';
import { PaddedAndRaisedSegment } from '@components/presentational/PaddedAndRaisedSegment';
import { StyledH5 } from '@components/presentational/headings/StyledH5';

import { useSPClusterWorkloadContext } from '../../contexts/SPClusterWorkloadContextProvider';
import { CLUSTER_WORKLOAD_DETAILS_CONFIG_MAP } from '../../config';

import { SPConnectorsMonthlyInputContainerWrapper } from './SPConnectorsMonthlyInputContainerWrapper';

export const connectorsRowConfig = [
  {
    backendName: 'is_enabled',
    displayName: 'Active',
    isBooleanField: true,
    defaultValue: true,
  },
  {
    backendName: 'task_count',
    displayName: 'Number of Tasks',
    defaultValue: 0,
  },
  {
    backendName: 'throughput_average_mbps',
    displayName: 'Average Throughput (MBps)',
    defaultValue: 0,
  },
];

export const SPConnectorsTopLevelContainer = ({ disabled }) => {
  const { values, initialValues } = useFormikContext();
  const spClusterWorkloadData = useSPClusterWorkloadContext();

  const {
    provider,
    cluster_type: clusterType,
    networking_type: networkingType,
  } = spClusterWorkloadData.cluster_resource_details;
  const connectorsList = useContext(SPMetaDataContext).metaData.fullConnectorsList;

  const isFreightCluster = clusterType === FREIGHT_CLUSTER_TYPE;
  if (isFreightCluster) {
    return (
      <PaddedAndRaisedSegment>
        <StyledH5 text="Freight does not support connectors!" />
      </PaddedAndRaisedSegment>
    );
  }

  const numberOfMonths = spClusterWorkloadData.throughput.inputs.length;
  const keyNameInValues = CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG;

  const connectorOptionsConfig = CLUSTER_WORKLOAD_DETAILS_CONFIG_MAP.get(
    CLUSTER_AVAILABLE_CONNECTORS_LIST_BACKEND_NAME
  );

  const fullConnectorsList = connectorsList.connector_types;

  const connectorNameToRecordsMap = getConnectorNameToRecordMap(connectorsList);

  const connectorsFilterValues = {
    ...values,
    provider,
    cluster_type: clusterType,
    networking_type: networkingType,
  };

  const connectorOptions = getDropdownOptions(
    fullConnectorsList,
    connectorsFilterValues,
    connectorOptionsConfig,
    false,
    false,
    false,
    true
  );
  return (
    <AddKafkaConnectorComponent
      MonthlyInputContainerWrapper={SPConnectorsMonthlyInputContainerWrapper}
      connectorNameToRecordsMap={connectorNameToRecordsMap}
      connectorOptions={connectorOptions}
      connectorOptionsConfig={connectorOptionsConfig}
      disabled={disabled}
      initialValues={initialValues}
      keyNameInValues={keyNameInValues}
      numberOfMonths={numberOfMonths}
      values={values}
    />
  );
};
