// @flow
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { DeleteButton } from '@presentational/buttons/DeleteButton';
import { SPStreamGovernanceWorkloadDeleteWarningModal } from '@streaming-projects/workloads/stream-governance-workloads/delete/SPStreamGovernanceWorkloadDeleteWarningModal';
import { StreamGovernanceWorkloadContext } from '@streaming-projects/workloads/stream-governance-workloads/contexts/StreamGovernanceWorkloadContext';

export const SPStreamGovernanceWorkloadDeleteContainer = () => {
  const { streamGovernanceWorkloadId } = useParams();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const spWorkloadData = useContext(StreamGovernanceWorkloadContext);
  const spStreamingProjectData = useContext(SPStreamingProjectContext);

  return (
    <>
      <DeleteButton
        onClick={() => {
          setDeleteModalOpen(true);
        }}
        size="large"
      />
      <SPStreamGovernanceWorkloadDeleteWarningModal
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        spVersion={spStreamingProjectData?.sp?.version}
        streamGovernanceWorkloadId={streamGovernanceWorkloadId}
        streamGovernanceWorkloadName={spWorkloadData?.name}
        streamGovernanceWorkloadResourceId={spWorkloadData?.stream_governance_resource_id}
      />
    </>
  );
};
