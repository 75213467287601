// @flow

import { Grid } from 'semantic-ui-react';
import React from 'react';

import { Spacer } from '../../../../../presentational/spacing/Spacer';

import { SPGlobalDiscountNoteContainer } from './SPGlobalDiscountNoteContainer';
import { SPGlobalDiscountsUpdateButtonContainer } from './SPGlobalDiscountsUpdateButtonContainer';
import { SPGlobalDiscountLabelContainer } from './SPGlobalDiscountLabelContainer';
import { SPGlobalDiscountsTextFieldContainer } from './SPGlobalDiscountsTextFieldContainer';

export const SPGlobalDiscountContainer = ({ readOnlyMode }) => {
  return (
    <Grid columns={4} stackable={true}>
      <Spacer y={12} />
      {/* { todo::SP restructure styling for label} */}
      <Grid.Column style={{ marginLeft: '-8px' }} textAlign="middle" width={2}>
        <SPGlobalDiscountLabelContainer />
      </Grid.Column>
      <Grid.Column textAlign="middle" width={2}>
        <SPGlobalDiscountsTextFieldContainer readOnlyMode={readOnlyMode} />
      </Grid.Column>
      <Grid.Column textAlign="middle" width={1}>
        <SPGlobalDiscountsUpdateButtonContainer readOnlyMode={readOnlyMode} />
      </Grid.Column>
      <Grid.Column textAlign="middle" width={5}>
        <SPGlobalDiscountNoteContainer />
      </Grid.Column>
    </Grid>
  );
};
