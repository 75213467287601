// @flow
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { StyledGridRow } from '@src/common-utils/styledComponents';
import { SPSPNameContainer } from '@streaming-projects/sp-page/sp-details/SPSPNameContainer';
import { SPStartDateContainer } from '@streaming-projects/sp-page/sp-details/SPStartDateContainer';
import { SPEndDateContainer } from '@streaming-projects/sp-page/sp-details/SPEndDateContainer';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { useUpdateStreamingProjectMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { Spacer } from '@presentational/spacing/Spacer';
import { GenericSaveResetButtons } from '@components/GenericSaveResetButtons';
import { STREAMING_PROJECTS_SP_DETAILS_CONFIG } from '@streaming-projects/sp-page/config';

export const SPStreamingProjectGenericDetailsContainer = () => {
  const { orgId, spId } = useParams();
  const [updateStreamingProjectDetails] = useUpdateStreamingProjectMutation();
  const spSPData = useContext(SPStreamingProjectContext);

  return (
    <>
      <PaddedAndRaisedSegment>
        <Grid columns={2} divided={true}>
          <StyledGridRow coloumns={2}>
            <Grid.Column>
              <SPSPNameContainer />
            </Grid.Column>
          </StyledGridRow>
          <StyledGridRow coloumns={2}>
            <Grid.Column>
              <SPStartDateContainer showWarningOnChange={true} />
            </Grid.Column>
            <Grid.Column>
              <SPEndDateContainer showWarningOnChange={true} />
            </Grid.Column>
          </StyledGridRow>
        </Grid>

        <Spacer y={40} />

        <GenericSaveResetButtons
          fieldsConfig={STREAMING_PROJECTS_SP_DETAILS_CONFIG}
          onSaveHandler={async (payload) => {
            return await updateStreamingProjectDetails({
              orgId,
              spId,
              payload: {
                ...payload,
                org_id: orgId,
                sp_id: spId,
                version: spSPData?.sp?.version,
              },
            });
          }}
        />
      </PaddedAndRaisedSegment>
    </>
  );
};
