// @flow
import React from 'react';
import { Grid } from 'semantic-ui-react';

import { FieldArrayStyledGridRow } from '../../../../../../formik-utils/FieldArrayGrid';

import { SPDependentDimensionsDropdownContainer } from './SPDependentDimensionsDropdownContainer';
import { SPDimensionsConfigUpdateButtonContainer } from './SPDimensionsConfigUpdateButtonContainer';
import { SPDimensionsConfigDeleteButtonContainer } from './SPDimensionsConfigDeleteButtonContainer';

export const SPMetricsLevelDiscountsDimensionsConfigurationItem = () => {
  return (
    <>
      <FieldArrayStyledGridRow>
        <SPDependentDimensionsDropdownContainer />
        <Grid.Column width={1}>
          <SPDimensionsConfigUpdateButtonContainer />
        </Grid.Column>
        <Grid.Column width={1}>
          <SPDimensionsConfigDeleteButtonContainer />
        </Grid.Column>
      </FieldArrayStyledGridRow>
    </>
  );
};
