// @flow
export const DEFAULT_NUMBER_OF_ITEMS_TO_SHOW_ON_HOME_PAGE = 10;
export const MAXIMUM_NUMBER_OF_ACCOUNTS_PER_PAGE_ON_HOME_PAGE = 10;
export const LIMIT_INDICATOR_FOR_FETCH_ALL = -1;
export const STREAMING_PROJECTS_API = 'streamingProjectsApi';
export const NO_FREE_RESOURCE_MESSAGE =
  'No free resource found - Either add a new resource or free up an existing resource!';
export const ADD_NEW_WORKLOAD_MESSAGE = 'Add New Workload';
/**
 * Temporary flag to enable/disable the commit details accordion.
 * TODO::SP Remove variable and usage once ready for release
 */
export const IS_DEFAULT_COMMIT_SECTION_LIVE = true;

export const IS_USER_COMMIT_SECTION_LIVE = false;

/**
 * Temporary flag to enable/disable the cost and margin columns
 * in Streaming Projects Pricing Summary.
 * TODO::SP Remove variable and usage once ready for release
 */
export const IS_COST_AND_MARGIN_COLUMNS_ENABLED = true;

/**
 * Temporary flag to enable/disable the total row pinning
 * in Streaming Projects Pricing Summary.
 * TODO::SP Remove variable and usage once ready for release
 */
export const IS_PINNING_TOTAL_ROW_ENABLED = true;
