// @flow
import { getIn, useFormikContext } from 'formik';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ConfirmModal } from '@presentational/modals/ConfirmModal';
import { ExclusiveAccordionsList } from '@presentational/accordions/ExclusiveAccordionsList';
import { toastError, toastSuccess } from '@presentational/notifications/utils';
import {
  CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG,
  DEFAULT_VALUE_FOR_DB_ROW_ID_STR,
} from '@src/constants';
import { useDeleteSPConnectorMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { getConnectorAccordianPanels } from '@components/cluster/cluster-monthly-inputs/ConnectorsMonthlyInputContainerWrapper';

import { useSPClusterWorkloadContext } from '../../contexts/SPClusterWorkloadContextProvider';

import { SPConnectorsMonthlyInputContainer } from './SPConnectorsMonthlyInputContainer';

export const SPConnectorsMonthlyInputContainerWrapper = ({ arrayHelpers, disabled }) => {
  const { orgId, spId, clusterWorkloadId } = useParams();
  const [isDeleteConnectorModalOpen, setDeleteConnectorModalOpen] = useState(false);
  const [selectedConnector, setSelectedConnector] = useState(null);
  const [deleteSPConnector] = useDeleteSPConnectorMutation();
  const spStreamingProjectData = useContext(SPStreamingProjectContext);
  const spClusterWorkloadData = useSPClusterWorkloadContext();
  const { values, errors, initialValues } = useFormikContext();

  const connectorsData = getIn(values, [
    CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG,
    'connectors',
  ]);

  const reverseConnectorsData = [...connectorsData].reverse();

  const accordionPanels = getConnectorAccordianPanels({
    connectorsData,
    reverseConnectorsData,
    initialValues,
    values,
    errors,
    disabled,
    setDeleteConnectorModalOpen,
    setSelectedConnector,
    MonthlyInputContainerComponent: SPConnectorsMonthlyInputContainer,
  });

  return (
    <>
      <ExclusiveAccordionsList panels={accordionPanels} />
      {selectedConnector && (
        <ConfirmModal
          body={<p>Are you sure you want to delete this Connector?</p>}
          header={`Delete Connector ${selectedConnector?.connector?.connectorLabel}`}
          isOpen={isDeleteConnectorModalOpen}
          onClickHandlerForCancel={() => setDeleteConnectorModalOpen(false)}
          onClickHandlerForOK={async function () {
            const connectorId = selectedConnector?.connector?.connectorId;

            if (connectorId === DEFAULT_VALUE_FOR_DB_ROW_ID_STR) {
              arrayHelpers.remove(selectedConnector?.connectorIndexToRemove);
              setDeleteConnectorModalOpen(false);
            } else {
              const payload = {
                sp_id: spId,
                cluster_resource_id: spClusterWorkloadData.cluster_resource_id,
                sp_version: spStreamingProjectData?.sp?.version,
                connector_id: connectorId,
              };

              const { error } = await deleteSPConnector({
                orgId,
                spId,
                clusterWorkloadId,
                connectorId,
                payload,
              });

              if (error) {
                toastError(error);
              } else {
                toastSuccess('Successfully deleted the connector!');
                setDeleteConnectorModalOpen(false);
              }
            }
          }}
        />
      )}
    </>
  );
};
