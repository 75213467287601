// @flow
import React, { useContext } from 'react';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';
import { SecondaryTopBar } from '@components/breadcrumbs/SecondaryTopBar';
import { getWorkloadBreadcrumbs } from '@streaming-projects/utils';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { StreamGovernanceWorkloadContext } from '@streaming-projects/workloads/stream-governance-workloads/contexts/StreamGovernanceWorkloadContext';

export const SPStreamGovernanceWorkloadBreadcrumbs = () => {
  const spOrgData = useContext(SPOrgContext);
  const spStreamingProjectData = useContext(SPStreamingProjectContext);
  const workloadData = useContext(StreamGovernanceWorkloadContext);
  const workloadName = workloadData?.name;
  return (
    <SecondaryTopBar
      sectionsToShow={getWorkloadBreadcrumbs(
        spOrgData,
        spStreamingProjectData,
        workloadName,
        'Stream Governance'
      )}
      showLastUpdatedMessage={false}
    />
  );
};
