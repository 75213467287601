/* eslint-disable react/jsx-handler-names */
// @flow
import React, { useContext } from 'react';
import { Table } from 'semantic-ui-react';
import { FieldArray, getIn, useFormikContext } from 'formik';
import { isEqual } from 'lodash';

import { YearSelectionContext } from '../YearSelectionContext';
import { CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG } from '../../../constants';
import { StyledLabel, StyledTable } from '../../../common-utils/styledComponents';
import { TableToCsvDownloader } from '../../presentational/TableToCsvDownloader';
import { ResetFormButton } from '../../presentational/buttons/ResetFormButton';
import { Spacer } from '../../presentational/spacing/Spacer';
import { SaveFormButton } from '../../presentational/buttons/SaveFormButton';
import { EstimateContext } from '../../../contexts/EstimateContext';

import { getDownloadColumns, getDownloadData, getRelevantMonthsBasedOnSelectedYear } from './utils';
import { ClusterInputTableRow } from './ClusterInputTableRow';
import { ClusterInputTableMonthHeaders } from './ClusterInputTableMonthHeaders';

export const ClusterInputTable = ({
  rowsConfig,
  tableName,
  updateFunc,
  keyNameInValues,
  includeTableDownload = true,
  includeSaveReset = true,
  disabled = false,
  connectorIndex = null,
  isNewElemAddedByUI = null,
  isReadOnly = false,
  AdditionalComponent = null,
  inferTotalNumberOfMonthsFromDataset = false,
  showYearNumberInMonthlyHeader = true,
  contextData = null,
  addCalendarMonthRowToDownload = false,
}) => {
  const { values, isValid, initialValues, resetForm, validateForm } = useFormikContext();
  const { totalYears, selectedYear, setSelectedYear } = useContext(YearSelectionContext);
  const estimate = useContext(EstimateContext);
  const data = getIn(values, keyNameInValues).months;
  let totalNumberOfMonths;

  if (inferTotalNumberOfMonthsFromDataset) {
    totalNumberOfMonths = Object.keys(data).length;
  } else {
    totalNumberOfMonths = estimate.deal_duration.deal_duration_months;
  }

  const months = getRelevantMonthsBasedOnSelectedYear(selectedYear, data);

  const dataInFormik = data;
  const downloadColumns = getDownloadColumns(dataInFormik);
  const dataToDownload = getDownloadData(
    rowsConfig,
    dataInFormik,
    values,
    contextData,
    addCalendarMonthRowToDownload
  );

  const shouldSaveButtonBeDisabled =
    !isValid ||
    disabled ||
    isEqual(getIn(initialValues, keyNameInValues), getIn(values, keyNameInValues));

  const shouldResetButtonBeDisabled =
    disabled || isEqual(getIn(initialValues, keyNameInValues), getIn(values, keyNameInValues));

  return (
    <>
      {includeTableDownload && (
        <StyledLabel style={{ float: 'right' }}>
          <>
            {tableName}
            <TableToCsvDownloader
              columns={downloadColumns}
              data={dataToDownload}
              title={`${tableName.split(' ').join('_')}_export`}
            />
          </>
        </StyledLabel>
      )}

      {!isReadOnly && includeSaveReset && (
        <span style={{ float: 'left' }}>
          <ResetFormButton
            disabled={shouldResetButtonBeDisabled || isNewElemAddedByUI}
            onClick={() => {
              let newValues;
              if (keyNameInValues.includes(CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG)) {
                const oldConnectors = getIn(
                  values,
                  CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG
                ).connectors;
                const newConnectors = oldConnectors.map((connector, index) => {
                  let returnValue = { ...connector };
                  if (connectorIndex === index) {
                    returnValue = getIn(initialValues, keyNameInValues);
                  }
                  return returnValue;
                });
                newValues = {
                  ...values,
                  [CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG]: { connectors: newConnectors },
                };
              } else {
                newValues = {
                  ...values,
                  [keyNameInValues]: getIn(initialValues, keyNameInValues),
                };
              }
              resetForm({ values: newValues });
            }}
          />
          <Spacer x={1} />

          <SaveFormButton
            disabled={shouldSaveButtonBeDisabled}
            onClick={async () => {
              // If there are any errors, then short circuit,
              // otherwise call the updateFunc provided by the parent
              const errors = await validateForm(values);
              if (Object.keys(errors).length > 0) {
                return;
              }
              updateFunc();
            }}
          />
        </span>
      )}

      {AdditionalComponent}

      <StyledTable celled={true} compact={true}>
        <ClusterInputTableMonthHeaders
          disabled={disabled}
          months={months}
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          showYearNumberInMonthlyHeader={showYearNumberInMonthlyHeader}
          totalYears={totalYears}
        />

        <FieldArray
          name={keyNameInValues}
          render={() => (
            <Table.Body>
              {rowsConfig.map((row) => {
                const shouldShowRow = !row.showOrHideFunc
                  ? true
                  : row.showOrHideFunc(values, contextData);
                return (
                  <>
                    {shouldShowRow && (
                      <ClusterInputTableRow
                        backendName={row.backendName}
                        disabled={disabled}
                        displayName={row.displayName}
                        isBooleanField={row.isBooleanField}
                        isReadOnly={isReadOnly}
                        key={row.displayName}
                        monthlyEstimatesFieldName={keyNameInValues}
                        months={months}
                        onChangeFunc={row.onChangeFunc}
                        selectedYear={selectedYear}
                        totalNumberOfMonths={totalNumberOfMonths}
                      />
                    )}
                  </>
                );
              })}
            </Table.Body>
          )}
        />
      </StyledTable>
    </>
  );
};

// todo::fix the performance issue which can be seen by holding up arrow on a number monthly field ( e.g., Average Read Throughput )
// todo::Remove the concept of onChangeFunc as it is not required anymore
// todo::Remove the concept of includeSaveReset as we are now having Save Reset for each connector too
