// @flow
import React from 'react';

export const ErrorListContainer = ({ errors }) => {
  return (
    <div>
      {errors.map((error, index) => (
        <p key={index}>{error.detail}</p>
      ))}
    </div>
  );
};
