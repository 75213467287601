// @flow
import React from 'react';
import { DeleteRenderer } from '@components/views/grid-utils/renderers';
import { SPStreamGovernanceResourceDeleteWarningModal } from '@streaming-projects/resource-definitions/stream-governance-resource-definitions/SPStreamGovernanceResourceDeleteWarningModal';
import { STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS } from '@streaming-projects/orgs/enums';

export const SPStreamGovernanceResourceDeleteRenderer = ({ data }) => {
  return (
    <DeleteRenderer>
      {(isDeleteModalOpen, setDeleteModalOpen) => {
        return (
          <SPStreamGovernanceResourceDeleteWarningModal
            isOpen={isDeleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            streamGovernanceResourceId={data[STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.ID]}
            streamGovernanceResourceName={
              data[STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.ENVIRONMENT_NAME]
            }
            version={data.Version}
          />
        );
      }}
    </DeleteRenderer>
  );
};
