// @flow
import { StyledHeader } from '@src/common-utils/styledComponents';
import { DataGrid } from '@components/views/grid-utils/DataGrid';
import React, { useContext } from 'react';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';
import { getSourceClustersForClusterLinking } from '@streaming-projects/resource-definitions/cluster-linking-resource-definitions/utils';
import { CLUSTER_LINKING_RESOURCES_GRID_FIELDNAMES } from '@streaming-projects/orgs/enums';

export const SourceClusterSelectionContainer = ({
  columnDefs,
  initialValues,
  onSelectionChanged,
}) => {
  const clusterResourceConfigurations =
    useContext(SPOrgContext)?.resources?.cluster_resources ?? [];

  const sourceClusters = getSourceClustersForClusterLinking(clusterResourceConfigurations);
  return (
    <>
      <StyledHeader>Select Source Cluster </StyledHeader>
      <DataGrid
        columnDefs={columnDefs}
        height={200}
        inputDataSource={sourceClusters}
        nodeSelectionFunc={(node) => {
          return (
            node.data['Cluster ID'] ===
            initialValues[CLUSTER_LINKING_RESOURCES_GRID_FIELDNAMES.SOURCE_CLUSTER_ID]
          );
        }}
        onSelectionChanged={onSelectionChanged}
        rowSelection="single"
        sizeColumnsToFitInitially={true}
        tooltipShowDelay={0}
      />
    </>
  );
};
