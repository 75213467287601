// @flow
import { AccordionsList } from '@presentational/accordions/AccordionsList';
import { getAccordianPanelDetailsObject } from '@src/common-utils/utils';
import React from 'react';

import SPDefaultCommitGenericDetails from './default-commit-generic-details/SPDefaultCommitGenericDetails';
import SPDefaultCommitValueDetails from './default-commit-value/SPDefaultCommitValueDetails';
import SPDefaultCommitSummaryContainer from './default-commit-pricing-summary/SPDefaultCommitSummaryContainer';

/**
 * Details page of a commit which is shown when the user clicks on a commit in the Org Commit Summary grid
 */
const SPDefaultCommitDetailsAccordions = () => {
  const accordionPanels = [
    getAccordianPanelDetailsObject('Commit Details', <SPDefaultCommitGenericDetails />),
    getAccordianPanelDetailsObject('Enter Commit Value', <SPDefaultCommitValueDetails />),
    getAccordianPanelDetailsObject('Commit Pricing Summary', <SPDefaultCommitSummaryContainer />),
  ];

  return (
    <AccordionsList
      initialIndexes={[0, 1]}
      panels={accordionPanels}
      showOnlyTitleInCollapsedState={true}
    />
  );
};

export default SPDefaultCommitDetailsAccordions;
