// @flow
import { useParams } from 'react-router-dom';
import React, { useCallback, useMemo } from 'react';
import { DataFetcherContainer } from '@presentational/DataFetcherContainer';
import { useGetSPOrgLevelViewQuery } from '@streaming-projects/service-definitions/streamingProjectsApi';

import { SPOrgContext } from './SPOrgContext';

export const SPOrgContextProvider = ({ children }) => {
  const { orgId } = useParams();

  return (
    <DataFetcherContainer
      dataFetchingArgs={useMemo(
        () => [
          { orgId },
          {
            skip: !orgId,
          },
        ],

        [orgId]
      )}
      dataFetchingFunction={useCallback(useGetSPOrgLevelViewQuery, [])}
    >
      {(data) => {
        return <SPOrgContext.Provider value={data}>{children}</SPOrgContext.Provider>;
      }}
    </DataFetcherContainer>
  );
};
