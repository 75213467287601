// @flow
import { Formik } from 'formik';
import React from 'react';
import { object } from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import {
  useAddStreamGovernanceResourceMutation,
  useUpdateStreamGovernanceResourceMutation,
} from '@streaming-projects/service-definitions/streamingProjectsApi';
import {
  STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES,
  STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS,
} from '@streaming-projects/orgs/enums';
import { getValidationSchemaFromColsConfig } from '@src/configuration/utils';
import { StyledContainer } from '@src/common-utils/styledComponents';
import { ConfirmModal } from '@presentational/modals/ConfirmModal';
import { Spacer } from '@presentational/spacing/Spacer';
import { toastError } from '@presentational/notifications/utils';
import { getLinkForOrganizationsPage } from '@streaming-projects/links';
import { SGResourceEnvironmentNameContainer } from '@streaming-projects/resource-definitions/stream-governance-resource-definitions/SGResourceEnvironmentNameContainer';
import { SGResourcePackageNameContainer } from '@streaming-projects/resource-definitions/stream-governance-resource-definitions/SGResourcePackageNameContainer';
import { SGResourceRegionNameContainer } from '@streaming-projects/resource-definitions/stream-governance-resource-definitions/SGResourceRegionNameContainer';
import { SGResourceProviderNameContainer } from '@streaming-projects/resource-definitions/stream-governance-resource-definitions/SGResourceProviderNameContainer';
import { STREAM_GOVERNANCE_RESOURCE_INPUTS_DETAILS_CONFIG } from '@streaming-projects/resource-definitions/stream-governance-resource-definitions/stream-governance-resource-inputs-config';
import { ResourceEditRepriceWarningContainer } from '@streaming-projects/resource-definitions/auxilary-components/ResourceEditRepriceWarningContainer';

const getAddAndUpdateParams = (orgId, initialValues, values) => {
  // Note, streamGovernanceResourceId and Version are only applicable in Editing Mode and hence the usage of "?."
  return {
    orgId,
    streamGovernanceResourceId: initialValues?.[STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.ID],
    payload: {
      org_id: orgId,
      name: values.name,
      package: values.package,
      inputs: {
        region: values.region,
        provider: values.provider,
      },
      version: initialValues?.Version,
    },
  };
};

export const SPStreamGovernanceConfigurationAddEditModal = ({
  isOpen,
  setOpen,
  isEditingMode = false,
  initialValues = null,
}) => {
  const { orgId } = useParams();
  const [addStreamGovernanceResource] = useAddStreamGovernanceResourceMutation();
  const [updateStreamGovernanceResource] = useUpdateStreamGovernanceResourceMutation();
  const { push } = useHistory();

  const initialValuesToUse = isEditingMode
    ? {
        [STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.ENVIRONMENT_NAME]:
          initialValues[STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.ENVIRONMENT_NAME],
        [STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.PACKAGE_NAME]:
          initialValues[STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.PACKAGE_NAME],
        [STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.PROVIDER]:
          initialValues[STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.PROVIDER],
        [STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.REGION]:
          initialValues[STREAM_GOVERNANCE_RESOURCES_GRID_HEADERS.REGION],
      }
    : {
        [STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.ENVIRONMENT_NAME]: null,
        [STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.PACKAGE_NAME]: null,
        [STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.PROVIDER]: null,
        [STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.REGION]: null,
      };

  const validationSchema = object({
    ...getValidationSchemaFromColsConfig(STREAM_GOVERNANCE_RESOURCE_INPUTS_DETAILS_CONFIG),
  });

  return (
    <StyledContainer>
      <Formik
        autoComplete="off"
        enableReinitialize={true}
        initialValues={initialValuesToUse}
        onSubmit={() => {}}
        validateOnMount={true}
        validationSchema={validationSchema}
      >
        {(addStreamGovernanceResourceFormik) => {
          return (
            <ConfirmModal
              body={
                <>
                  <Grid columns={1}>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <SGResourceEnvironmentNameContainer
                          disableOnFormErrors={false}
                          disabled={false}
                          fluid={true}
                        />
                      </Grid.Column>
                      <Grid.Column width={16}>
                        <SGResourcePackageNameContainer
                          disableOnFormErrors={false}
                          disabled={false}
                          fluid={true}
                        />
                      </Grid.Column>
                      <Grid.Column width={16}>
                        <SGResourceProviderNameContainer
                          disableOnFormErrors={false}
                          disabled={false}
                          fluid={true}
                        />
                      </Grid.Column>
                      <Grid.Column width={16}>
                        <SGResourceRegionNameContainer
                          disableOnFormErrors={false}
                          disabled={false}
                          fluid={true}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Spacer y={20} />
                  {isEditingMode && (
                    <ResourceEditRepriceWarningContainer resourceType="Stream Governance" />
                  )}
                </>
              }
              cancelButtonNegative={true}
              disabled={
                !addStreamGovernanceResourceFormik.isValid ||
                !addStreamGovernanceResourceFormik.dirty
              }
              header="Add New Stream Governace Resource Configuration"
              isOpen={isOpen}
              okButtonNegative={false}
              okButtonText="Continue"
              onClickHandlerForCancel={() => {
                addStreamGovernanceResourceFormik.resetForm();
                return setOpen(false);
              }}
              onClickHandlerForOK={async () => {
                let errorInCreateOrUpdate;
                if (!isEditingMode) {
                  const { error } = await addStreamGovernanceResource(
                    getAddAndUpdateParams(
                      orgId,
                      initialValues,
                      addStreamGovernanceResourceFormik.values
                    )
                  );
                  errorInCreateOrUpdate = error;
                } else {
                  const { error } = await updateStreamGovernanceResource(
                    getAddAndUpdateParams(
                      orgId,
                      initialValues,
                      addStreamGovernanceResourceFormik.values
                    )
                  );
                  errorInCreateOrUpdate = error;
                }

                if (errorInCreateOrUpdate) {
                  toastError(errorInCreateOrUpdate);
                } else {
                  push(getLinkForOrganizationsPage(orgId));
                }
                addStreamGovernanceResourceFormik.resetForm();
                setOpen(false);
              }}
            />
          );
        }}
      </Formik>
    </StyledContainer>
  );
};

// todo::SP when BE is ready with their changes, clean up this file to make it consistent with others
