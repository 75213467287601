// @flow
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getFormikFormInputsFromColConfigAndInputSource } from '@src/common-utils/utils';
import { Form } from '@src/formik-utils/formikSUIWrapper';
import { ValidateFormOnMount } from '@src/formik-utils/ValidateFormOnMount';
import FormListener from '@src/formik-utils/FormListener';
import { PromptIfFormHasUnSavedChanges } from '@presentational/PromptIfFormHasUnSavedChanges';
import { Spacer } from '@presentational/spacing/Spacer';
import { StyledContainer } from '@src/common-utils/styledComponents';
import { Formik } from 'formik';
import { CLUSTER_LINKING_WORKLOADS_INPUTS_FIELDNAMES } from '@streaming-projects/orgs/enums';
import { ClusterLinkingWorkloadContext } from '@streaming-projects/workloads/cluster-linking-workloads/contexts/ClusterLinkingWorkloadContext';
import { CLUSTER_LINKING_WORKLOAD_DETAILS_CONFIG } from '@streaming-projects/workloads/cluster-linking-workloads/config';
import { SPClusterLinkingWorkloadEnabledContainer } from '@streaming-projects/workloads/cluster-linking-workloads/enable/SPClusterLinkingWorkloadEnabledContainer';
import { SPClusterLinkingWorkloadDeleteContainer } from '@streaming-projects/workloads/cluster-linking-workloads/delete/SPClusterLinkingWorkloadDeleteContainer';
import { SPClusterLinkingWorkloadAccordionsContainer } from '@streaming-projects/workloads/cluster-linking-workloads/SPClusterLinkingWorkloadAccordionsContainer';
import { getSPClusterLinkingMonthlyInputsValidation } from '@streaming-projects/workloads/cluster-linking-workloads/utils';
import { useMetaDataContext } from '@streaming-projects/orgs/contexts/SPMetaDataContextProvider';

export const SPClusterLinkingWorkloadTopLevelContainerForm = () => {
  const { cluster_linking_threshold_configs: clusterLinkingThresholdConfigs } =
    useMetaDataContext().cluster_linking_configs;
  const workloadData = useContext(ClusterLinkingWorkloadContext);
  const { clusterLinkingWorkloadId } = useParams();

  const clusterLinkingWorkloadInputsSource = {
    id: clusterLinkingWorkloadId,
    name: workloadData.name,
    is_enabled: workloadData.is_enabled,
    [CLUSTER_LINKING_WORKLOADS_INPUTS_FIELDNAMES.MONTHLY_INPUTS]: workloadData.cluster_linking,
  };
  const {
    source_cluster_type: sourceClusterType,
    destination_cluster_type: destinationClusterType,
  } = workloadData.cluster_linking_resource_details;

  const numberOfMonths = workloadData.cluster_linking.inputs.length;

  const filteredClusterLinkingThresholdConfig = clusterLinkingThresholdConfigs.find(
    (clConfig) =>
      sourceClusterType === clConfig.source_cluster_type &&
      destinationClusterType === clConfig.destination_cluster_type
  );

  const maxLimitsObject = {
    avg_throughput_mbps: filteredClusterLinkingThresholdConfig.avg_throughput_threshold,
  };
  CLUSTER_LINKING_WORKLOAD_DETAILS_CONFIG.find(
    (x) => x.backendFieldName === CLUSTER_LINKING_WORKLOADS_INPUTS_FIELDNAMES.MONTHLY_INPUTS
  ).validation = getSPClusterLinkingMonthlyInputsValidation(numberOfMonths, maxLimitsObject);

  const { initialValues, initialTouched, validationSchema } =
    getFormikFormInputsFromColConfigAndInputSource(
      CLUSTER_LINKING_WORKLOAD_DETAILS_CONFIG,
      clusterLinkingWorkloadInputsSource
    );

  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialTouched={initialTouched}
        initialValues={initialValues}
        onSubmit={() => {}}
        validateOnBlur={true}
        validateOnChange={false}
        validateOnMount={true}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form autoComplete="off">
            <ValidateFormOnMount />
            <FormListener formik={formik} />
            <PromptIfFormHasUnSavedChanges />
            <SPClusterLinkingWorkloadEnabledContainer
              checked={workloadData.is_enabled}
              clusterLinkingResourceId={workloadData?.cluster_linking_resource_id}
              clusterLinkingWorkloadId={clusterLinkingWorkloadId}
            />
            <SPClusterLinkingWorkloadDeleteContainer />
            <Spacer y={10} />
            <SPClusterLinkingWorkloadAccordionsContainer />
          </Form>
        )}
      </Formik>
    </StyledContainer>
  );
};
