// @flow
import { DateField } from '@src/formik-utils/FormFields';
import { SP_DEFAULT_COMMIT_DETAILS_CONFIG_MAP } from '@streaming-projects/default-commit-details/config';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';
import React from 'react';

const SPDefaultCommitDealStartDateContainer = () => {
  const { backendFieldName, displayName, disabled } = SP_DEFAULT_COMMIT_DETAILS_CONFIG_MAP.get(
    BACKEND_FIELDNAMES.SP_START_DATE
  );

  return (
    <DateField
      disabled={disabled}
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      fluid={true}
    />
  );
};

export default SPDefaultCommitDealStartDateContainer;
