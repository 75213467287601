// @flow
import { array, bool, number, object, string } from 'yup';

import {
  CUSTOM_DISCOUNTS_GLOBAL_DISCOUNT_BACKEND_NAME,
  CUSTOM_DISCOUNTS_JSON_BACKEND_NAME,
  GLOBAL_DISCOUNT_BACKEND_NAME,
  IS_ROW_FROZEN,
  IS_ROW_OPENED_FOR_EDITING,
  METRICS_LEVEL_DISCOUNTS_CONFIG_ARRAY_BACKEND_NAME,
  METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_ARRAY_BACKEND_NAME,
  METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_DIMENSION_VALUES_BACKEND_NAME,
  METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_DISCOUNT_BACKEND_NAME,
  METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_METRIC_NAME_BACKEND_NAME,
  ROW_ID,
} from '../constants';
import { TEXT_NUMBER_INPUT_TYPE } from '../formik-utils/consts';
import {
  doDimensionValuesConflict,
  formatMLDIntoFrontEndFormat,
} from '../components/custom-discounts/utils';

import { convertConfigArrayToMap } from './utils';
import { spGlobalDiscountValidation, discountValidation } from './estimate-commit-details';

const extractDimValuesFromRow = (row, keys) => {
  const values = [];
  for (const key of keys) {
    values.push(row[key]);
  }
  return values;
};

// Note, this should NOT be converted to arrow function
const conflictsTest = (data, { createError, path, from }) => {
  const currRow = from[0].value;
  if (currRow?.isRowFrozen === true || currRow?.isRowOpenedForEditing === false) {
    return true;
  }

  const parentInputs = from[1].value;
  const allRows = parentInputs[METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_ARRAY_BACKEND_NAME];

  const inProgressRow = allRows.filter((x) => x[IS_ROW_FROZEN] === false)?.[0];

  if (!inProgressRow) {
    return true;
  }

  const allKeys = Object.keys(inProgressRow);
  const nonDimRelatedKeys = [
    IS_ROW_FROZEN,
    IS_ROW_OPENED_FOR_EDITING,
    METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_METRIC_NAME_BACKEND_NAME,
    ROW_ID,
    METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_DISCOUNT_BACKEND_NAME,
  ];

  const dimRelatedKeys = allKeys.filter((x) => !nonDimRelatedKeys.includes(x));
  const inProgressRowDimValues = extractDimValuesFromRow(inProgressRow, dimRelatedKeys);

  let conflictingRowNum = null;

  if (allRows.length > 1 && Object.values(inProgressRow).every((x) => x != null)) {
    for (let rowNumber = 0; rowNumber <= allRows.length - 2; rowNumber++) {
      const currRowDimValues = extractDimValuesFromRow(allRows[rowNumber], dimRelatedKeys);
      if (doDimensionValuesConflict(inProgressRowDimValues, currRowDimValues)) {
        conflictingRowNum = rowNumber;
        break;
      }
    }
  }

  if (conflictingRowNum != null) {
    return createError({
      message: `This row conflicts with row ${conflictingRowNum + 1} , please check!`,
      path: path,
    });
  }

  return true;
};

export const customDiscountsValidation = object({
  [GLOBAL_DISCOUNT_BACKEND_NAME]: discountValidation.notRequired(),
  [METRICS_LEVEL_DISCOUNTS_CONFIG_ARRAY_BACKEND_NAME]: array(
    object({
      [METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_METRIC_NAME_BACKEND_NAME]: string()
        .required()
        .label('Metric Name')
        .nullable()
        .typeError('Please select a metric!')
        .test(
          'notAllDimensionConfigItemsFrozen',
          'Please ensure all Dimension Config Items are frozen except possibly the last one',
          function () {
            return this.parent[METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_ARRAY_BACKEND_NAME].slice(
              0,
              -1
            ).every((dimensionConfigItem) => dimensionConfigItem[IS_ROW_FROZEN] === true);
          }
        ),
      [METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_ARRAY_BACKEND_NAME]: array(
        object({
          [METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_METRIC_NAME_BACKEND_NAME]: string().required(),
          [IS_ROW_FROZEN]: bool().required(),
          [METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_DIMENSION_VALUES_BACKEND_NAME]: array().of(
            string().required()
          ),
          [METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_DISCOUNT_BACKEND_NAME]: number()
            .nullable()
            .typeError('Discount is a required field!')
            .label('Discount')
            .min(0)
            .max(100)
            .required()
            .test('conflictsExist', 'Please ensure there are NO conflicts', conflictsTest),
        })
      ).min(1),
    })
  )
    .notRequired()
    .default([]),
});

export const CUSTOM_DISCOUNTS_JSON_CONFIG = {
  backendFieldName: CUSTOM_DISCOUNTS_JSON_BACKEND_NAME,
  inputTransformationFunc: (metricsDiscounts) => {
    return {
      [GLOBAL_DISCOUNT_BACKEND_NAME]: Number(metricsDiscounts?.global_discount ?? 0.0),
      [METRICS_LEVEL_DISCOUNTS_CONFIG_ARRAY_BACKEND_NAME]: formatMLDIntoFrontEndFormat(
        metricsDiscounts?.metrics ?? []
      ),
    };
  },
  validation: customDiscountsValidation,
};

const spCustomDiscountsValidation = object({
  ...customDiscountsValidation.fields,
  [GLOBAL_DISCOUNT_BACKEND_NAME]: spGlobalDiscountValidation.notRequired(),
});

export const SP_CUSTOM_DISCOUNTS_JSON_CONFIG = {
  ...CUSTOM_DISCOUNTS_JSON_CONFIG,
  validation: spCustomDiscountsValidation,
};

export const METRICS_LEVEL_DISCOUNTS_TOP_LEVEL_CONFIG_ARRAY_CONFIG = [
  {
    backendFieldName: METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_METRIC_NAME_BACKEND_NAME,
    hidden: false,
    displayName: 'Metric',
    columnWidth: 3,
    colDefaultValue: null,
  },
  {
    backendFieldName: METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_ARRAY_BACKEND_NAME,
    displayName: 'Dimensions Config',
    hidden: false,
    columnWidth: 3,
    colDefaultValue: [],
  },
];

export const METRICS_LEVEL_DISCOUNTS_TOP_LEVEL_CONFIG_ARRAY_CONFIG_MAP = convertConfigArrayToMap(
  METRICS_LEVEL_DISCOUNTS_TOP_LEVEL_CONFIG_ARRAY_CONFIG
);

export const CUSTOM_DISCOUNTS_GLOBAL_DISCOUNT_CONFIG = {
  backendFieldName: CUSTOM_DISCOUNTS_GLOBAL_DISCOUNT_BACKEND_NAME,
  displayName: 'Global Discount',
  inputType: TEXT_NUMBER_INPUT_TYPE,
  icon: 'percent',
};
