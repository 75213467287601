// @flow
import React from 'react';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';

import { useSPClusterWorkloadContext } from '../contexts/SPClusterWorkloadContextProvider';

import SPClusterResourceDetailsGrid from './SPClusterResourceDetailsGrid';

export const SPClusterWorkloadResourceDetailsContainer = () => {
  const spClusterWorkloadData = useSPClusterWorkloadContext();
  const spClusterWorkloadDetails = {
    name: spClusterWorkloadData.name,
    ...spClusterWorkloadData.cluster_resource_details,
  };
  return (
    <>
      <PaddedAndRaisedSegment>
        <SPClusterResourceDetailsGrid
          clusterWorkloadDetails={spClusterWorkloadDetails}
          disabled={true}
        />
      </PaddedAndRaisedSegment>
    </>
  );
};
