// @flow
import { DataFetcherContainer } from '@presentational/DataFetcherContainer';
import { shouldSkipDataFetching } from '@src/common-utils/utils';
import { useGetSPDefaultCommitDetailsQuery } from '@streaming-projects/service-definitions/streamingProjectsApi';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import SPDefaultCommitDetailsContext from './SPDefaultCommitDetailsContext';

const SPDefaultCommitDetailsContextProvider = ({ children }) => {
  const params = useParams();

  const dataFetchingArgs = useMemo(
    () => [{ ...params }, { skip: shouldSkipDataFetching(params) }],
    [params]
  );

  const dataFetchingFunction = useCallback(useGetSPDefaultCommitDetailsQuery, []);

  return (
    <DataFetcherContainer
      dataFetchingArgs={dataFetchingArgs}
      dataFetchingFunction={dataFetchingFunction}
    >
      {(data) => {
        const defaultCommitDetails = data.default_commit_views.commit;
        return (
          <SPDefaultCommitDetailsContext.Provider value={defaultCommitDetails}>
            {children}
          </SPDefaultCommitDetailsContext.Provider>
        );
      }}
    </DataFetcherContainer>
  );
};

export default SPDefaultCommitDetailsContextProvider;
