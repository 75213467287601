// @flow
import React, { useCallback, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';

import {
  FieldArrayGridConfigItemContext,
  FieldArrayGridSelectField,
} from '../../../../../../formik-utils/FieldArrayGrid';
import {
  METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_METRIC_NAME_BACKEND_NAME,
  RATE_CARD_BACKEND_NAME,
} from '../../../../../../constants';
import { METRICS_LEVEL_DISCOUNTS_TOP_LEVEL_CONFIG_ARRAY_CONFIG_MAP } from '../../../../../../configuration/custom-discounts';
import { DataFetcherContainer } from '../../../../../presentational/DataFetcherContainer';
import { getMetricNameOptions } from '../../../../../custom-discounts/utils';
import { useGetCustomizableMetricsListQuery } from '../../../../../../service-definitions/estimates';

export const SPMetricNameDropdownContainer = () => {
  const { orgId } = useParams();
  const orgData = useContext(SPOrgContext);
  const { allRows, rowValues } = useContext(FieldArrayGridConfigItemContext);

  const metricNameConfig = METRICS_LEVEL_DISCOUNTS_TOP_LEVEL_CONFIG_ARRAY_CONFIG_MAP.get(
    METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_METRIC_NAME_BACKEND_NAME
  );

  const rateCardVersion = orgData?.org[RATE_CARD_BACKEND_NAME];

  return (
    <DataFetcherContainer
      dataFetchingArgs={useMemo(
        () => [
          {
            rateCardVersion,
          },
          {
            skip: !orgId,
          },
        ],
        [orgId, rateCardVersion]
      )}
      dataFetchingFunction={useCallback(useGetCustomizableMetricsListQuery, [])}
    >
      {(metricsList) => {
        // todo::SP Check with BE team if this should go into BE
        const metricsListToUse = metricsList.metrics.filter((x) => x.is_customizable === true);

        return (
          <>
            <FieldArrayGridSelectField
              columnConfig={metricNameConfig}
              disableWhenSet={true}
              options={getMetricNameOptions(metricsListToUse, rowValues, allRows)}
            />
          </>
        );
      }}
    </DataFetcherContainer>
  );
};
