// @flow
import React from 'react';
import { CheckBoxField } from '@src/formik-utils/FormFields';
import { CLUSTER_WORKLOADS_INPUTS_FIELDNAMES } from '@streaming-projects/orgs/enums';

import { CLUSTER_WORKLOAD_DETAILS_CONFIG_MAP } from '../config';

export const SPClusterRetentionInfiniteContainer = ({ disabled, dealDurationInDays }) => {
  const { backendFieldName, displayName, icon } = CLUSTER_WORKLOAD_DETAILS_CONFIG_MAP.get(
    CLUSTER_WORKLOADS_INPUTS_FIELDNAMES.CLUSTER_RETENTION_INFINITE
  );
  return (
    <CheckBoxField
      disabled={disabled}
      displayCheckBoxBelowLabel={true}
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      icon={icon}
      onChange={(event, value, setFieldValue, setFieldTouched, values, checked) => {
        if (checked) {
          setFieldValue(CLUSTER_WORKLOADS_INPUTS_FIELDNAMES.CLUSTER_RETENTION, dealDurationInDays);
        }
      }}
    />
  );
};
