// @flow
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getFormikFormInputsFromColConfigAndInputSource } from '@src/common-utils/utils';
import { Form } from '@src/formik-utils/formikSUIWrapper';
import { ValidateFormOnMount } from '@src/formik-utils/ValidateFormOnMount';
import FormListener from '@src/formik-utils/FormListener';
import { PromptIfFormHasUnSavedChanges } from '@presentational/PromptIfFormHasUnSavedChanges';
import { Spacer } from '@presentational/spacing/Spacer';
import { StyledContainer } from '@src/common-utils/styledComponents';
import { Formik } from 'formik';
import { STREAM_GOVERNANCE_WORKLOADS_INPUTS_FIELDNAMES } from '@streaming-projects/orgs/enums';
import { StreamGovernanceWorkloadContext } from '@streaming-projects/workloads/stream-governance-workloads/contexts/StreamGovernanceWorkloadContext';
import { STREAM_GOVERNANCE_WORKLOAD_DETAILS_CONFIG } from '@streaming-projects/workloads/stream-governance-workloads/config';
import { SPStreamGovernanceWorkloadEnabledContainer } from '@streaming-projects/workloads/stream-governance-workloads/enable/SPStreamGovernanceWorkloadEnabledContainer';
import { SPStreamGovernanceWorkloadDeleteContainer } from '@streaming-projects/workloads/stream-governance-workloads/delete/SPStreamGovernanceWorkloadDeleteContainer';
import { SPStreamGovernanceWorkloadAccordionsContainer } from '@streaming-projects/workloads/stream-governance-workloads/SPStreamGovernanceWorkloadAccordionsContainer';
import { getSPStreamGovernanceMonthlyInputsValidation } from '@streaming-projects/workloads/stream-governance-workloads/utils';
import { useMetaDataContext } from '@streaming-projects/orgs/contexts/SPMetaDataContextProvider';

export const SPStreamGovernanceWorkloadTopLevelContainerForm = () => {
  const workloadData = useContext(StreamGovernanceWorkloadContext);
  const { streamGovernanceWorkloadId } = useParams();

  const streamGovernanceMaxLimitsAllowed = useMetaDataContext().stream_governance_configs;

  const inputsSource = {
    id: streamGovernanceWorkloadId,
    name: workloadData.name,
    is_enabled: workloadData.is_enabled,
    [STREAM_GOVERNANCE_WORKLOADS_INPUTS_FIELDNAMES.MONTHLY_INPUTS]: workloadData.stream_governance,
  };

  const numberOfMonths = workloadData.stream_governance.inputs.length;
  const maxLimitsObject = {
    schema_count: streamGovernanceMaxLimitsAllowed.schema_count_threshold,
    rules_count: streamGovernanceMaxLimitsAllowed.rules_count_threshold,
  };

  // Append the Dynamic Validations Required
  STREAM_GOVERNANCE_WORKLOAD_DETAILS_CONFIG.find(
    (x) => x.backendFieldName === STREAM_GOVERNANCE_WORKLOADS_INPUTS_FIELDNAMES.MONTHLY_INPUTS
  ).validation = getSPStreamGovernanceMonthlyInputsValidation(numberOfMonths, maxLimitsObject);

  const { initialValues, initialTouched, validationSchema } =
    getFormikFormInputsFromColConfigAndInputSource(
      STREAM_GOVERNANCE_WORKLOAD_DETAILS_CONFIG,
      inputsSource
    );

  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialTouched={initialTouched}
        initialValues={initialValues}
        onSubmit={() => {}}
        validateOnBlur={true}
        validateOnChange={false}
        validateOnMount={true}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form autoComplete="off">
            <ValidateFormOnMount />
            <FormListener formik={formik} />
            <PromptIfFormHasUnSavedChanges />
            <SPStreamGovernanceWorkloadEnabledContainer
              checked={workloadData.is_enabled}
              streamGovernanceResourceId={workloadData?.stream_governance_resource_id}
              streamGovernanceWorkloadId={streamGovernanceWorkloadId}
            />
            <SPStreamGovernanceWorkloadDeleteContainer />
            <Spacer y={10} />
            <SPStreamGovernanceWorkloadAccordionsContainer />
          </Form>
        )}
      </Formik>
    </StyledContainer>
  );
};
