// @flow
import React from 'react';
import { ShowLoaderIfAnyQueryIsPending } from '@presentational/ShowLoaderIfAnyQueryIsPending';
import { UserContextProvider } from '@src/contexts/UserContextProvider';
import { SPOrgContextProvider } from '@streaming-projects/orgs/contexts/SPOrgContextProvider';

import SPDefaultCommitDetailsBreadcrumbs from './SPDefaultCommitDetailsBreadcrumbs';
import SPDefaultCommitDetailsContextProvider from './context/SPDefaultCommitDetailsContextProvider';
import SPDefaultCommitDetailsTopLevelContainerForm from './SPDefaultCommitDetailsTopLevelContainerForm';

const SPDefaultCommitDetailsTopLevelContainer = () => {
  return (
    <>
      <ShowLoaderIfAnyQueryIsPending />
      <UserContextProvider>
        <SPOrgContextProvider>
          <SPDefaultCommitDetailsContextProvider>
            <SPDefaultCommitDetailsBreadcrumbs />
            <SPDefaultCommitDetailsTopLevelContainerForm />
          </SPDefaultCommitDetailsContextProvider>
        </SPOrgContextProvider>
      </UserContextProvider>
    </>
  );
};

export default SPDefaultCommitDetailsTopLevelContainer;
