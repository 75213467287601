// @flow
import React from 'react';
import { useParams } from 'react-router-dom';
import { LinkRenderer } from '@components/views/grid-utils/renderers';
import { getLinkForStreamingProjectsClusterWorkloadsPage } from '@streaming-projects/links';
import { FRONT_END_DISPLAY_NAMES } from '@streaming-projects/enums';

export const SPClusterWorkloadNameRenderer = ({ data }) => {
  const { orgId, spId } = useParams();
  return (
    <LinkRenderer
      pathName={getLinkForStreamingProjectsClusterWorkloadsPage(
        orgId,
        spId,
        data[FRONT_END_DISPLAY_NAMES.WORKLOAD_ID]
      )}
      text={data[FRONT_END_DISPLAY_NAMES.WORKLOAD_NAME]}
    />
  );
};
