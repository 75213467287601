// @flow
import React, { useState } from 'react';
import { AccordionsList } from '@presentational/accordions/AccordionsList';
import { YearSelectionContext } from '@components/cluster/YearSelectionContext';
import {
  getAccordianPanelDetailsObject,
  getNumberOfYearsBasedOnMonths,
} from '@src/common-utils/utils';

import { SPClusterWorkloadDetailsContainer } from './workload-details/SPClusterWorkloadDetailsContainer';
import { SPClusterWorkloadResourceDetailsContainer } from './resource-details/SPClusterWorkloadResourceDetailsContainer';
import { useSPClusterWorkloadContext } from './contexts/SPClusterWorkloadContextProvider';
import { SPClusterUsageTabsContainer } from './monthly-inputs/SPClusterUsageTabsContainer';

export const SPClusterWorkloadAccordionsContainer = () => {
  const spClusterWorkloadData = useSPClusterWorkloadContext();
  const totalYears = getNumberOfYearsBasedOnMonths(spClusterWorkloadData.throughput.inputs.length);
  const [selectedYear, setSelectedYear] = useState(1);

  const accordionPanels = [
    getAccordianPanelDetailsObject(
      'Workload Specific Details',
      <SPClusterWorkloadDetailsContainer />
    ),
    getAccordianPanelDetailsObject(
      'Cluster Resource Details',
      <SPClusterWorkloadResourceDetailsContainer />
    ),
    getAccordianPanelDetailsObject('SP Cluster Usage', <SPClusterUsageTabsContainer />),
  ];

  return (
    <YearSelectionContext.Provider
      value={{
        totalYears,
        selectedYear,
        setSelectedYear,
      }}
    >
      <AccordionsList panels={accordionPanels} />
    </YearSelectionContext.Provider>
  );
};
