// @flow
import React from 'react';
import { CLUSTER_WORKLOADS_INPUTS_FIELDNAMES } from '@streaming-projects/orgs/enums';
import { CheckBoxField } from '@src/formik-utils/FormFields';

import { CLUSTER_WORKLOAD_DETAILS_CONFIG_MAP } from '../config';

export const SPClusterFollowerFetchContainer = ({ disabled }) => {
  const { displayName, backendFieldName, icon } = CLUSTER_WORKLOAD_DETAILS_CONFIG_MAP.get(
    CLUSTER_WORKLOADS_INPUTS_FIELDNAMES.FOLLOWER_FETCH
  );
  return (
    <>
      <CheckBoxField
        disabled={disabled}
        displayCheckBoxBelowLabel={true}
        fieldDisplayName={displayName}
        fieldName={backendFieldName}
        icon={icon}
      />
    </>
  );
};
