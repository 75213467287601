// @flow
import React from 'react';
import { SPClusterResourceConfigurationsSummaryContainer } from '@streaming-projects/resource-definitions/cluster-resource-definitions/SPClusterResourceConfigurationsSummaryContainer';
import { AccordionsList } from '@presentational/accordions/AccordionsList';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { getAccordianPanelDetailsObject } from '@src/common-utils/utils';
import { SPStreamGovernanceResourcesSummaryContainer } from '@streaming-projects/resource-definitions/stream-governance-resource-definitions/SPStreamGovernanceResourcesSummaryContainer';
import { SPClusterLinkingResourceConfigurationsSummaryContainer } from '@streaming-projects/resource-definitions/cluster-linking-resource-definitions/SPClusterLinkingResourceConfigurationsSummaryContainer';

import { SPFlinkResourceConfigurationsSummaryContainer } from './flink-resource-definitions/SPFlinkResourceConfigurationsSummaryContainer';

export const SPResourceConfigurations = () => {
  const accordionPanels = [
    getAccordianPanelDetailsObject(
      'Cluster Shared Resource Configurations',
      <SPClusterResourceConfigurationsSummaryContainer />
    ),
    getAccordianPanelDetailsObject(
      'Flink Shared Resource Configurations',
      <SPFlinkResourceConfigurationsSummaryContainer />
    ),
    getAccordianPanelDetailsObject(
      'Stream Governance Resource Configurations',
      <SPStreamGovernanceResourcesSummaryContainer />
    ),

    getAccordianPanelDetailsObject(
      'Cluster Linking Resource Configurations',
      <SPClusterLinkingResourceConfigurationsSummaryContainer />
    ),
  ];

  return (
    <PaddedAndRaisedSegment>
      <AccordionsList
        initiallyAllCollapsed={true}
        panels={accordionPanels}
        showOnlyTitleInCollapsedState={true}
      />
    </PaddedAndRaisedSegment>
  );
};

export default SPResourceConfigurations;
