// @flow
import React from 'react';
import { ShowLoaderIfAnyQueryIsPending } from '@presentational/ShowLoaderIfAnyQueryIsPending';
import { UserContextProvider } from '@src/contexts/UserContextProvider';
import { SPStreamingProjectContextProvider } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContextProvider';
import { SPOrgContextProvider } from '@streaming-projects/orgs/contexts/SPOrgContextProvider';
import { SPMetaDataContextProvider } from '@streaming-projects/orgs/contexts/SPMetaDataContextProvider';

import { SPClusterWorkloadContextProvider } from './contexts/SPClusterWorkloadContextProvider';
import { SPClusterWorkloadBreadcrumbs } from './breadcrumbs/SPClusterWorkloadBreadcrumbs';
import { SPClusterWorkloadTopLevelContainerForm } from './SPClusterWorkloadTopLevelContainerForm';

export const SPClusterWorkloadTopLevelContainer = () => {
  return (
    <>
      <ShowLoaderIfAnyQueryIsPending />
      <UserContextProvider>
        <SPOrgContextProvider>
          <SPStreamingProjectContextProvider>
            <SPMetaDataContextProvider>
              <SPClusterWorkloadContextProvider>
                <SPClusterWorkloadBreadcrumbs />
                <SPClusterWorkloadTopLevelContainerForm />
              </SPClusterWorkloadContextProvider>
            </SPMetaDataContextProvider>
          </SPStreamingProjectContextProvider>
        </SPOrgContextProvider>
      </UserContextProvider>
    </>
  );
};
