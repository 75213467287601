// @flow
import { number, object, array } from 'yup';
import { getMonthName } from '@components/cluster/cluster-input-table/utils';

const getValidationSchemaObject = (maxLimitsObject) => {
  const keys = Object.keys(maxLimitsObject);
  return object(
    keys.reduce((acc, key) => {
      acc[key] = number()
        .required()
        .min(0)
        .max(maxLimitsObject[key])
        .label('This field')
        .typeError('Required Field');
      return acc;
    }, {})
  );
};

export const getMonthlyInputsValidationForType = (numberOfMonths, maxLimitsObject, inputType) => {
  const schemaObject = {};
  for (let i = 1; i <= numberOfMonths; i++) {
    schemaObject[getMonthName(i)] = getValidationSchemaObject(maxLimitsObject[inputType]);
  }
  return object({ months: object(schemaObject) });
};

export const connectorsMonthlyInputsValidationFunc = (
  numberOfMonths,
  maxLimitsObject,
  inputType
) => {
  const monthsSchema = getMonthlyInputsValidationForType(
    numberOfMonths,
    maxLimitsObject,
    inputType
  );

  return object({
    connectors: array(monthsSchema).notRequired().default([]),
  });
};
