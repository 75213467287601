// @flow
import React, { useContext } from 'react';
import { getIn, useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';
import { useUpdateClusterWorkloadMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG } from '@src/constants';
import { toastError } from '@presentational/notifications/utils';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { ClusterInputTable } from '@components/cluster/cluster-input-table/ClusterInputTable';

import { useSPClusterWorkloadContext } from '../contexts/SPClusterWorkloadContextProvider';

export const SPKSQLDBUsageContainer = ({ disabled }) => {
  const { orgId, spId, clusterWorkloadId } = useParams();
  const [updateClusterWorkload] = useUpdateClusterWorkloadMutation();
  const spStreamingProjectData = useContext(SPStreamingProjectContext);
  const spClusterWorkloadData = useSPClusterWorkloadContext();

  const rowsConfig = [
    {
      backendName: 'is_enabled',
      displayName: 'Active',
      isBooleanField: true,
    },
    {
      backendName: 'csu_count',
      displayName: 'CSUs',
    },
  ];

  const { values } = useFormikContext();

  return (
    <PaddedAndRaisedSegment disabled={disabled}>
      <ClusterInputTable
        addCalendarMonthRowToDownload={true}
        inferTotalNumberOfMonthsFromDataset={true}
        keyNameInValues={CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG}
        rowsConfig={rowsConfig}
        showYearNumberInMonthlyHeader={false}
        tableName="KSQLDB Monthly Usage"
        updateFunc={async () => {
          const { name } = values;
          const valuesToSendToBackend = getIn(values, CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG);
          const ksqlDBInputs = Object.values(valuesToSendToBackend.months);
          const ksqldb = {
            id: spClusterWorkloadData.ksqldb.id,
            inputs: ksqlDBInputs,
          };
          const payload = {
            id: clusterWorkloadId,
            sp_id: spId,
            cluster_resource_id: spClusterWorkloadData.cluster_resource_id,
            sp_version: spStreamingProjectData.sp.version,
            // todo::SP check if name is still needed for clusterWokload update api
            name,
            ksqldb,
          };
          const { error } = await updateClusterWorkload({
            orgId,
            spId,
            clusterWorkloadId,
            payload,
          });
          if (error) {
            toastError(error);
          }
        }}
      />
    </PaddedAndRaisedSegment>
  );
};
