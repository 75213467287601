// @flow
import { cloneDeep } from 'lodash';
import { STREAMING_PROJECTS_SP_DETAILS_CONFIG } from '@streaming-projects/sp-page/config';
import { BASELINE_SP_IDENTIFIER_NAME, FRONT_END_DISPLAY_NAMES } from '@streaming-projects/enums';

export const getSPDetailsFieldConfigByAddingBaselineNameValidation = () => {
  const fieldsConfig = cloneDeep(STREAMING_PROJECTS_SP_DETAILS_CONFIG);
  fieldsConfig[0].validation = fieldsConfig[0].validation.test(
    'is-not-baseline',
    `${FRONT_END_DISPLAY_NAMES.SP_NAME} cannot be Baseline`,
    (value) => {
      return !value || value.toLowerCase() !== BASELINE_SP_IDENTIFIER_NAME.toLowerCase();
    }
  );
  return fieldsConfig;
};
