// @flow
import React from 'react';
import { STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES } from '@streaming-projects/orgs/enums';
import { TextField } from '@src/formik-utils/FormFields';
import { STREAM_GOVERNANCE_RESOURCE_INPUTS_DETAILS_CONFIG_MAP } from '@streaming-projects/resource-definitions/stream-governance-resource-definitions/stream-governance-resource-inputs-config';

export const SGResourceEnvironmentNameContainer = ({
  disabled,
  disableOnFormErrors = null,
  fluid = null,
}) => {
  const { backendFieldName, displayName } =
    STREAM_GOVERNANCE_RESOURCE_INPUTS_DETAILS_CONFIG_MAP.get(
      STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.ENVIRONMENT_NAME
    );

  return (
    <TextField
      disableOnFormErrors={disableOnFormErrors}
      disabled={disabled}
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      fluid={fluid}
      placeholder={displayName}
    />
  );
};
