// @flow
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getIn, useFormikContext } from 'formik';
import {
  CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG,
  CONNECTOR_AVAILABLE_NETWORKING_TYPES_LIST_BACKEND_NAME,
  DEFAULT_VALUE_FOR_DB_ROW_ID_STR,
} from '@src/constants';
import { toastError } from '@presentational/notifications/utils';
import { ClusterInputTable } from '@components/cluster/cluster-input-table/ClusterInputTable';
import { Spacer } from '@presentational/spacing/Spacer';
import { StyledSegmentWithBorderColor } from '@src/common-utils/styledComponents';
import { SelectField } from '@src/formik-utils/FormFields';
import { getConnectorNameToRecordMap } from '@src/contexts/ClusterContextProvider';
import { useUpdateClusterWorkloadMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { getConnectorInputsFromConnectorValue } from '@components/cluster/cluster-input-table/utils';
import { SPMetaDataContext } from '@streaming-projects/orgs/contexts/SPMetaDataContext';
import { getConnectorNetworkingOptions } from '@components/cluster/cluster-monthly-inputs/ConnectorsMonthlyInputContainer';

import { CLUSTER_WORKLOAD_DETAILS_CONFIG_MAP } from '../../config';
import { useSPClusterWorkloadContext } from '../../contexts/SPClusterWorkloadContextProvider';

import { connectorsRowConfig } from './SPConnectorsTopLevelContainer';

export const SPConnectorsMonthlyInputContainer = ({
  connector,
  connectorIndex,
  disabled,
  keyToUse,
}) => {
  const { values } = useFormikContext();
  const { orgId, spId, clusterWorkloadId } = useParams();
  const [updateClusterWorkload] = useUpdateClusterWorkloadMutation();
  const spStreamingProjectData = useContext(SPStreamingProjectContext);
  const spClusterWorkloadData = useSPClusterWorkloadContext();

  const connectorsList = useContext(SPMetaDataContext).metaData.fullConnectorsList;
  const connectorNameToRecordsMap = getConnectorNameToRecordMap(connectorsList);

  const connectorLabel = connector.connectorLabel;
  const keyNameInValuesToUseForThisConnector = `${CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG}.connectors[${connectorIndex}]`;

  const connectorNetworkingTypeOptionsConfig = CLUSTER_WORKLOAD_DETAILS_CONFIG_MAP.get(
    CONNECTOR_AVAILABLE_NETWORKING_TYPES_LIST_BACKEND_NAME
  );

  const connectorName = connector.connectorName;
  const connectorNetworkingTypeOptions = getConnectorNetworkingOptions(
    connectorNameToRecordsMap,
    connectorName,
    //  add the missing input values from the cluster resource details
    { ...values, ...useSPClusterWorkloadContext().cluster_resource_details },
    connectorNetworkingTypeOptionsConfig
  );
  return (
    <>
      <ClusterInputTable
        AdditionalComponent={
          <>
            <Spacer y={40} />
            <StyledSegmentWithBorderColor compact={true}>
              <SelectField
                disabled={false}
                fieldDisplayName={connectorNetworkingTypeOptionsConfig.displayName}
                fieldName={`${keyNameInValuesToUseForThisConnector}.connectorNetworkingType`}
                fluid={false}
                options={connectorNetworkingTypeOptions}
                search={false}
              />
            </StyledSegmentWithBorderColor>
          </>
        }
        addCalendarMonthRowToDownload={true}
        connectorIndex={connectorIndex}
        disabled={disabled}
        includeSaveReset={true}
        includeTableDownload={true}
        inferTotalNumberOfMonthsFromDataset={true}
        isNewElemAddedByUI={connector.connectorId === DEFAULT_VALUE_FOR_DB_ROW_ID_STR}
        key={keyToUse}
        keyNameInValues={keyNameInValuesToUseForThisConnector}
        rowsConfig={connectorsRowConfig}
        showYearNumberInMonthlyHeader={false}
        tableName={`Monthly Usage for ${connectorLabel} Connector`}
        updateFunc={async () => {
          const { name } = values;
          const connectorValues = getIn(values, keyNameInValuesToUseForThisConnector);

          const payload = {
            id: clusterWorkloadId,
            sp_id: spId,
            cluster_resource_id: spClusterWorkloadData.cluster_resource_id,
            sp_version: spStreamingProjectData?.sp?.version,
            // todo::SP check if name is still needed for clusterWokload update api
            name,
            connectors: [getConnectorInputsFromConnectorValue(connectorValues)],
          };
          const { error } = await updateClusterWorkload({
            orgId,
            spId,
            clusterWorkloadId,
            payload,
          });

          if (error) {
            toastError(error);
          }
        }}
      />
    </>
  );
};
