// @flow
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';
import {
  useDisableClusterLinkingWorkloadMutation,
  useEnableClusterLinkingWorkloadMutation,
} from '@streaming-projects/service-definitions/streamingProjectsApi';
import { CLUSTER_LINKING_WORKLOADS_INPUTS_FIELDNAMES } from '@streaming-projects/orgs/enums';
import { CheckBoxField } from '@src/formik-utils/FormFields';
import { toastError } from '@presentational/notifications/utils';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { CLUSTER_LINKING_WORKLOAD_DETAILS_CONFIG_MAP } from '@streaming-projects/workloads/cluster-linking-workloads/config';

export const SPClusterLinkingWorkloadEnabledContainer = ({
  clusterLinkingWorkloadId = null,
  clusterLinkingResourceId = null,
  checked = null,
  addLabel = true,
}) => {
  const { orgId, spId } = useParams();
  const { isValid } = useFormikContext();
  const spStreamingProjectData = useContext(SPStreamingProjectContext);

  const [enableWorkload] = useEnableClusterLinkingWorkloadMutation();
  const [disableWorkload] = useDisableClusterLinkingWorkloadMutation();

  const { backendFieldName, displayName, icon } = CLUSTER_LINKING_WORKLOAD_DETAILS_CONFIG_MAP.get(
    CLUSTER_LINKING_WORKLOADS_INPUTS_FIELDNAMES.IS_ENABLED
  );

  const checkedProps = checked != null ? { checked } : {};
  return (
    <CheckBoxField
      addLabel={addLabel}
      disabled={!isValid}
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      icon={icon}
      onChange={async (event, value, setFieldValue, setFieldTouched, values, checked) => {
        const payload = {
          cluster_linking_resource_id: clusterLinkingResourceId,
          sp_version: spStreamingProjectData?.sp?.version,
        };

        const funcToCall = checked ? enableWorkload : disableWorkload;

        const { error } = await funcToCall({
          orgId,
          spId,
          clusterLinkingWorkloadId,
          payload,
        });

        if (error) {
          toastError(error);
        }
      }}
      {...checkedProps}
    />
  );
};
