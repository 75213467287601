// @flow
import React, { useContext } from 'react';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';
import { STREAMING_PROJECTS_SP_DETAILS_CONFIG_MAP } from '@streaming-projects/sp-page/config';
import { Tooltip } from '@presentational/Tooltip';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';

import { TextField } from '../../../../formik-utils/FormFields';

export const SPSPNameContainer = ({ disableOnFormErrors = null }) => {
  const { backendFieldName, displayName, icon } = STREAMING_PROJECTS_SP_DETAILS_CONFIG_MAP.get(
    BACKEND_FIELDNAMES.SP_NAME
  );
  const spSPData = useContext(SPStreamingProjectContext);
  const isBaselineSP = spSPData?.isBaseLineSP ?? false;
  const tooltip = isBaselineSP ? (
    <Tooltip flowing={true} text="Baseline SP Name cannot be changed." />
  ) : null;

  return (
    <TextField
      disableOnFormErrors={disableOnFormErrors}
      disabled={isBaselineSP}
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      fluid={true}
      icon={icon}
      tooltip={tooltip}
    />
  );
};
