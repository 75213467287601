// @flow
import { boolean, string, number } from 'yup';
import {
  CLUSTER_RESOURCES_GRID_FIELDNAMES,
  CLUSTER_WORKLOADS_INPUTS_DISPLAY_NAMES,
  CLUSTER_WORKLOADS_INPUTS_FIELDNAMES,
} from '@streaming-projects/orgs/enums';
import { convertConfigArrayToMap, createFormNameToFieldsMap } from '@src/configuration/utils';
import { TEXT_NUMBER_INPUT_TYPE, CHECKBOX_INPUT_TYPE } from '@src/formik-utils/consts';
import {
  clusterMonthlyDataInputTransformationFunc,
  connectorsMonthlyDataInputTransformationFunc,
} from '@components/cluster/cluster-input-table/utils';
import {
  CLUSTER_AVAILABLE_CONNECTORS_LIST,
  CLUSTER_AVAILABLE_CONNECTORS_LIST_BACKEND_NAME,
  CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG,
  CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG,
  CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG,
  CLUSTER_NETWORKING_TYPE_LABEL_BACKEND_NAME,
  CONNECTOR_AVAILABLE_NETWORKING_TYPES_LIST,
  CONNECTOR_AVAILABLE_NETWORKING_TYPES_LIST_BACKEND_NAME,
  CONNECTOR_LABEL,
} from '@src/constants';

import { CLUSTER_WORKLOAD_DETAILS_FORM, SP_MAX_INPUT_VALUE_FOR_ANY_FIELD } from './constants';

export const CLUSTER_WORKLOAD_ENABLE_DISABLE_CONFIG = {
  displayName: CLUSTER_WORKLOADS_INPUTS_DISPLAY_NAMES.IS_ENABLED,
  backendFieldName: CLUSTER_WORKLOADS_INPUTS_FIELDNAMES.IS_ENABLED,
  validation: boolean().label(CLUSTER_WORKLOADS_INPUTS_DISPLAY_NAMES.IS_ENABLED).required(),
};

export const CLUSTER_WORKLOAD_DETAILS_INPUT_CONFIG = [
  {
    displayName: CLUSTER_WORKLOADS_INPUTS_DISPLAY_NAMES.WORKLOAD_NAME,
    backendFieldName: CLUSTER_WORKLOADS_INPUTS_FIELDNAMES.WORKLOAD_NAME,
    validation: string().label(CLUSTER_WORKLOADS_INPUTS_DISPLAY_NAMES.WORKLOAD_NAME).required(),
  },
  {
    displayName: CLUSTER_WORKLOADS_INPUTS_DISPLAY_NAMES.CLUSTER_RETENTION,
    backendFieldName: CLUSTER_WORKLOADS_INPUTS_FIELDNAMES.CLUSTER_RETENTION,
    icon: null,
    validation: ({ dealDuration }) => {
      const dealLengthInDays = dealDuration.deal_duration_days;
      return number()
        .min(0)
        .label('Retention Days')
        .required()
        .max(dealLengthInDays)
        .typeError('You must specify a number');
    },
    inputType: TEXT_NUMBER_INPUT_TYPE,
    belongingForm: CLUSTER_WORKLOAD_DETAILS_FORM,
  },
  {
    displayName: CLUSTER_WORKLOADS_INPUTS_DISPLAY_NAMES.CLUSTER_RETENTION_INFINITE,
    backendFieldName: CLUSTER_WORKLOADS_INPUTS_FIELDNAMES.CLUSTER_RETENTION_INFINITE,
    icon: null,
    inputType: CHECKBOX_INPUT_TYPE,
    belongingForm: CLUSTER_WORKLOAD_DETAILS_FORM,
  },
  {
    displayName: CLUSTER_WORKLOADS_INPUTS_DISPLAY_NAMES.FOLLOWER_FETCH,
    backendFieldName: CLUSTER_WORKLOADS_INPUTS_FIELDNAMES.FOLLOWER_FETCH,
    icon: null,
    inputType: CHECKBOX_INPUT_TYPE,
    belongingForm: CLUSTER_WORKLOAD_DETAILS_FORM,
  },
  {
    displayName: CLUSTER_WORKLOADS_INPUTS_DISPLAY_NAMES.EXISTING_STORAGE,
    backendFieldName: CLUSTER_WORKLOADS_INPUTS_FIELDNAMES.EXISTING_STORAGE,
    icon: null,
    validation: number()
      .min(0)
      .max(SP_MAX_INPUT_VALUE_FOR_ANY_FIELD)
      .label(CLUSTER_WORKLOADS_INPUTS_DISPLAY_NAMES.EXISTING_STORAGE)
      .required()
      .typeError('You must specify a number'),
    inputType: TEXT_NUMBER_INPUT_TYPE,
    belongingForm: CLUSTER_WORKLOAD_DETAILS_FORM,
  },
  {
    displayName: CLUSTER_WORKLOADS_INPUTS_DISPLAY_NAMES.CKU,
    backendFieldName: CLUSTER_WORKLOADS_INPUTS_FIELDNAMES.CKU,
    icon: null,
    validation: number()
      .min(0)
      .max(SP_MAX_INPUT_VALUE_FOR_ANY_FIELD)
      .label(CLUSTER_WORKLOADS_INPUTS_DISPLAY_NAMES.CKU)
      .required()
      .typeError('You must specify a number'),
    inputType: TEXT_NUMBER_INPUT_TYPE,
    belongingForm: CLUSTER_WORKLOAD_DETAILS_FORM,
  },
];

export const CLUSTER_WORKLOAD_MONTHLY_INPUTS_CONFIG = [
  {
    displayName: CLUSTER_WORKLOADS_INPUTS_DISPLAY_NAMES.MONTHLY_INPUTS,
    backendFieldName: CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG,
    belongingForm: CLUSTER_WORKLOAD_DETAILS_FORM,
    inputTransformationFunc: clusterMonthlyDataInputTransformationFunc,
  },
  {
    displayName: CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG,
    backendFieldName: CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG,
    belongingForm: CLUSTER_WORKLOAD_DETAILS_FORM,
    inputTransformationFunc: clusterMonthlyDataInputTransformationFunc,
  },
  {
    displayName: CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG,
    backendFieldName: CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG,
    belongingForm: CLUSTER_WORKLOAD_DETAILS_FORM,
    inputTransformationFunc: connectorsMonthlyDataInputTransformationFunc,
  },
  {
    displayName: CLUSTER_AVAILABLE_CONNECTORS_LIST,
    backendFieldName: CLUSTER_AVAILABLE_CONNECTORS_LIST_BACKEND_NAME,
    displayNameCol: CONNECTOR_LABEL,
    belongingForm: CLUSTER_WORKLOAD_DETAILS_FORM,
    parentFieldsInGroup: [
      CLUSTER_RESOURCES_GRID_FIELDNAMES.CLUSTER_TYPE,
      CLUSTER_RESOURCES_GRID_FIELDNAMES.PROVIDER,
      CLUSTER_RESOURCES_GRID_FIELDNAMES.NETWORKING_TYPE,
    ],
  },
  {
    displayName: CONNECTOR_AVAILABLE_NETWORKING_TYPES_LIST,
    backendFieldName: CONNECTOR_AVAILABLE_NETWORKING_TYPES_LIST_BACKEND_NAME,
    displayNameCol: CLUSTER_NETWORKING_TYPE_LABEL_BACKEND_NAME,
    belongingForm: CLUSTER_WORKLOAD_DETAILS_FORM,
    parentFieldsInGroup: [
      CLUSTER_RESOURCES_GRID_FIELDNAMES.CLUSTER_TYPE,
      CLUSTER_RESOURCES_GRID_FIELDNAMES.PROVIDER,
    ],
  },
];

export const CLUSTER_WORKLOAD_DETAILS_CONFIG = [
  CLUSTER_WORKLOAD_ENABLE_DISABLE_CONFIG,
  ...CLUSTER_WORKLOAD_DETAILS_INPUT_CONFIG,
  ...CLUSTER_WORKLOAD_MONTHLY_INPUTS_CONFIG,
];

export const CLUSTER_WORKLOAD_DETAILS_CONFIG_MAP = convertConfigArrayToMap(
  CLUSTER_WORKLOAD_DETAILS_CONFIG
);

export const CLUSTER_WORKLOAD_DETAILS_FORM_NAMES_TO_FIELDS_MAP = createFormNameToFieldsMap(
  CLUSTER_WORKLOAD_DETAILS_CONFIG
);
