// @flow
import React, { useContext } from 'react';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { ClusterInputTable } from '@components/cluster/cluster-input-table/ClusterInputTable';
import { FLINK_WORKLOADS_INPUTS_FIELDNAMES } from '@streaming-projects/orgs/enums';
import { getIn, useFormikContext } from 'formik';
import { toastError } from '@presentational/notifications/utils';
import { useParams } from 'react-router-dom';
import { useUpdateFlinkWorkloadMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { FlinkPoolWorkloadContext } from '@streaming-projects/workloads/flink-workloads/contexts/FlinkPoolWorkloadContext';

export const SPFlinkWorkloadMonthlyUsageContainer = () => {
  const { orgId, spId, flinkWorkloadId } = useParams();
  const { values } = useFormikContext();
  const [updateFlinkWorkload] = useUpdateFlinkWorkloadMutation();
  const spStreamingProjectData = useContext(SPStreamingProjectContext);
  const spFlinkPoolWorkloadData = useContext(FlinkPoolWorkloadContext);

  const rowsConfig = [
    {
      backendName: 'is_enabled',
      displayName: 'Active',
      isBooleanField: true,
    },
    {
      backendName: 'cfu_count',
      displayName: 'CFUs',
    },
  ];

  return (
    <PaddedAndRaisedSegment>
      <ClusterInputTable
        addCalendarMonthRowToDownload={true}
        inferTotalNumberOfMonthsFromDataset={true}
        keyNameInValues={FLINK_WORKLOADS_INPUTS_FIELDNAMES.MONTHLY_INPUTS}
        rowsConfig={rowsConfig}
        showYearNumberInMonthlyHeader={false}
        tableName="Flink Workload Monthly Usage"
        updateFunc={async () => {
          const valuesToSendToBackend = getIn(
            values,
            FLINK_WORKLOADS_INPUTS_FIELDNAMES.MONTHLY_INPUTS
          );
          const flinkPoolInputs = Object.values(valuesToSendToBackend.months);
          const payload = {
            flink_pool_config_id: spFlinkPoolWorkloadData?.flink_pool_config_id,
            name: spFlinkPoolWorkloadData?.name,
            sp_version: spStreamingProjectData?.sp?.version,
            flink_throughput: {
              id: spFlinkPoolWorkloadData.flink_throughput.id,
              inputs: flinkPoolInputs,
            },
          };
          const { error } = await updateFlinkWorkload({
            orgId,
            spId,
            flinkWorkloadId,
            payload,
          });
          if (error) {
            toastError(error);
          }
        }}
      />
    </PaddedAndRaisedSegment>
  );
};
