// @flow
import React from 'react';
import { isEmpty } from 'lodash';
import { array, boolean, number, object, string } from 'yup';
import { toastError } from '@presentational/notifications/utils';
import { Spacer } from '@presentational/spacing/Spacer';
import { convertToISODateString } from '@src/common-utils/utils';

// todo::SP This validation schema should be in their own files and be used here
// If that is not possible, at least we should use constants
// Define the validation schema for throughput
const createThroughputSchema = (numberOfMonths) =>
  object({
    inputs: array()
      .of(
        object({
          is_enabled: boolean().required(`is_enabled is required`),
          partition_count: number().integer().required().min(0),
          connected_client_count: number().integer().required().min(0),
          client_requests_per_sec: number().integer().required().min(0),
          read_throughput_peak_mbps: number().required().min(0),
          write_throughput_peak_mbps: number().required().min(0),
          read_throughput_avg_mbps: number().required().min(0),
          write_throughput_avg_mbps: number().required().min(0),
          client_connection_attempts_per_sec: number().integer().required().min(0),
        })
          .noUnknown(true)
          .strict()
      )
      .length(numberOfMonths, `inputs must have exactly ${numberOfMonths} elements`),
  })
    .required('throughput inputs are required')
    .noUnknown(true)
    .strict();

// Define the validation schema for ksqldb
const createKsqlDbSchema = (numberOfMonths) =>
  object({
    inputs: array()
      .of(
        object({
          is_enabled: boolean().required(`is_enabled is required`),
          csu_count: number().integer().required().min(0),
        })
          .noUnknown(true)
          .strict()
      )
      .length(numberOfMonths, `inputs must have exactly ${numberOfMonths} elements`),
  })
    .required('ksqldb inputs are required')
    .noUnknown(true)
    .strict();

// Define the validation schema for connectors
const createConnectorsSchema = (numberOfMonths) =>
  array().of(
    object({
      name: string().required('name is required'),
      input_details: object({
        networking_type: string().required('networking_type is required'),
      })
        .required('input_details are required')
        .noUnknown(true)
        .strict(),
      label: string().required('label is required'),
      inputs: array()
        .of(
          object({
            is_enabled: boolean().required(`is_enabled is required`),
            task_count: number().integer().min(0),
            throughput_average_mbps: number().min(0),
          })
            .noUnknown(true)
            .strict()
        )
        .length(numberOfMonths, `inputs must have exactly ${numberOfMonths} elements`),
    })
  );
export const getValidationSchemaBasedOnMonths = (numberOfMonths) => {
  return object({
    clusterWorkLoads: array().of(
      object({
        resourceDetails: object({
          name: string().required('name is required'),
          cluster_type: string().required('cluster_type is required'),
          networking_type: string().required('networking_type is required'),
          provider: string().required('provider is required'),
          region: string().required('region is required'),
          az_configuration: string().required('az_configuration is required'),
          sla: number().required('sla is required'),
        })
          .required('resourceDetails are required')
          .noUnknown(true)
          .strict(),
        workLoadDetails: object({
          name: string().required('Workload Name is required'),
          inputs: object({
            is_follower_fetch_enabled: boolean().required('followerFetchEnabled is required'),
            retention: object({
              retention_days: number().required('retention is required'),
              is_finite_retention: boolean().required('infiniteRetention is required'),
            }),
          }),
          metadata: object({
            cku: number().required('CKU is required'),
            existing_storage: number().required('ExistingStorage is required'),
          }),
          throughput: createThroughputSchema(numberOfMonths),
          ksqldb: createKsqlDbSchema(numberOfMonths),
          connectors: createConnectorsSchema(numberOfMonths),
        })
          .required('workLoadDetails are required')
          .noUnknown(true)
          .strict(),
        clusterLinkingWorkloadDetails: object({
          resourceName: string().required('resourceName is required'),
          name: string().required('name is required'),
          inputs: object({
            inputs: array()
              .of(
                object({
                  is_enabled: boolean().required('is_enabled is required'),
                  avg_throughput_mbps: number().min(0).required('avg_throughput_mbps is required'),
                })
                  .noUnknown(true)
                  .strict()
              )
              .required('inputs are required')
              .length(numberOfMonths, `inputs must have exactly ${numberOfMonths} elements`),
          }).required('inputs are required'),
        })
          .nullable()
          .strict()
          .noUnknown(true),
      })
        .strict()
        .noUnknown(true)
    ),
    flinkWorkLoads: array().of(
      object({
        resourceDetails: object({
          name: string().required('name is required'),
          provider: string().required('provider is required'),
          region: string().required('region is required'),
        }).required('resourceDetails are required'),
        workLoadDetails: object({
          name: string().required('workloadName is required'),
          flink_throughput: object({
            inputs: array()
              .of(
                object({
                  is_enabled: boolean().required(`is_enabled is required`),
                  cfu_count: number().integer().min(0).max(50).required(`cfu_count is required`),
                })
                  .noUnknown(true)
                  .strict()
              )
              .required('flink inputs are required')
              .length(numberOfMonths, `inputs must have exactly ${numberOfMonths} elements`),
          }).required('flink_throughput is required'),
        }).required('workLoadDetails are required'),
      })
    ),
    streamGovernanceWorkloads: array().of(
      object({
        resourceDetails: object({
          name: string().required('name is required'),
          package: string().required('package is required'),
          inputs: object({
            region: string().required('region is required'),
            provider: string().required('provider is required'),
          }).required('inputs are required'),
        }).required('resourceDetails are required'),
        workLoadDetails: object({
          name: string().required('workloadName is required'),
          stream_governance_schema_details: object({
            inputs: array()
              .of(
                object({
                  is_enabled: boolean().required('is_enabled is required'),
                  schema_count: number().integer().min(0).required('schema_count is required'),
                  rules_count: number().integer().min(0).required('rules_count is required'),
                })
                  .noUnknown(true)
                  .strict()
              )
              .required('stream governance inputs are required')
              .length(numberOfMonths, `inputs must have exactly ${numberOfMonths} elements`),
          }).required('stream_governance_schema_details are required'),
        }).required('workLoadDetails are required'),
      })
    ),
  });
};

const validateData = async (data, validationSchema) => {
  try {
    // eslint-disable-next-line no-unused-vars
    await validationSchema.validate(data, {
      abortEarly: true,
      strict: true,
    });
    return true;
  } catch (err) {
    toastError(
      null,
      <div>
        Found this error in the uploaded file.
        <Spacer y={20} />
        Path: {err.path}
        <Spacer y={20} />
        Error: {err.message}
        <Spacer y={20} />
        Fix and try again!
        <Spacer y={20} />
      </div>,
      'top-left',
      false,
      true
    );
    return false;
  }
};

const addDataReplicationAndMonthBasedOnIndex = (inputs, numberOfMonths) => {
  if (!numberOfMonths && numberOfMonths <= 0) {
    throw 'Number of months should be greater than ZERO and should be provided';
  }

  const firstMonthsData = inputs[0];

  // Replicate the data for the other months
  for (let i = 1; i <= numberOfMonths - 1; i++) {
    inputs.push({ ...firstMonthsData });
  }

  // Add proper month Index
  inputs.forEach((item, index) => {
    item.month = index + 1;
  });
};

const createFlinkResourcesAndWorkloads = async (
  jsonData,
  addFlinkPoolResource,
  orgId,
  addFlinkWorkload,
  baselineIDCreated,
  startDate,
  endDate,
  spCurrentVersion,
  numberOfMonths
) => {
  if (!jsonData.flinkWorkLoads) {
    return spCurrentVersion;
  }

  let spCurrentVersionLocal = spCurrentVersion;
  for (const flinkWorkLoad of jsonData.flinkWorkLoads) {
    const { resourceDetails, workLoadDetails } = flinkWorkLoad;
    const { error, data } = await addFlinkPoolResource({
      orgId,
      payload: {
        org_id: orgId,
        name: `BQ_${resourceDetails.name}`,
        inputs: {
          ...resourceDetails,
        },
      },
    });
    if (error) {
      toastError(error);
      return null;
    }

    addDataReplicationAndMonthBasedOnIndex(workLoadDetails.flink_throughput.inputs, numberOfMonths);

    const { error: errorWorkload, data: dataForWorkload } = await addFlinkWorkload({
      orgId,
      spId: baselineIDCreated,
      payload: {
        org_id: orgId,
        sp_id: baselineIDCreated,
        name: `BQ_WL_${workLoadDetails.name}`,
        flink_pool_config_id: data.flink_pool_resource_id,
        sp_start_date: startDate,
        sp_end_date: endDate,
        sp_version: spCurrentVersionLocal,
        flink_throughput: workLoadDetails.flink_throughput,
      },
    });

    if (errorWorkload) {
      toastError(errorWorkload);
      return null;
    }

    spCurrentVersionLocal = dataForWorkload.sp_version;
  }
  return spCurrentVersionLocal;
};

const createStreamGovernanceResourcesAndWorkloads = async (
  jsonData,
  addStreamGovernanceResource,
  orgId,
  addStreamGovernanceWorkload,
  baselineIDCreated,
  startDate,
  endDate,
  spCurrentVersion,
  numberOfMonths
) => {
  if (!jsonData.streamGovernanceWorkloads) {
    return spCurrentVersion;
  }

  let spCurrentVersionLocal = spCurrentVersion;
  for (const sgWorkLoad of jsonData.streamGovernanceWorkloads) {
    const { resourceDetails, workLoadDetails } = sgWorkLoad;
    const { error, data } = await addStreamGovernanceResource({
      orgId,
      payload: {
        org_id: orgId,
        ...resourceDetails,
        name: `BQ_${resourceDetails.name}`,
      },
    });

    if (error) {
      toastError(error);
      return null;
    }

    addDataReplicationAndMonthBasedOnIndex(
      workLoadDetails.stream_governance_schema_details.inputs,
      numberOfMonths
    );

    const { error: errorWorkload, data: dataForWorkload } = await addStreamGovernanceWorkload({
      orgId,
      spId: baselineIDCreated,
      payload: {
        org_id: orgId,
        sp_id: baselineIDCreated,
        name: `BQ_WL_${workLoadDetails.name}`,
        stream_governance_resource_id: data.id,
        sp_start_date: startDate,
        sp_end_date: endDate,
        sp_version: spCurrentVersionLocal,
        stream_governance_schema_details: workLoadDetails.stream_governance_schema_details,
      },
    });

    if (errorWorkload) {
      toastError(errorWorkload);
      return null;
    }

    spCurrentVersionLocal = dataForWorkload.sp_version;
  }
  return spCurrentVersionLocal;
};

const createClusterLinkingResourcesAndWorkloads = async (
  clusterLinkingWorkloadDetails,
  addClusterLinkingResource,
  orgId,
  clusterResourceData,
  addClusterLinkingWorkload,
  baselineIDCreated,
  startDate,
  endDate,
  spCurrentVersion,
  numberOfMonths,
  clusterResourceDetails
) => {
  let spCurrentVersionLocal = spCurrentVersion;
  if (clusterLinkingWorkloadDetails && !isEmpty(clusterLinkingWorkloadDetails)) {
    const { error: errorCLResource, data: dataCLResource } = await addClusterLinkingResource({
      orgId,
      payload: {
        org_id: orgId,
        name: `BQ_${clusterLinkingWorkloadDetails.resourceName}`,
        source_cluster_resource_id:
          clusterResourceDetails.cluster_type === 'dedicated'
            ? 'CL-RES-00000000-0000-0000-0000-000000000000'
            : clusterResourceData.id,
        destination_cluster_resource_id:
          clusterResourceDetails.cluster_type === 'dedicated'
            ? clusterResourceData.id
            : 'CL-RES-00000000-0000-0000-0000-000000000000',
      },
    });

    if (errorCLResource) {
      toastError(errorCLResource);
      return null;
    }

    // Now, we have CL Resource, next step is to create its Workload
    addDataReplicationAndMonthBasedOnIndex(
      clusterLinkingWorkloadDetails.inputs.inputs,
      numberOfMonths
    );

    const { error: errorCLWorkload, data: dataForCLWorkload } = await addClusterLinkingWorkload({
      orgId,
      spId: baselineIDCreated,
      payload: {
        org_id: orgId,
        sp_id: baselineIDCreated,
        cluster_linking_resource_id: dataCLResource.id,
        sp_start_date: startDate,
        sp_end_date: endDate,
        sp_version: spCurrentVersionLocal,
        ...clusterLinkingWorkloadDetails,
        name: `BQ_WL_${clusterLinkingWorkloadDetails.name}`,
      },
    });

    if (errorCLWorkload) {
      toastError(errorCLWorkload);
      return null;
    }

    spCurrentVersionLocal = dataForCLWorkload.sp_version;
  }
  return spCurrentVersionLocal;
};

const createClusterResourcesAndWorkloads = async (
  jsonData,
  addClusterResource,
  orgId,
  addClusterWorkload,
  baselineIDCreated,
  startDate,
  endDate,
  spCurrentVersion,
  addClusterLinkingResource,
  addClusterLinkingWorkload,
  numberOfMonths
) => {
  if (!jsonData.clusterWorkLoads) {
    return spCurrentVersion;
  }

  let spCurrentVersionLocal = spCurrentVersion;
  for (const clusterWorkLoad of jsonData.clusterWorkLoads) {
    const { resourceDetails, workLoadDetails, clusterLinkingWorkloadDetails } = clusterWorkLoad;

    addDataReplicationAndMonthBasedOnIndex(workLoadDetails.throughput.inputs, numberOfMonths);
    addDataReplicationAndMonthBasedOnIndex(workLoadDetails.ksqldb.inputs, numberOfMonths);

    // Process connectors - Note: Connectors is an optional field
    (workLoadDetails?.connectors ?? []).forEach((connector) => {
      if (connector.inputs) {
        addDataReplicationAndMonthBasedOnIndex(connector.inputs, numberOfMonths);
      }
    });

    // First, add the given cluster resource
    const { error, data } = await addClusterResource({
      orgId,
      payload: {
        org_id: orgId,
        name: `BQ_${resourceDetails.name}`,
        inputs: {
          ...resourceDetails,
          existing_storage: 0,
          is_follower_fetch_enabled: false,
        },
      },
    });
    if (error) {
      toastError(error);
      return null;
    }

    // Second, add the cluster workload
    const { error: errorWorkload, data: dataForWorkload } = await addClusterWorkload({
      orgId,
      spId: baselineIDCreated,
      payload: {
        org_id: orgId,
        sp_id: baselineIDCreated,
        cluster_resource_id: data.id,
        sp_start_date: startDate,
        sp_end_date: endDate,
        sp_version: spCurrentVersionLocal,
        ...workLoadDetails,
        name: `BQ_WL_${workLoadDetails.name}`,
      },
    });

    if (errorWorkload) {
      toastError(errorWorkload);
      return null;
    }

    spCurrentVersionLocal = dataForWorkload.sp_version;

    // // Third, if any Cluster Linking Workload is defined, add  its resource and workload
    spCurrentVersionLocal = await createClusterLinkingResourcesAndWorkloads(
      clusterLinkingWorkloadDetails,
      addClusterLinkingResource,
      orgId,
      data,
      addClusterLinkingWorkload,
      baselineIDCreated,
      startDate,
      endDate,
      spCurrentVersionLocal,
      numberOfMonths,
      resourceDetails
    );
  }
  return spCurrentVersionLocal;
};
const createBaselineStreamingProject = async (addStreamingProject, orgId, endDate, startDate) => {
  const { error, data } = await addStreamingProject({
    orgId,
    payload: {
      org_id: orgId,
      end_date: endDate,
      start_date: startDate,
      name: 'Baseline',
    },
  });

  const spCurrentVersionLocal = data.version;
  const baselineIDCreatedLocal = data.sp_id;

  if (error) {
    toastError(error);
    return {};
  }

  return { spCurrentVersion: spCurrentVersionLocal, baselineIDCreated: baselineIDCreatedLocal };
};

const getNumberOfMonthsFromSDAndEDSelected = async (startDate, endDate, getMetaData) => {
  const formattedStartDate = convertToISODateString(startDate);
  const formattedEndDate = convertToISODateString(endDate);

  const dealDurationQuery = {
    query: `DEAL_DURATION,START_DATE=${formattedStartDate},END_DATE=${formattedEndDate}`,
  };
  const { data, error } = await getMetaData(dealDurationQuery);

  if (error) {
    toastError(
      error,
      'Could not fetch Number of Months Based on given Start Date and End Date, please check!'
    );
    return -1;
  }

  return data.deal_duration.deal_duration_months;
};
export const processUploadedData = async (
  startDate,
  endDate,
  jsonData,
  orgId,
  isBaselineSPAlreadyConfigured,
  addClusterResource,
  addFlinkPoolResource,
  addStreamingProject,
  addClusterWorkload,
  addFlinkWorkload,
  addStreamGovernanceResource,
  addStreamGovernanceWorkload,
  addClusterLinkingResource,
  addClusterLinkingWorkload,
  getMetaData
) => {
  const numberOfMonths = await getNumberOfMonthsFromSDAndEDSelected(
    startDate,
    endDate,
    getMetaData
  );

  if (numberOfMonths === -1) {
    return;
  }

  // Next, validate the schema and throw any errors
  // Validation should happen on just 1 month
  // We need to ensure that one and only one months data is available in the file
  const validationSchema = getValidationSchemaBasedOnMonths(1);

  const isJsonSane = await validateData(jsonData, validationSchema);
  if (!isJsonSane) {
    return;
  }

  // If we are here, it means that validation was successful. Now, we need to create SP, Resources and Workloads

  if (isBaselineSPAlreadyConfigured) {
    // This should never be the case, so throw an error
    throw new Error('Attempting to add a Baseline SP when one already exists. Please check!');
  } else {
    const __ret = await createBaselineStreamingProject(
      addStreamingProject,
      orgId,
      endDate,
      startDate
    );

    let spCurrentVersion = __ret.spCurrentVersion;
    const baselineIDCreated = __ret.baselineIDCreated;

    if (!spCurrentVersion || !baselineIDCreated) {
      // This means there was an error in creating the SP itself, so no point in proceeding further
      return;
    }

    // Now, create Resources and Workloads

    // First Handle Cluster Workloads
    spCurrentVersion = await createClusterResourcesAndWorkloads(
      jsonData,
      addClusterResource,
      orgId,
      addClusterWorkload,
      baselineIDCreated,
      startDate,
      endDate,
      spCurrentVersion,
      addClusterLinkingResource,
      addClusterLinkingWorkload,
      numberOfMonths
    );

    if (spCurrentVersion === null) {
      // This means there was an error in processing one of the workloads and we need to return from this function too
      return;
    }

    // Then Handle Flink Workloads
    spCurrentVersion = await createFlinkResourcesAndWorkloads(
      jsonData,
      addFlinkPoolResource,
      orgId,
      addFlinkWorkload,
      baselineIDCreated,
      startDate,
      endDate,
      spCurrentVersion,
      numberOfMonths
    );

    if (spCurrentVersion === null) {
      // This means there was an error in processing one of the workloads and we need to return from this function too
      return;
    }

    // After that Handle SG Workloads
    await createStreamGovernanceResourcesAndWorkloads(
      jsonData,
      addStreamGovernanceResource,
      orgId,
      addStreamGovernanceWorkload,
      baselineIDCreated,
      startDate,
      endDate,
      spCurrentVersion,
      numberOfMonths
    );
  }
};

export const formResetHandler = (addSPBaselineFormik, setOpen) => {
  addSPBaselineFormik.resetForm();
  setOpen(false);
};
