// @flow
import React, { useContext } from 'react';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { ClusterInputTable } from '@components/cluster/cluster-input-table/ClusterInputTable';
import { STREAM_GOVERNANCE_WORKLOADS_INPUTS_FIELDNAMES } from '@streaming-projects/orgs/enums';
import { getIn, useFormikContext } from 'formik';
import { toastError } from '@presentational/notifications/utils';
import { useParams } from 'react-router-dom';
import { useUpdateStreamGovernanceWorkloadMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { StreamGovernanceWorkloadContext } from '@streaming-projects/workloads/stream-governance-workloads/contexts/StreamGovernanceWorkloadContext';
import { streamGovernanceWorkloadMonthlyInputRowsConfig } from '@streaming-projects/workloads/stream-governance-workloads/monthly-inputs/stream-governance-workload-monthly-input-rows-config';

export const SPStreamGovernanceWorkloadMonthlyUsageContainer = () => {
  const { orgId, spId, streamGovernanceWorkloadId } = useParams();
  const { values } = useFormikContext();
  const [updateWorkload] = useUpdateStreamGovernanceWorkloadMutation();
  const spStreamingProjectData = useContext(SPStreamingProjectContext);
  const workloadData = useContext(StreamGovernanceWorkloadContext);

  return (
    <PaddedAndRaisedSegment>
      <ClusterInputTable
        addCalendarMonthRowToDownload={true}
        contextData={workloadData}
        inferTotalNumberOfMonthsFromDataset={true}
        keyNameInValues={STREAM_GOVERNANCE_WORKLOADS_INPUTS_FIELDNAMES.MONTHLY_INPUTS}
        rowsConfig={streamGovernanceWorkloadMonthlyInputRowsConfig}
        showYearNumberInMonthlyHeader={false}
        tableName="Stream Governance Workload Monthly Usage"
        updateFunc={async () => {
          const valuesToSendToBackend = getIn(
            values,
            STREAM_GOVERNANCE_WORKLOADS_INPUTS_FIELDNAMES.MONTHLY_INPUTS
          );
          const inputs = Object.values(valuesToSendToBackend.months);
          const payload = {
            stream_governance_resource_id: workloadData?.stream_governance_resource_id,
            name: workloadData?.name,
            sp_version: spStreamingProjectData?.sp?.version,
            stream_governance_schema_details: {
              id: workloadData.stream_governance.stream_governance_schema_id,
              inputs: inputs,
            },
          };
          const { error } = await updateWorkload({
            orgId,
            spId,
            streamGovernanceWorkloadId,
            payload,
          });
          if (error) {
            toastError(error);
          }
        }}
      />
    </PaddedAndRaisedSegment>
  );
};
