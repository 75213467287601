// @flow
import React, { useContext, useState } from 'react';

import { EstimateContext } from '../../contexts/EstimateContext';
import { getAccordianPanelDetailsObject } from '../../common-utils/utils';
import { YearSelectionContext } from '../cluster/YearSelectionContext';
import { AccordionsList } from '../presentational/accordions/AccordionsList';

import { FlinkPoolDetailsContainer } from './flink-pool-details-inputs-section/FlinkPoolDetailsContainer';
import { FlinkPoolMonthlyUsageContainer } from './flink-pool-details-inputs-section/FlinkPoolMonthlyUsageContainer';
import { FlinkPoolSummaryContainer } from './flink-pool-summaries/FlinkPoolSummaryContainer';

export const FlinkPoolAccordionsContainer = () => {
  const estimate = useContext(EstimateContext);

  const totalYears = estimate?.deal_duration?.deal_duration_years ?? 0;
  const [selectedYear, setSelectedYear] = useState(1);

  const accordionPanels = [
    getAccordianPanelDetailsObject('Flink Pool Details', <FlinkPoolDetailsContainer />),
    getAccordianPanelDetailsObject('Flink Pool Usage', <FlinkPoolMonthlyUsageContainer />),
    getAccordianPanelDetailsObject('Flink Pool Summary', <FlinkPoolSummaryContainer />),
  ];

  return (
    <YearSelectionContext.Provider
      value={{
        totalYears,
        selectedYear,
        setSelectedYear,
      }}
    >
      <AccordionsList panels={accordionPanels} />
    </YearSelectionContext.Provider>
  );
};
