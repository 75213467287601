// @flow
import React from 'react';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';
import { TextField } from '@src/formik-utils/FormFields';
import { SP_DEFAULT_COMMIT_DETAILS_CONFIG_MAP } from '@streaming-projects/default-commit-details/config';

const SPDefaultCommitNameContainer = () => {
  const { backendFieldName, displayName, disabled } = SP_DEFAULT_COMMIT_DETAILS_CONFIG_MAP.get(
    BACKEND_FIELDNAMES.NAME
  );

  return (
    <TextField
      disabled={disabled}
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      fluid={true}
    />
  );
};

export default SPDefaultCommitNameContainer;
