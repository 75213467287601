// @flow
import { Formik } from 'formik';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { object } from 'yup';
import { Grid } from 'semantic-ui-react';
import { SPSPNameContainer } from '@streaming-projects/sp-page/sp-details/SPSPNameContainer';
import { SPStartDateContainer } from '@streaming-projects/sp-page/sp-details/SPStartDateContainer';
import { SPEndDateContainer } from '@streaming-projects/sp-page/sp-details/SPEndDateContainer';
import { STREAMING_PROJECTS_SP_DETAILS_CONFIG } from '@streaming-projects/sp-page/config';
import { useAddStreamingProjectMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { toastError } from '@presentational/notifications/utils';
import { ConfirmModal } from '@presentational/modals/ConfirmModal';
import { getLinkForStreamingProjectsSPPage } from '@streaming-projects/links';
import { Form } from '@src/formik-utils/formikSUIWrapper';
import { StyledContainer, StyledGridRow } from '@src/common-utils/styledComponents';
import { getValidationSchemaFromColsConfig } from '@src/configuration/utils';
import { Spacer } from '@presentational/spacing/Spacer';
import { extractRelevantFieldValuesFromForm } from '@src/common-utils/utils';
import { getSPDetailsFieldConfigByAddingBaselineNameValidation } from '@streaming-projects/sp-page/utils';

export const SPSPAddModal = ({ isOpen, setOpen }) => {
  const [addStreamingProject] = useAddStreamingProjectMutation();
  const { push } = useHistory();
  const { orgId } = useParams();

  const initialValues = {};

  // The Add Modal should always validate that the SP Name should not be Baseline
  const fieldsConfigToUse = getSPDetailsFieldConfigByAddingBaselineNameValidation();

  const validationSchema = object({
    ...getValidationSchemaFromColsConfig(fieldsConfigToUse),
  });

  return (
    <StyledContainer data-testid="sp-sp-add-modal">
      <Formik
        autoComplete="off"
        initialValues={initialValues}
        onSubmit={() => {}}
        validationSchema={validationSchema}
      >
        {(addSPSPFormik) => (
          <ConfirmModal
            body={
              <StyledContainer>
                <Form autoComplete="off">
                  <Grid columns={2} divided={true}>
                    <StyledGridRow coloumns={2}>
                      <Grid.Column>
                        <SPSPNameContainer disableOnFormErrors={false} />
                        {/* <SPSPSFDCIDContainer disableOnFormErrors={false} />*/}
                      </Grid.Column>
                    </StyledGridRow>
                    <StyledGridRow coloumns={2}>
                      <Grid.Column>
                        <SPStartDateContainer
                          disableOnFormErrors={false}
                          showWarningOnChange={false}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <SPEndDateContainer
                          disableOnFormErrors={false}
                          showWarningOnChange={false}
                        />
                      </Grid.Column>
                    </StyledGridRow>
                    <Spacer y={20} />
                  </Grid>
                </Form>
              </StyledContainer>
            }
            cancelButtonNegative={true}
            disabled={!addSPSPFormik.dirty || !addSPSPFormik.isValid}
            header="Add New Streaming Project"
            isOpen={isOpen}
            okButtonNegative={false}
            okButtonText="Continue"
            onClickHandlerForCancel={() => {
              addSPSPFormik.resetForm();
              return setOpen(false);
            }}
            onClickHandlerForOK={async () => {
              const { error, data } = await addStreamingProject({
                orgId,
                payload: {
                  org_id: orgId,
                  ...extractRelevantFieldValuesFromForm(
                    STREAMING_PROJECTS_SP_DETAILS_CONFIG,
                    addSPSPFormik.values
                  ),
                },
              });

              if (error) {
                toastError(error);
              } else {
                push(getLinkForStreamingProjectsSPPage(orgId, data.sp_id));
              }
              setOpen(false);
            }}
          />
        )}
      </Formik>
    </StyledContainer>
  );
};
