// @flow
import React, { useContext } from 'react';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';
import { SecondaryTopBar } from '@components/breadcrumbs/SecondaryTopBar';
import { getSPStreamingProjectBreadcrumbs } from '@streaming-projects/utils';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';

export const SPStreamingProjectBreadcrumbs = () => {
  const spOrgData = useContext(SPOrgContext);
  const spStreamingProjectData = useContext(SPStreamingProjectContext);
  return (
    <SecondaryTopBar
      sectionsToShow={getSPStreamingProjectBreadcrumbs(spOrgData, spStreamingProjectData)}
      showLastUpdatedMessage={false}
    />
  );
};
