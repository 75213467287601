// @flow
import React from 'react';
import { Grid, Label, Table } from 'semantic-ui-react';
import { getIn, useFormikContext } from 'formik';
import {
  CLUSTER_LINKING_WORKLOADS_INPUTS_FIELDNAMES,
  FLINK_WORKLOADS_INPUTS_FIELDNAMES,
  STREAM_GOVERNANCE_WORKLOADS_INPUTS_FIELDNAMES,
} from '@streaming-projects/orgs/enums';

import {
  CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG,
  FLINK_POOL_MONTHLY_INPUTS_JSON_CONFIG,
} from '../../../constants';
import {
  StyledCheckboxWithAccessibility,
  StyledFormikErrorMessage,
  StyledMonthlyUsageInput,
} from '../../../common-utils/styledComponents';
import { Field } from '../../../formik-utils/formikSUIWrapper';

import { addAdditionalDummyMonthsIfRequired } from './utils';
import ClusterMonthlyUsageRampCalculator from './ClusterMonthlyUsageRampCalculator';
import { PeakLessThanAvgPopup } from './PeakLessThanAvgPopup';

export const ClusterInputTableRow = ({
  displayName,
  backendName,
  selectedYear,
  months,
  onChangeFunc,
  isBooleanField = false,
  monthlyEstimatesFieldName,
  displayNameWidth = 4,
  inputWidth = 1,
  totalNumberOfMonths,
  disabled,
  isReadOnly = false,
}) => {
  const monthsToUse = addAdditionalDummyMonthsIfRequired(selectedYear, months);
  const { values: formikValues } = useFormikContext();

  return (
    <Table.Row disabled={disabled}>
      {isBooleanField ? (
        <Table.Cell width={displayNameWidth}>{displayName}</Table.Cell>
      ) : (
        <Table.Cell>
          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>{displayName}</Grid.Column>
              {!isReadOnly && (
                <Grid.Column textAlign="right" width={4}>
                  <ClusterMonthlyUsageRampCalculator
                    monthlyEstimatesFieldName={monthlyEstimatesFieldName}
                    totalNumberOfMonths={totalNumberOfMonths}
                    usageMetric={backendName}
                  />
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </Table.Cell>
      )}

      {monthsToUse.map((month, index) => {
        const fieldName = `${monthlyEstimatesFieldName}[months][${month.name}].${backendName}`;

        let isPeakLessThanAvgForMonth = false;
        if (fieldName.includes('_peak_')) {
          const correspondingAvgFieldName = fieldName.replace('_peak_', '_avg_');
          const peakValue = getIn(formikValues, fieldName);
          const averageValue = getIn(formikValues, correspondingAvgFieldName);
          isPeakLessThanAvgForMonth = peakValue < averageValue;
        }

        const isKafkaDisabledForMonth = !getIn(
          formikValues,
          `${CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG}[months][${month.name}].is_enabled`
        );

        let isKafkaDisabledAndWeAreNotDealingWithKafkaTable =
          isKafkaDisabledForMonth &&
          monthlyEstimatesFieldName !== CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG;

        // Flink is not dependent on Kafka in any way
        if (monthlyEstimatesFieldName === FLINK_POOL_MONTHLY_INPUTS_JSON_CONFIG) {
          isKafkaDisabledAndWeAreNotDealingWithKafkaTable = false;
        }

        // Flink in SP is not dependent on Kafka in any way
        if (monthlyEstimatesFieldName === FLINK_WORKLOADS_INPUTS_FIELDNAMES.MONTHLY_INPUTS) {
          isKafkaDisabledAndWeAreNotDealingWithKafkaTable = false;
        }

        // SG in SP is not dependent on Kafka in any way
        if (
          monthlyEstimatesFieldName === STREAM_GOVERNANCE_WORKLOADS_INPUTS_FIELDNAMES.MONTHLY_INPUTS
        ) {
          isKafkaDisabledAndWeAreNotDealingWithKafkaTable = false;
        }

        // Cluster Linking in SP is not dependent on Kafka in any way
        if (
          monthlyEstimatesFieldName === CLUSTER_LINKING_WORKLOADS_INPUTS_FIELDNAMES.MONTHLY_INPUTS
        ) {
          isKafkaDisabledAndWeAreNotDealingWithKafkaTable = false;
        }

        // todo:: Use CheckBoxField and TextField here - not used currently because of some CSS issue with FormField

        return (
          <Table.Cell
            disabled={disabled}
            key={index}
            selectable={!isReadOnly}
            style={
              isPeakLessThanAvgForMonth
                ? {
                    border: '2px dotted red',
                  }
                : {}
            }
            textAlign="center"
            width={inputWidth}
          >
            {isReadOnly ? (
              <>{getIn(formikValues, fieldName)}</>
            ) : (
              <>
                {isBooleanField ? (
                  <>
                    <Field
                      component={StyledCheckboxWithAccessibility}
                      disabled={
                        month.isMonthArtificiallyGenerated === true ||
                        isKafkaDisabledAndWeAreNotDealingWithKafkaTable ||
                        disabled
                      }
                      name={fieldName}
                      onChange={(event, value, setFieldValue, setFieldTouched, values, checked) =>
                        onChangeFunc(
                          month.name,
                          event,
                          value,
                          setFieldValue,
                          setFieldTouched,
                          values,
                          checked
                        )
                      }
                      toggle={true}
                      type="checkbox"
                    />
                    <StyledFormikErrorMessage
                      basic={true}
                      color="red"
                      component={Label}
                      name={fieldName}
                      pointing={true}
                      showErrorsOnlyWhenTouched={false}
                    />
                  </>
                ) : (
                  <>
                    <Field
                      component={StyledMonthlyUsageInput}
                      disabled={month.isMonthArtificiallyGenerated === true || disabled}
                      name={fieldName}
                      onChange={onChangeFunc}
                      onFocus={(e) => e.target.select()}
                      type="number"
                    />
                    {isPeakLessThanAvgForMonth && <PeakLessThanAvgPopup />}
                    <StyledFormikErrorMessage
                      basic={true}
                      color="red"
                      component={Label}
                      name={fieldName}
                      pointing={true}
                      showErrorsOnlyWhenTouched={false}
                    />
                  </>
                )}
              </>
            )}
          </Table.Cell>
        );
      })}
    </Table.Row>
  );
};
