// @flow
import React, { useContext } from 'react';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { ClusterInputTable } from '@components/cluster/cluster-input-table/ClusterInputTable';
import { CLUSTER_LINKING_WORKLOADS_INPUTS_FIELDNAMES } from '@streaming-projects/orgs/enums';
import { getIn, useFormikContext } from 'formik';
import { toastError } from '@presentational/notifications/utils';
import { useParams } from 'react-router-dom';
import { useUpdateClusterLinkingWorkloadMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { ClusterLinkingWorkloadContext } from '@streaming-projects/workloads/cluster-linking-workloads/contexts/ClusterLinkingWorkloadContext';

export const SPClusterLinkingWorkloadMonthlyUsageContainer = () => {
  const { orgId, spId, clusterLinkingWorkloadId } = useParams();
  const { values } = useFormikContext();
  const [updateWorkload] = useUpdateClusterLinkingWorkloadMutation();
  const spStreamingProjectData = useContext(SPStreamingProjectContext);
  const workloadData = useContext(ClusterLinkingWorkloadContext);

  const rowsConfig = [
    {
      backendName: 'is_enabled',
      displayName: 'Active',
      isBooleanField: true,
    },
    {
      backendName: 'avg_throughput_mbps',
      displayName: 'Avg Throughput ( mbps )',
    },
  ];

  return (
    <PaddedAndRaisedSegment>
      <ClusterInputTable
        addCalendarMonthRowToDownload={true}
        inferTotalNumberOfMonthsFromDataset={true}
        keyNameInValues={CLUSTER_LINKING_WORKLOADS_INPUTS_FIELDNAMES.MONTHLY_INPUTS}
        rowsConfig={rowsConfig}
        showYearNumberInMonthlyHeader={false}
        tableName="Cluster Linking Workload Monthly Usage"
        updateFunc={async () => {
          const valuesToSendToBackend = getIn(
            values,
            CLUSTER_LINKING_WORKLOADS_INPUTS_FIELDNAMES.MONTHLY_INPUTS
          );
          const clusterLinkingInputs = Object.values(valuesToSendToBackend.months);
          const payload = {
            cluster_linking_resource_id: workloadData?.cluster_linking_resource_id,
            name: workloadData?.name,
            sp_version: spStreamingProjectData?.sp?.version,
            inputs: { id: workloadData.cluster_linking.id, inputs: clusterLinkingInputs },
          };
          const { error } = await updateWorkload({
            orgId,
            spId,
            clusterLinkingWorkloadId,
            payload,
          });
          if (error) {
            toastError(error);
          }
        }}
      />
    </PaddedAndRaisedSegment>
  );
};
