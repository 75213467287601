// @flow
import React from 'react';
import { TextField } from '@src/formik-utils/FormFields';
import { CLUSTER_WORKLOADS_INPUTS_FIELDNAMES } from '@streaming-projects/orgs/enums';

import { CLUSTER_WORKLOAD_DETAILS_CONFIG_MAP } from '../config';

export const SPClusterWorkloadExistingStorageContainer = ({ disabled }) => {
  const { backendFieldName, displayName, icon, inputType } =
    CLUSTER_WORKLOAD_DETAILS_CONFIG_MAP.get(CLUSTER_WORKLOADS_INPUTS_FIELDNAMES.EXISTING_STORAGE);

  return (
    <TextField
      disabled={disabled}
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      icon={icon}
      showErrorsOnlyWhenTouched={false}
      type={inputType}
    />
  );
};
