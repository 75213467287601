// @flow
import React from 'react';
import { SPClusterResourceDeleteWarningModal } from '@streaming-projects/resource-definitions/cluster-resource-definitions/SPClusterResourceDeleteWarningModal';
import { DeleteRenderer } from '@components/views/grid-utils/renderers';
import { CLUSTER_ID } from '@src/constants';

export const SPClusterDeleteRenderer = ({ data }) => {
  return (
    <DeleteRenderer>
      {(isDeleteModalOpen, setDeleteModalOpen) => {
        return (
          <SPClusterResourceDeleteWarningModal
            clusterId={data[CLUSTER_ID]}
            clusterName={data.Name}
            // todo:SP Vishal to fix this
            clusterResourceId={data.stream_governance_resource_id}
            isOpen={isDeleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            version={data.Version}
          />
        );
      }}
    </DeleteRenderer>
  );
};
