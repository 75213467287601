// @flow
import React from 'react';
import { ErrorListContainer } from '@presentational/errors/ErrorListContainer';

import { StyledContainer } from '../../common-utils/styledComponents';

import { NegativeMessageContainer } from './messages/NegativeMessageContainer';

export const QueryError30 = ({ error }) => {
  const errorStatus = error?.status ?? 'NA';
  const allErrors = error?.data?.errors ?? [];
  return (
    <StyledContainer>
      <NegativeMessageContainer
        body={
          <>
            <p>Status: {errorStatus}</p>
            <p> Details: </p>
            {allErrors.length === 0 ? (
              <p>An error occurred</p>
            ) : (
              <ErrorListContainer errors={allErrors} />
            )}
            <p>Note: Please contact #cloud-commitment-estimator channel if the issue persists</p>
          </>
        }
        header="An Unexpected Error Occurred"
      />
    </StyledContainer>
  );
};
