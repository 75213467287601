// @flow
import { TextField } from '@src/formik-utils/FormFields';
import React from 'react';

export const ClusterLinkingNameContainer = () => (
  <TextField
    disableOnFormErrors={false}
    fieldDisplayName="Cluster Linking Name"
    fieldName="name"
    fluid={true}
  />
);
