// @flow
import React from 'react';
import { FLINK_WORKLOAD_DETAILS_CONFIG_MAP } from '@streaming-projects/workloads/flink-workloads/config';
import { FLINK_WORKLOADS_INPUTS_FIELDNAMES } from '@streaming-projects/orgs/enums';
import { TextField } from '@src/formik-utils/FormFields';

export const SPFlinkWorkloadNameContainer = ({
  disabled = false,
  fluid = null,
  disableOnFormErrors = null,
}) => {
  const { backendFieldName, displayName } = FLINK_WORKLOAD_DETAILS_CONFIG_MAP.get(
    FLINK_WORKLOADS_INPUTS_FIELDNAMES.WORKLOAD_NAME
  );
  return (
    <TextField
      disableOnFormErrors={disableOnFormErrors}
      disabled={disabled}
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      fluid={fluid}
    />
  );
};
