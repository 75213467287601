// @flow
import { Table } from 'semantic-ui-react';
import React from 'react';

import { YearSelector } from '../../presentational/year-selector/YearSelector';

import { addAdditionalDummyMonthsIfRequired } from './utils';

export const ClusterInputTableMonthHeaders = ({
  selectedYear,
  setSelectedYear,
  totalYears,
  months,
  disabled,
  showYearNumberInMonthlyHeader = false,
}) => {
  const monthsToUse = addAdditionalDummyMonthsIfRequired(selectedYear, months);
  return (
    <Table.Header>
      <Table.Row disabled={disabled} textAlign="center">
        <Table.HeaderCell textAlign="center">
          <YearSelector
            disabled={disabled}
            onChange={(year) => setSelectedYear(year)}
            selectedYearIndex={selectedYear}
            showYearNumberInMonthlyHeader={showYearNumberInMonthlyHeader}
            totalYears={totalYears}
          />
        </Table.HeaderCell>
        {monthsToUse.map((month) => {
          return (
            <Table.HeaderCell key={month.name}>
              <>
                {month.name}
                {month?.calendar_month && (
                  <>
                    <br />
                    {month?.calendar_month}
                  </>
                )}
              </>
            </Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
  );
};
