// @flow
import { useSelector } from 'react-redux';
import React from 'react';

import { LoadingIndicator } from './LoadingIndicator';

export const ShowLoaderIfAnyQueryIsPending = () => {
  // todo::SP Changed this to use ?. and ?? to take care of failing tests. Need to fix this for good later.
  const isSomeQueryPending = useSelector((state) => {
    return (
      Object.values(state?.estimatesApi?.queries ?? []).some(
        (query) => query?.status === 'pending'
      ) ||
      Object.values(state?.estimatesApi?.mutations ?? []).some(
        (query) => query?.status === 'pending'
      ) ||
      Object.values(state?.streamingProjectsApi?.queries ?? []).some(
        (query) => query?.status === 'pending'
      ) ||
      Object.values(state?.streamingProjectsApi?.mutations ?? []).some(
        (query) => query?.status === 'pending'
      )
    );
  });

  return <>{isSomeQueryPending && <LoadingIndicator />}</>;
};
