// @flow
import React from 'react';
import { useFormikContext } from 'formik';
import { STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES } from '@streaming-projects/orgs/enums';
import { SG_PARAMS_HACK } from '@src/constants';
import { SelectField } from '@src/formik-utils/FormFields';
import { getDropdownOptions } from '@components/stream-governance/utils';
import { STREAM_GOVERNANCE_RESOURCE_INPUTS_DETAILS_CONFIG_MAP } from '@streaming-projects/resource-definitions/stream-governance-resource-definitions/stream-governance-resource-inputs-config';

export const SGResourcePackageNameContainer = ({
  disabled,
  disableOnFormErrors = null,
  fluid = null,
}) => {
  const { values } = useFormikContext();

  const packageNameConfig = STREAM_GOVERNANCE_RESOURCE_INPUTS_DETAILS_CONFIG_MAP.get(
    STREAM_GOVERNANCE_RESOURCES_GRID_FIELDNAMES.PACKAGE_NAME
  );

  const dataUniverse = SG_PARAMS_HACK?.sg_configs?.sg_packages ?? [];

  const packageNameOptions = getDropdownOptions(dataUniverse, values, packageNameConfig);

  return (
    <SelectField
      disableOnFormErrors={disableOnFormErrors}
      disabled={disabled}
      fieldDisplayName={packageNameConfig.displayName}
      fieldName={packageNameConfig.backendFieldName}
      fluid={fluid}
      options={packageNameOptions}
      placeholder={packageNameConfig.displayName}
    />
  );
};
