// @flow
import React, { useContext } from 'react';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { GenericSaveResetButtons } from '@components/GenericSaveResetButtons';
import { Spacer } from '@presentational/spacing/Spacer';
import { Grid } from 'semantic-ui-react';
import { STREAM_GOVERNANCE_WORKLOADS_INPUTS_FIELDNAMES } from '@streaming-projects/orgs/enums';
import { useUpdateStreamGovernanceWorkloadMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { useParams } from 'react-router-dom';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { StreamGovernanceWorkloadContext } from '@streaming-projects/workloads/stream-governance-workloads/contexts/StreamGovernanceWorkloadContext';
import { STREAM_GOVERNANCE_WORKLOAD_DETAILS_CONFIG } from '@streaming-projects/workloads/stream-governance-workloads/config';
import { SPStreamGovernanceWorkloadNameContainer } from '@streaming-projects/workloads/stream-governance-workloads/workload-details/SPStreamGovernanceWorkloadNameContainer';

export const SPStreamGovernanceWorkloadDetailsContainer = () => {
  const { orgId, spId, streamGovernanceWorkloadId } = useParams();
  const [updateWorkload] = useUpdateStreamGovernanceWorkloadMutation();
  const spStreamingProjectData = useContext(SPStreamingProjectContext);
  const workloadData = useContext(StreamGovernanceWorkloadContext);

  return (
    <>
      <PaddedAndRaisedSegment>
        <>
          <GenericSaveResetButtons
            fieldsConfig={STREAM_GOVERNANCE_WORKLOAD_DETAILS_CONFIG.filter(
              (x) =>
                x.backendFieldName === STREAM_GOVERNANCE_WORKLOADS_INPUTS_FIELDNAMES.WORKLOAD_NAME
            )}
            onSaveHandler={async (payload) => {
              return await updateWorkload({
                orgId,
                spId,
                streamGovernanceWorkloadId,
                payload: {
                  stream_governance_resource_id: workloadData?.stream_governance_resource_id,
                  sp_version: spStreamingProjectData?.sp?.version,
                  ...payload,
                },
              });
            }}
          />
          <Spacer y={20} />
          <Grid>
            <Grid.Row>
              <Grid.Column width={3}>
                <SPStreamGovernanceWorkloadNameContainer disabled={false} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </>
      </PaddedAndRaisedSegment>
    </>
  );
};
