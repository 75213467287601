// @flow
import { LinkRenderer } from '@components/views/grid-utils/renderers';
import { getLinkForSPUserCommitDetailsPage } from '@streaming-projects/links';
import React from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const SPUserCommitNameRenderer = ({ commitId, commitName }) => {
  const { orgId } = useParams();

  return (
    <LinkRenderer pathName={getLinkForSPUserCommitDetailsPage(orgId, commitId)} text={commitName} />
  );
};

export default SPUserCommitNameRenderer;
