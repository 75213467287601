// @flow
import React from 'react';
import { ShowLoaderIfAnyQueryIsPending } from '@presentational/ShowLoaderIfAnyQueryIsPending';
import { UserContextProvider } from '@src/contexts/UserContextProvider';
import { SPOrgContextProvider } from '@streaming-projects/orgs/contexts/SPOrgContextProvider';
import { SPStreamingProjectContextProvider } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContextProvider';
import { SPStreamingProjectBreadcrumbs } from '@streaming-projects/sp-page/breadcrumbs/SPStreamingProjectBreadcrumbs';
import { SPStreamingProjectTopLevelContainerForm } from '@streaming-projects/sp-page/SPStreamingProjectTopLevelContainerForm';
import { SPMetaDataContextProvider } from '@streaming-projects/orgs/contexts/SPMetaDataContextProvider';

export const SPStreamingProjectTopLevelContainer = () => {
  return (
    <>
      <ShowLoaderIfAnyQueryIsPending />
      <UserContextProvider>
        <SPOrgContextProvider>
          <SPMetaDataContextProvider>
            <SPStreamingProjectContextProvider>
              <SPStreamingProjectBreadcrumbs />
              <SPStreamingProjectTopLevelContainerForm />
            </SPStreamingProjectContextProvider>
          </SPMetaDataContextProvider>
        </SPOrgContextProvider>
      </UserContextProvider>
    </>
  );
};
