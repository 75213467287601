// @flow

import { getIfUserIsAdminBasedOnGivenDetails } from '@src/common-utils/utils';
import { UserContext } from '@src/contexts/UserContext';
import { useContext } from 'react';

function useIsAdmin() {
  const user = useContext(UserContext);
  return getIfUserIsAdminBasedOnGivenDetails(user);
}

export default useIsAdmin;
