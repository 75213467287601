// @flow
import React, { useContext } from 'react';
import { getIn, useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';

import {
  CLUSTER_KAFKA_FORM,
  CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG,
  CLUSTER_TYPE_BACKEND_NAME,
  DEDICATED_CLUSTER_TYPE,
  ENTERPRISE_CLUSTER_TYPE,
  FREIGHT_CLUSTER_TYPE,
  RATE_CARD_BACKEND_NAME,
  RATE_CARD_VERSION_4_11_2024,
} from '../../../constants';
import { ClusterInputTable } from '../cluster-input-table/ClusterInputTable';
import { PaddedAndRaisedSegment } from '../../presentational/PaddedAndRaisedSegment';
import { toastError } from '../../presentational/notifications/utils';
import { useUpdateClusterMutation } from '../../../service-definitions/estimates';
import { EstimateContext } from '../../../contexts/EstimateContext';
import {
  getDataForOtherTabsThatNeedToBeSentToBackEnd,
  getKafkaNewlyDisabledMonths,
} from '../cluster-input-table/utils';
import { isAnythingEditedOnClusterPageBesidesTheGivenForm } from '../../views/utils';

const CLUSTER_TYPES_FOR_WHICH_EXPANDED_INPUTS_ARE_NEEDED_FOR_ALL_RCS = [
  DEDICATED_CLUSTER_TYPE,
  ENTERPRISE_CLUSTER_TYPE,
  FREIGHT_CLUSTER_TYPE,
];

const isExpandedInputNeeded = (values, rateCardVersion) => {
  if (rateCardVersion === RATE_CARD_VERSION_4_11_2024) {
    return true;
  }

  return CLUSTER_TYPES_FOR_WHICH_EXPANDED_INPUTS_ARE_NEEDED_FOR_ALL_RCS.includes(
    values[CLUSTER_TYPE_BACKEND_NAME]
  );
};

export const getOtherTabsInputsBasedOnWhetherMonthsAreDisabledOrNot = (
  kafkaMonthsData,
  initialValues,
  values
) => {
  let toRet = {};
  const disabledMonths = getKafkaNewlyDisabledMonths(kafkaMonthsData, initialValues, values);
  if (disabledMonths.length > 0) {
    toRet = getDataForOtherTabsThatNeedToBeSentToBackEnd(values, disabledMonths);
  }
  return toRet;
};

export const KafkaUsageContainer = () => {
  const { estimateId, clusterId } = useParams();
  const [updateCluster] = useUpdateClusterMutation();
  const estimate = useContext(EstimateContext);
  const rateCardVersion = estimate.inputs[RATE_CARD_BACKEND_NAME];

  const rowsConfig = [
    {
      backendName: 'is_enabled',
      displayName: 'Active',
      isBooleanField: true,
    },
    {
      backendName: 'write_throughput_peak_mbps',
      displayName: 'Peak Write Throughput (MBps)',
      showOrHideFunc: (values) => values[CLUSTER_TYPE_BACKEND_NAME] === DEDICATED_CLUSTER_TYPE,
    },
    {
      backendName: 'read_throughput_peak_mbps',
      displayName: 'Peak Read Throughput (MBps)',
      showOrHideFunc: (values) => values[CLUSTER_TYPE_BACKEND_NAME] === DEDICATED_CLUSTER_TYPE,
    },
    {
      backendName: 'write_throughput_avg_mbps',
      displayName: 'Average Write Throughput (MBps)',
    },
    {
      backendName: 'read_throughput_avg_mbps',
      displayName: 'Average Read Throughput (MBps)',
    },
    {
      backendName: 'connected_client_count',
      displayName: '# of Simultaneously Connected Clients',
      showOrHideFunc: (values) => isExpandedInputNeeded(values, rateCardVersion),
    },
    {
      backendName: 'client_connection_attempts_per_sec',
      displayName: '# of Connection Attempts Per Second',
      showOrHideFunc: (values) => isExpandedInputNeeded(values, rateCardVersion),
    },
    {
      backendName: 'client_requests_per_sec',
      displayName: '# of Requests Per Second',
      showOrHideFunc: (values) => isExpandedInputNeeded(values, rateCardVersion),
    },
  ];

  const { values, initialValues } = useFormikContext();

  const shouldBeDisabled = isAnythingEditedOnClusterPageBesidesTheGivenForm(
    values,
    initialValues,
    CLUSTER_KAFKA_FORM
  );

  return (
    <PaddedAndRaisedSegment disabled={shouldBeDisabled}>
      <ClusterInputTable
        disabled={shouldBeDisabled}
        keyNameInValues={CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG}
        rowsConfig={rowsConfig}
        tableName="Kafka Monthly Usage"
        updateFunc={async () => {
          const valuesToSendToBackend = getIn(values, CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG);
          const kafkaInputs = Object.values(valuesToSendToBackend.months);
          const otherComponentsToSendToBackEnd =
            getOtherTabsInputsBasedOnWhetherMonthsAreDisabledOrNot(
              valuesToSendToBackend.months,
              initialValues,
              values
            );

          const payload = {
            id: clusterId,
            estimate_id: estimateId,
            estimate_version: estimate?.inputs?.estimate_version,
            throughput: { inputs: kafkaInputs },
            ...otherComponentsToSendToBackEnd,
          };

          const { error } = await updateCluster({
            estimateId,
            clusterId,
            payload,
          });

          if (error) {
            toastError(error);
          }
        }}
      />
    </PaddedAndRaisedSegment>
  );
};

// todo:: Fix the duplication in all 4 update funcs
