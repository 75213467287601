// @flow
import React, { useContext } from 'react';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { GenericSaveResetButtons } from '@components/GenericSaveResetButtons';
import { Spacer } from '@presentational/spacing/Spacer';
import { Grid } from 'semantic-ui-react';
import { SPFlinkWorkloadNameContainer } from '@streaming-projects/workloads/flink-workloads/workload-details/SPFlinkWorkloadNameContainer';
import { FLINK_WORKLOAD_DETAILS_CONFIG } from '@streaming-projects/workloads/flink-workloads/config';
import { FLINK_WORKLOADS_INPUTS_FIELDNAMES } from '@streaming-projects/orgs/enums';
import { useUpdateFlinkWorkloadMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { useParams } from 'react-router-dom';
import { SPStreamingProjectContext } from '@streaming-projects/sp-page/contexts/SPStreamingProjectContext';
import { FlinkPoolWorkloadContext } from '@streaming-projects/workloads/flink-workloads/contexts/FlinkPoolWorkloadContext';

export const SPFlinkWorkloadDetailsContainer = () => {
  const { orgId, spId, flinkWorkloadId } = useParams();
  const [updateFlinkWorkload] = useUpdateFlinkWorkloadMutation();
  const spStreamingProjectData = useContext(SPStreamingProjectContext);
  const spFlinkPoolWorkloadData = useContext(FlinkPoolWorkloadContext);

  return (
    <>
      <PaddedAndRaisedSegment>
        <>
          <GenericSaveResetButtons
            fieldsConfig={FLINK_WORKLOAD_DETAILS_CONFIG.filter(
              (x) => x.backendFieldName === FLINK_WORKLOADS_INPUTS_FIELDNAMES.WORKLOAD_NAME
            )}
            onSaveHandler={async (payload) => {
              return await updateFlinkWorkload({
                orgId,
                spId,
                flinkWorkloadId,
                payload: {
                  flink_pool_config_id: spFlinkPoolWorkloadData?.flink_pool_config_id,
                  sp_version: spStreamingProjectData?.sp?.version,
                  ...payload,
                },
              });
            }}
          />
          <Spacer y={20} />
          <Grid>
            <Grid.Row>
              <Grid.Column width={3}>
                <SPFlinkWorkloadNameContainer disabled={false} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </>
      </PaddedAndRaisedSegment>
    </>
  );
};
