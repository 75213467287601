// @flow
import React, { useContext } from 'react';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';
import { getFormikFormInputsFromColConfigAndInputSource } from '@src/common-utils/utils';
import { StyledContainer } from '@src/common-utils/styledComponents';
import { Formik } from 'formik';
import { Form } from '@src/formik-utils/formikSUIWrapper';
import { Spacer } from '@presentational/spacing/Spacer';
import { SP_ORG_TOP_LEVEL_CONFIG } from '@streaming-projects/orgs/config';
import { SPOrgAccordionsContainer } from '@streaming-projects/orgs/org-top-level/SPOrgAccordionsContainer';
import { CUSTOM_DISCOUNTS_JSON_BACKEND_NAME } from '@src/constants';
import { SP_CUSTOM_DISCOUNTS_JSON_CONFIG } from '@src/configuration/custom-discounts';

export const SPOrgTopLevelContainerForm = () => {
  const spOrgData = useContext(SPOrgContext);
  const spOrgInputsSource = {
    ...spOrgData?.org,
    [CUSTOM_DISCOUNTS_JSON_BACKEND_NAME]: spOrgData?.discounts ?? {},
  };
  const spOrgConfig = [...SP_ORG_TOP_LEVEL_CONFIG, SP_CUSTOM_DISCOUNTS_JSON_CONFIG];

  const { initialValues, initialTouched, validationSchema } =
    getFormikFormInputsFromColConfigAndInputSource(spOrgConfig, spOrgInputsSource, spOrgData);

  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialTouched={initialTouched}
        initialValues={initialValues}
        onSubmit={() => {}}
        validateOnMount={true}
        validationSchema={validationSchema}
      >
        {() => (
          <Form autoComplete="off">
            <Spacer y={10} />
            <SPOrgAccordionsContainer />
          </Form>
        )}
      </Formik>
    </StyledContainer>
  );
};
