// @flow
import React, { useContext } from 'react';
import { SecondaryTopBar } from '@components/breadcrumbs/SecondaryTopBar';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';
import { getSPDefaultCommitDetailsBreadcrumbs } from '@streaming-projects/utils';

const SPDefaultCommitDetailsBreadcrumbs = () => {
  const spOrgContext = useContext(SPOrgContext);

  return (
    <SecondaryTopBar
      sectionsToShow={getSPDefaultCommitDetailsBreadcrumbs(spOrgContext)}
      showLastUpdatedMessage={false}
    />
  );
};

export default SPDefaultCommitDetailsBreadcrumbs;
