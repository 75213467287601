// @flow
import { Menu } from 'semantic-ui-react';
import React from 'react';
import { IconWithPopup } from '@presentational/IconWithPopup';

import { CaretRightIcon } from '../icons/CaretRightIcon';
import { CaretLeftIcon } from '../icons/CaretLeftIcon';
import { StyledYearSelectorMenu } from '../../../common-utils/styledComponents';

import { getYearNumberToUseBasedOnLimits } from './utils';

export const YearSelector = ({
  selectedYearIndex,
  totalYears,
  onChange,
  disabled,
  showYearNumberInMonthlyHeader = false,
}) => {
  if (totalYears <= 0) {
    throw 'totalYears should be a positive integer';
  }

  if (selectedYearIndex > totalYears) {
    throw 'selectedYear must be less than totalYears!';
  }

  return (
    <StyledYearSelectorMenu compact={true}>
      <Menu.Item
        disabled={disabled}
        onClick={() => {
          const previousYear = getYearNumberToUseBasedOnLimits(selectedYearIndex - 1, totalYears);
          onChange(previousYear);
        }}
      >
        {showYearNumberInMonthlyHeader ? (
          <CaretLeftIcon />
        ) : (
          <IconWithPopup iconName="caret left" popupContent="Move back by 12 months" />
        )}
      </Menu.Item>

      {showYearNumberInMonthlyHeader ? (
        <Menu.Item>
          Year {selectedYearIndex} / {totalYears}
        </Menu.Item>
      ) : (
        <Menu.Item>Move Back/Forward by 12 months</Menu.Item>
      )}

      <Menu.Item
        disabled={disabled}
        onClick={() => {
          const nextYear = getYearNumberToUseBasedOnLimits(selectedYearIndex + 1, totalYears);
          onChange(nextYear);
        }}
      >
        {showYearNumberInMonthlyHeader ? (
          <CaretRightIcon />
        ) : (
          <IconWithPopup iconName="caret right" popupContent="Move forward by 12 months" />
        )}
      </Menu.Item>
    </StyledYearSelectorMenu>
  );
};
